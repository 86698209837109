import {Injectable} from '@angular/core';
import {
  AggregateId,
  AggregateVersion,
  DepartmentId,
  GroupId,
  Option,
  OrganizationNodeId,
  PersonId, toastr,
  Typed
} from "@utils";
import {asSuccess, AuthenticatedHttp, CommandResponse, isSuccess, nonSuccessMessages} from "@shared-model";
import {
  AddDepartmentToGroup, ChangeDepartmentParent,
  CreateDepartment,
  DeleteDepartment, RemoveDepartmentFromGroup,
  UpdateDepartmentInfo
} from "../commands/DepartmentCommands";

@Injectable({
  providedIn: 'root',
})
export class DepartmentsCommandService {

  constructor(private readonly authenticatedHttp: AuthenticatedHttp) {}

  createDepartment(departmentName: string, parentNodeId: OrganizationNodeId, directorId: Option<PersonId>,
                   onSuccess:(aggregateId: AggregateId, aggregateVersion: AggregateVersion) => void,
                   onFailure: (exceptions: Array<string>) => void) {
    const command = new CreateDepartment(departmentName, parentNodeId.id, directorId.map(d => d.id));
    this.authenticatedHttp.post("organization-structure/department/create", command,
      (data: Typed<CommandResponse>) => {
        if (isSuccess(data)) {
          const success = asSuccess(data);
          onSuccess(success.aggregateId, success.aggregateVersion);
        } else {
          onFailure([nonSuccessMessages(data)]);
        }
      }
    );
  }

  updateDepartmentInfo(departmentId: DepartmentId, departmentVersion: AggregateVersion, newName: string, newDirector: Option<PersonId>, newParentId: AggregateId|undefined,
                       onSuccess: (aggregateVersion: AggregateVersion) => void,
                       onFailure: (exceptions: Array<string>) => void) {
    const command = new UpdateDepartmentInfo(departmentId.id, departmentVersion, newName, newDirector, newParentId);
    this.authenticatedHttp.post("organization-structure/department/update-department-info", command,
      (data: Typed<CommandResponse>) => {
        if (isSuccess(data)) {
          const success = asSuccess(data);
          onSuccess(success.aggregateVersion);
        } else {
          onFailure([nonSuccessMessages(data)]);
        }
      }
    );
  }

  changeDepartmentParent(departmentId: DepartmentId, departmentVersion: AggregateVersion, newParent: AggregateId,
                         onSuccess: (aggregateVersion: AggregateVersion) => void) {
    const command = new ChangeDepartmentParent(departmentId.id, departmentVersion, newParent);

    this.authenticatedHttp.post("organization-structure/department/change-parent", command,
      (data: Typed<CommandResponse>) => {
        if (isSuccess(data)) {
          const success = asSuccess(data);
          onSuccess(success.aggregateVersion);
        } else {
          toastr.error(nonSuccessMessages(data));
        }
      }
    );
  }

  addDepartmentToGroup(aggregateId: AggregateId, version: AggregateVersion,
                       groupId: GroupId,
                       onSuccess: (version: AggregateVersion) => void) {
    const command = new AddDepartmentToGroup(aggregateId, version, groupId);
    this.authenticatedHttp.post("organization-structure/department/add-department-to-group", command,
      (data: Typed<CommandResponse>) => {
        if (isSuccess(data)) {
          const success = asSuccess(data);
          onSuccess(success.aggregateVersion);
        } else {
          toastr.error(nonSuccessMessages(data));
        }
      }
    );
  }

  removeDepartmentFromGroup(aggregateId: AggregateId, version: AggregateVersion,
                            groupId: GroupId,
                            onSuccess: (version: AggregateVersion) => void) {
    const command = new RemoveDepartmentFromGroup(aggregateId, version, groupId);
    this.authenticatedHttp.post("organization-structure/department/remove-department-from-group", command,
      (data: Typed<CommandResponse>) => {
        if (isSuccess(data)) {
          const success = asSuccess(data);
          onSuccess(success.aggregateVersion);
        } else {
          toastr.error(nonSuccessMessages(data));
        }
      }
    );
  }

  deleteDepartment(aggregateId: AggregateId, expectedVersion: AggregateVersion,
                   onSuccess: (aggregateId: AggregateId, aggregateVersion: AggregateVersion) => void) {
    this.authenticatedHttp.post("organization-structure/department/delete", new DeleteDepartment(aggregateId, expectedVersion),
      (data: Typed<CommandResponse>) => {
        if (isSuccess(data)) {
          const success = asSuccess(data);
          onSuccess(success.aggregateId, success.aggregateVersion);
        } else {
          toastr.error(nonSuccessMessages(data));
        }
      }
    );
  }

}
