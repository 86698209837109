import {
  ArrayVariableType,
  BusinessVariableType,
  ObjectVariableType,
  RootVariableType,
  VariableTypePath
} from "@shared-model";
import {$$, $$Element, __, getElementPositionAndSize, myRequestAnimationFrame, mySetTimeoutNoAngular} from "@utils";

export class FormHelper {

  static animateChange(scope: {updated: number, updatedTimeout: number}) {
    scope.updated = 1;
    clearTimeout(scope.updatedTimeout);
    scope.updatedTimeout = mySetTimeoutNoAngular(() => {
      scope.updated = 2;
      scope.updatedTimeout = mySetTimeoutNoAngular(() => {
        scope.updated = 0;
      }, 4000);
    }, 1000);
  }

  static hidePicker(dateContentSelection:$$Element) {
    dateContentSelection.classed("hidden", true);
  }

  static showPicker(popupSelection:$$Element, parentSelection: $$Element) {
    popupSelection
      .classed("hidden", false);

    FormHelper.fixPopupPosition(popupSelection, parentSelection)


  }

  static fixPopupPosition(popupSelection:$$Element, parentSelection: $$Element) {

    if(parentSelection.hasClass("focused")) {

      const parents = $$(".taskForm").parents(".scrollContainer");
      if(parents.length > 0) {

        const scrollSelection = parents[0];

        const popupSize = getElementPositionAndSize(popupSelection);
        const parentPosition = getElementPositionAndSize(parentSelection);
        const formSize = getElementPositionAndSize(scrollSelection);

        const onTop = parentPosition.y + parentPosition.height + popupSize.height > formSize.y + formSize.height
        const onLeft = parentPosition.x + popupSize.width > formSize.x + formSize.width;

        if (onTop) {
          popupSelection
            .style("top", (parentPosition.y - popupSize.height) + "px");
        } else {
          popupSelection
            .style("top", (parentPosition.y + parentPosition.height) + "px");
        }

        if (onLeft) {
          popupSelection
            .style("left", (parentPosition.x + parentPosition.width - popupSize.width) + "px");
        } else {
          popupSelection
            .style("left", (parentPosition.x) + "px");
        }

        const out = parentPosition.y < formSize.y || popupSelection.classed("hidden");

        popupSelection.classed("hidden", out);

        myRequestAnimationFrame(() => {
          FormHelper.fixPopupPosition(popupSelection, parentSelection);
        });
      }
    }
  }

  static variableTypeByPath(variableTypePath: VariableTypePath,
                        variablesTypes: Array<RootVariableType<BusinessVariableType>>): BusinessVariableType {
    if (variableTypePath.isRoot()) {
      const found = __(variablesTypes).find(v => v.name === variableTypePath.last()).get();
      return found.unwrappedVariableType();
    } else {
      const contextPath = variableTypePath.context();
      const context = __(variablesTypes).find(v => v.name === contextPath.head()).get();
      if (context.unwrappedVariableType().className() === ArrayVariableType.className) {
        const a = <ArrayVariableType<BusinessVariableType>>context.unwrappedVariableType();
        if (a.subtypeUnwrapped().className() === ObjectVariableType.className) {
          const o = <ObjectVariableType>a.subtypeUnwrapped();
          const fieldType = o.fieldTypeByName(variableTypePath.last());
          return fieldType.getOrError("Field not found");
        } else {
          throw new Error("Only Object or Array[Object] supported, but was " + context.unwrappedVariableType().typeName())
        }
      } else if (context.unwrappedVariableType().className() === ObjectVariableType.className) {
        const o = <ObjectVariableType>context.unwrappedVariableType();
        const fieldType = o.fieldTypeByName(variableTypePath.last());
        return fieldType.getOrError("Field not found");
      } else {
        throw new Error("Only Object or Array[Object] supported, but was " + +context.unwrappedVariableType().typeName());
      }
    }
  }


}
