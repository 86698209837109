<ng-container *ngFor="let action of visibleActions; let last = last">
  <button
    class="alternativeAction theme-button-link-icon"
    (click)="viewModel.redirectByEdge(action, $event)"
    [disabled]="!viewModel.redirectButtonsEnabled">
    <i class="actionIcon mi-rewind"></i>{{action.name}}
  </button>

  <div *ngIf="!last || actionsInsideDropdown.length > 0" class="theme-action-button-separator"></div>
</ng-container>

<my-modal cssClass="theme-modal-simple-prompt"
          [(visible)]="viewModel.redirectConfirmationVisible"
          [backgroundVisible]="true"
          backgroundStyle="dark"
          [closeOnBackgroundClick]="true"
          (closed)="viewModel.cancelRedirectAction()">
  <ng-container *ngIf="viewModel.redirectConfirmationVisible">
    <div class="theme-title">
      {{viewModel.redirectConfirmationText}}
      <button class="theme-button-close" (click)="viewModel.cancelRedirectAction()"><i class="mi-delete"></i></button>
    </div>
    <div class="theme-prompt">
      {{'task-form_alternative-action-header' | i18n}}
    </div>
    <adjusting-text-input
      [(model)]="viewModel.redirectComment"
      [large]="true"
      class="theme-input"
      [placeholder]="'task-form_alternative-action-placeholder' | i18n"
      ngDefaultControl
      [focus-on-show]="true" (accepted)="viewModel.acceptRedirectAction()"
      (keydown.escape)="viewModel.cancelRedirectAction()"
    ></adjusting-text-input>

    <div class="theme-buttons">
      <button type="button" class="theme-button-cancel-icon theme-align-start" (click)="viewModel.cancelRedirectAction()"><i class="mi-delete"></i>{{'common_cancel' | i18n}}</button>
      <button type="button" class="theme-button-confirm-with-icon" (click)="viewModel.acceptRedirectAction()"><i class="mi-undo"></i>{{'common_confirm' | i18n}}</button>
    </div>
  </ng-container>
</my-modal>

<ng-container *ngIf="actionsInsideDropdown.length > 0">
  <button #moreActions class="alternativeAction"
          [class.theme-button-link-icon]="visibleActions.length > 0"
          [class.theme-button-option]="visibleActions.length === 0"
          (click)="moreActionsMenuVisible = !moreActionsMenuVisible">
    <ng-container *ngIf="visibleActions.length > 0"><i class="moreIcon mi-menu-small"></i>{{'tasks_form-more' | i18n}}</ng-container>
    <ng-container *ngIf="visibleActions.length === 0"><i class="rewindIcon mi-rewind"></i></ng-container>

  </button>

  <my-drop-menu cssClass="theme-drop-menu theme-drop-menu-above alternativeActionsDropMenu"
                [(visible)]="moreActionsMenuVisible"
                [anchor]="moreActions"
                position="top"
                (closed)="moreActionsMenuVisible = false"
                horizontalPosition="end"
  >

    <ng-container *ngIf="moreActionsMenuVisible">
      <button
        *ngFor="let action of actionsInsideDropdown"
        class="theme-drop-menu-entry"
        (click)="viewModel.redirectByEdge(action, $event)"
        [disabled]="!viewModel.redirectButtonsEnabled">
        <i class="actionIcon mi-rewind"></i>{{action.name}}
      </button>
    </ng-container>

  </my-drop-menu>
</ng-container>

<button *ngIf="viewModel.cancelButtonVisible"
        class="alternativeAction theme-button-link-icon"
        (click)="viewModel.cancelFlow()">
  <i class="mi-delete"></i> {{'dashboard_Form_CancelButton' | i18n}}
</button>
