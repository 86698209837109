import {FormSectionId} from "@shared"
import {__, ___, FormElementRefId, None, ObjectId, Option, Some} from "@utils";
import {FieldsAreaViewModel, FormSectionViewModel} from "./task-form.view-model";
import {TaskEventBus} from "./TaskEventBus";

export class ElementDiff {
		constructor(readonly elementRefId: FormElementRefId, public gridDY: number, public gridDHeight: number) {
		}
	}

	export class SectionDiff {
		constructor(readonly formSectionId: FormSectionId, readonly contextObjectId: Option<ObjectId>,
		            public gridDHeight: number, public elements: ElementDiff[]) {
		}
	}

	export class FormDiff {
		sectionsDiffs: SectionDiff[] = [];

		constructor(readonly eventBus: TaskEventBus) {}

		private getOrCreateSection(sectionId: FormSectionId, contextObjectId: Option<ObjectId>): SectionDiff {
			const sectionOption = __(this.sectionsDiffs)
				.find(s => s.formSectionId.id === sectionId.id && s.contextObjectId.equals(contextObjectId, (a, b) => a.id === b.id));

			if(sectionOption.isDefined()) {
				return sectionOption.get();
			} else {
				const empty = new SectionDiff(sectionId, contextObjectId, 0, []);
				this.sectionsDiffs.push(empty);
				return empty;
			}
		}

		private getOrCreateElement(section: SectionDiff, elementRefId: FormElementRefId): ElementDiff {

			const elementOption = __(section.elements).find(e => e.elementRefId.id === elementRefId.id);
			if(elementOption.isDefined()) {
				return elementOption.get();
			} else {
				const empty = new ElementDiff(elementRefId, 0, 0);
				section.elements.push(empty);
				return empty;
			}
		}

		setElementDHeight(sectionId: FormSectionId, contextObjectId: Option<ObjectId>, elementRefId: FormElementRefId, gridDHeight: number) {

			const section = this.getOrCreateSection(sectionId, contextObjectId);
			const element = this.getOrCreateElement(section, elementRefId);

			if(element.gridDHeight != gridDHeight) {
        element.gridDHeight = gridDHeight;
        this.eventBus.formElementHeightChanged();
      }
		}

		updateDiff(sections: Array<FormSectionViewModel>) {

			sections.forEach(section => {

			  if(section.isRepeatableSection) {
			    section.asRepeatableSection().entries.forEach(entry => {
			      this.updateDiffForFieldArea(entry.fieldsArea, section.sectionId, Some(entry.contextObjectId));
          })
        } else if (section.isSingularSection) {
			    this.updateDiffForFieldArea(section.asSingularSection().fieldsArea, section.sectionId, None());
        } else {
			    throw new Error("Incorrect section type");
        }
			});
		}

		private updateDiffForFieldArea(fieldArea: FieldsAreaViewModel, sectionId: FormSectionId, contextObjectId: Option<ObjectId>) {
      const sectionDiff = this.getOrCreateSection(sectionId, contextObjectId);

      const processedElements: Array<ElementDiff> = [];

      fieldArea.elements.forEach(element => {

        const processedElementsAbove = processedElements.filter(pe => {
          const elem = __(fieldArea.elements).find(se => se.elementRef.id.id === pe.elementRefId.id);
          return elem.isDefined() && (elem.get().elementRef.gridXY.gridY + elem.get().elementRef.gridSize.height) <= element.elementRef.gridXY.gridY;
        });

        const elementDiff = this.getOrCreateElement(sectionDiff, element.elementRef.id);

        const dY = ___(processedElementsAbove).map(p => p.gridDHeight + p.gridDY).maxOrDefault(0);

        elementDiff.gridDY = dY;
        processedElements.push(elementDiff);
      });

      let maxYElementDiff: Option<ElementDiff> = None();

      processedElements.forEach(e => {
        if (maxYElementDiff.isEmpty() || maxYElementDiff.isDefined() && (e.gridDY + e.gridDHeight > maxYElementDiff.get().gridDY + maxYElementDiff.get().gridDHeight)){
          maxYElementDiff = Some(e);
        }
      });

      sectionDiff.gridDHeight = maxYElementDiff.map(p => p.gridDY + p.gridDHeight).getOrElse(0);

    }

		diffForElement(sectionId: FormSectionId, contextObjectId: Option<ObjectId>, elementRef: FormElementRefId): Option<ElementDiff> {
			return ___(this.sectionsDiffs)
				.find(s => s.formSectionId.id === sectionId.id && s.contextObjectId.equals(contextObjectId, (a, b) => a.id === b.id))
				.flatMap(s => __(s.elements).find(e => e.elementRefId.id === elementRef.id));
		}

		diffForSection(sectionId: FormSectionId, contextObjectId: Option<ObjectId>): Option<SectionDiff> {
			return ___(this.sectionsDiffs).find(s => s.formSectionId.id === sectionId.id && s.contextObjectId.equals(contextObjectId, (a, b) => a.id === b.id))
		}
	}
