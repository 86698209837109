import {Injectable} from '@angular/core';
import {
  AggregateId,
  AggregateVersion,
  ApplicationId,
  GroupId,
  I18nText,
  Option,
  OrganizationNodeId,
  toastr,
  Typed
} from "@utils";
import {asSuccess, AuthenticatedHttp, CommandResponse, isSuccess, nonSuccessMessages} from "@shared-model";
import {
  AddGroupAuthorization,
  AddGroupToGroup,
  ChangeGroupAuthorizations,
  ChangeGroupInfo,
  CreateGroup,
  DeleteGroup,
  RemoveGroupAuthorization,
  RemoveGroupFromGroup
} from "../commands/GroupCommands";

@Injectable({
  providedIn: 'root',
})
export class GroupsCommandService {

  constructor(private readonly authenticatedHttp: AuthenticatedHttp) {}

  createGroup(name: I18nText, identifier: string, applicationId: Option<ApplicationId>,
              onSuccess: (id: AggregateId, version: AggregateVersion) => void) {
    const command = new CreateGroup(name, identifier, applicationId);
    this.authenticatedHttp.post("organization-groups/create", command,
      (data: Typed<CommandResponse>) => {
        if (isSuccess(data)) {
          const success = asSuccess(data);
          onSuccess(success.aggregateId, success.aggregateVersion);
        } else {
          toastr.error(nonSuccessMessages(data));
        }
      }
    );
  }

  changeGroupInfo(aggregateId: AggregateId, expectedVersion: AggregateVersion,
                  name: I18nText, description: I18nText, identifier: string,
                  onSuccess: (version: AggregateVersion) => void) {

    const command = new ChangeGroupInfo(aggregateId, expectedVersion, name, description, identifier);
    this.authenticatedHttp.post("organization-groups/change-group-info", command,
      (data: Typed<CommandResponse>) => {
        if (isSuccess(data)) {
          const success = asSuccess(data);
          onSuccess(success.aggregateVersion);
        } else {
          toastr.error(nonSuccessMessages(data));
        }
      }
    );
  }

  addGroupToGroup(aggregateId: AggregateId, expectedVersion: AggregateVersion,
                  groupId: GroupId,
                  onSuccess: (version: AggregateVersion) => void) {
    const command = new AddGroupToGroup(aggregateId, expectedVersion, groupId);
    this.authenticatedHttp.post("organization-groups/add-group-to-group", command,
      (data: Typed<CommandResponse>) => {
        if (isSuccess(data)) {
          const success = asSuccess(data);
          onSuccess(success.aggregateVersion);
        } else {
          toastr.error(nonSuccessMessages(data));
        }
      }
    );
  }

  removeGroupFromGroup(aggregateId: AggregateId, expectedVersion: AggregateVersion,
                       groupId: GroupId,
                       onSuccess: (version: AggregateVersion) => void) {
    const command = new RemoveGroupFromGroup(aggregateId, expectedVersion, groupId);
    this.authenticatedHttp.post("organization-groups/remove-group-from-group", command,
      (data: Typed<CommandResponse>) => {
        if (isSuccess(data)) {
          const success = asSuccess(data);
          onSuccess(success.aggregateVersion);
        } else {
          toastr.error(nonSuccessMessages(data));
        }
      }
    );
  }

  deleteGroup(aggregateId: AggregateId, expectedVersion: AggregateVersion,
              onSuccess: () => void) {

    const command = new DeleteGroup(aggregateId, expectedVersion);
    this.authenticatedHttp.post("organization-groups/delete", command,
      (data: Typed<CommandResponse>) => {
        if (isSuccess(data)) {
          const success = asSuccess(data);
          onSuccess();
        } else {
          toastr.error(nonSuccessMessages(data));
        }
      }
    );
  }

  addGroupAuthorization(aggregateId: AggregateId, expectedVersion: AggregateVersion,
                        newEditorId: Option<OrganizationNodeId>, newGroupTaskCreatorId: Option<OrganizationNodeId>, newPersonTaskCreatorId: Option<OrganizationNodeId>,
                        onSuccess: (version: AggregateVersion) => void){
    const command = new AddGroupAuthorization(aggregateId, expectedVersion, newEditorId, newGroupTaskCreatorId, newPersonTaskCreatorId);
    this.authenticatedHttp.post("organization-groups/add-authorization", command,
      (data: Typed<CommandResponse>) => {
        if (isSuccess(data)) {
          const success = asSuccess(data);
          onSuccess(success.aggregateVersion);
        } else {
          toastr.error(nonSuccessMessages(data));
        }
      }
    );
  }

  removeGroupAuthorization(aggregateId: AggregateId, expectedVersion: AggregateVersion,
                           editorId: Option<OrganizationNodeId>, groupTaskCreatorId: Option<OrganizationNodeId>, personTaskCreatorId: Option<OrganizationNodeId>,
                           onSuccess: (version: AggregateVersion) => void){
    const command = new RemoveGroupAuthorization(aggregateId, expectedVersion, editorId, groupTaskCreatorId, personTaskCreatorId);
    this.authenticatedHttp.post("organization-groups/remove-authorization", command,
      (data: Typed<CommandResponse>) => {
        if (isSuccess(data)) {
          const success = asSuccess(data);
          onSuccess(success.aggregateVersion);
        } else {
          toastr.error(nonSuccessMessages(data));
        }
      }
    );
  }

  changeGroupAuthorizations(aggregateId: AggregateId, expectedVersion: AggregateVersion,
                            editors: Array<OrganizationNodeId>, groupTaskCreators: Array<OrganizationNodeId>, personTaskCreators: Array<OrganizationNodeId>,
                            onSuccess: (version: AggregateVersion) => void){
    const command = new ChangeGroupAuthorizations(aggregateId, expectedVersion, editors, groupTaskCreators, personTaskCreators);
    this.authenticatedHttp.post("organization-groups/change-group-authorizations", command,
      (data: Typed<CommandResponse>) => {
        if (isSuccess(data)) {
          const success = asSuccess(data);
          onSuccess(success.aggregateVersion);
        } else {
          toastr.error(nonSuccessMessages(data));
        }
      }
    );
  }

}
