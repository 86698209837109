import {FormElementId, FormElementRefId, GridSize, GridXY, I18nText, Option, Trilean, Typed} from "@utils";
import {
  ArrayVariable,
  BusinessVariable,
  BusinessVariableFactory,
  BusinessVariablesValidation,
  BusinessVariableType,
  BusinessVariableValidationFactory,
  ExpressionWithAst,
  VariableTypePath
} from "@shared-model";
import {InputElement, InputElementRef} from "../FormModel";
import {LabelPosition} from "../FormField";

export abstract class SingleSelectInputElement implements InputElement {

  abstract className(): string;

  unwrappedDefaultValue() {
    return this.defaultValue.map(v => Typed.value(v));
  }

  defaultVariable(variableType: BusinessVariableType) {
    return this.defaultValue.map(v => BusinessVariableFactory.copy(Typed.value(v)));
  }

  abstract cssClassName(): string;

  isInputElement(): boolean {
    return true;
  }

  constructor(public id: FormElementId,
              public tooltip: I18nText,
              public variableTypePath: VariableTypePath,
              public validation: Option<Typed<BusinessVariablesValidation>>,
              public entries: ArrayVariable<BusinessVariable>,
              public defaultValue: Option<Typed<BusinessVariable>>,
              public entriesExpression: Option<ExpressionWithAst>,
              public hiddenExpression: Option<ExpressionWithAst>,
              public requiredExpression: Option<ExpressionWithAst>,
              public readOnlyExpression: Option<ExpressionWithAst>) {
  }
}

export class RadioButton extends SingleSelectInputElement {
  static className = "RadioButton";

  cssClassName(): string {
    return "radioButtonField";
  }

  className() {
    return RadioButton.className;
  }

  constructor(id: FormElementId,
              tooltip: I18nText,
              variableTypePath: VariableTypePath,
              validation: Option<Typed<BusinessVariablesValidation>>,
              entries: ArrayVariable<BusinessVariable>,
              defaultValue: Option<Typed<BusinessVariable>>,
              entriesExpression: Option<ExpressionWithAst>,
              hiddenExpression: Option<ExpressionWithAst>,
              requiredExpression: Option<ExpressionWithAst>,
              readOnlyExpression: Option<ExpressionWithAst>) {
    super(id, tooltip, variableTypePath, validation, entries,
      defaultValue, entriesExpression, hiddenExpression, requiredExpression, readOnlyExpression);
  }

  static copy(other: RadioButton): RadioButton {
    return new RadioButton(other.id, I18nText.copy(other.tooltip),
      VariableTypePath.copy(other.variableTypePath),
      Option.copy(other.validation, BusinessVariableValidationFactory.copyTyped),
      <ArrayVariable<BusinessVariable>>BusinessVariableFactory.copyByType(other.entries, ArrayVariable.className),
      Option.copy(other.defaultValue, BusinessVariableFactory.copyTyped),
      Option.copy(other.entriesExpression, ExpressionWithAst.copy),
      Option.copy(other.hiddenExpression, ExpressionWithAst.copy),
      Option.copy(other.requiredExpression, ExpressionWithAst.copy),
      Option.copy(other.readOnlyExpression, ExpressionWithAst.copy));
  }
}

export class RadioButtonRef implements InputElementRef {
  static className = "RadioButtonRef";

  className(): string {
    return RadioButtonRef.className;
  }

  isInputElementRef() {
    return true;
  }

  constructor(public id: FormElementRefId,
              public elementId: FormElementId,
              public gridXY: GridXY,
              public gridSize: GridSize,
              public label: I18nText,
              public labelPosition: LabelPosition,
              public labelGridShift: GridXY,
              public required: Trilean,
              public readOnly: Trilean,
              public hidden: Trilean,
              public visibleInSummary: boolean,
              public visibleInTaskBox: boolean) {}

  static copy(other: RadioButtonRef): RadioButtonRef {
    return new RadioButtonRef(other.id, other.elementId, GridXY.copy(other.gridXY), GridSize.copy(other.gridSize),
      I18nText.copy(other.label), LabelPosition.copy(other.labelPosition), GridXY.copy(other.labelGridShift),
      Trilean.copy(other.required), Trilean.copy(other.readOnly), Trilean.copy(other.hidden), other.visibleInSummary, other.visibleInTaskBox);
  }
}
