import {
  ApplicationLoginRequest,
  ApplicationLoginResponse,
  ApplicationSessionInfoClientSide,
  BasicAuthenticatedUserInfo,
  ContinueMFALogin, DepartmentCalendar,
  OrganizationAuthenticationOnlySuccess,
  OrganizationLoginAsRequest,
  OrganizationLoginFailure,
  OrganizationLoginMFATokenRequired,
  OrganizationLoginRequest,
  OrganizationLoginResponse,
  OrganizationLoginResponseFactory,
  OrganizationLoginSuccess,
  OrganizationLoginTemporaryDisabled,
  OrganizationSessionInfoClientSide,
  UserVerificationResult
} from "./SessionServiceModel";
import {AggregateId, clearArray, global, handleError, None, Option, restUrl, Some, toastr, Typed} from "@utils";
import {SessionCookiesService} from "./session-cookies.service";
import {HttpClient, HttpResponse} from "@angular/common/http";
import {SessionEventBus} from "./SessionEventBus";
import {Observable, Subject} from "rxjs";
import {Constants} from "../../Constants";


// Provided by SessionServiceProvider, because it's initialized asynchronously
export class SessionService {
  private loggedIn: boolean = sessionStorage.getItem("loggedIn") == "true";

  lastRequestedUrl: Option<string> = None();
  sessionToken: string|null = null;
  notificationsNo: number = 0;
  organizationCalendarChanged: boolean = false;

  private organizationCache: Option<AggregateId>|null = null;
  private loginPending = false;
  private loginQueries: Array<[() => void, () => void]> = [];

  private _loggedInUsingSSO = false;

  private organizationSessionInfoSubject = new Subject<OrganizationSessionInfoClientSide|null>();

  private _organizationSessionInfo: OrganizationSessionInfoClientSide|null = null;
  private _platformSessionInfo: ApplicationSessionInfoClientSide|null = null;

  constructor(readonly sessionCookiesService: SessionCookiesService,
              private readonly http: HttpClient,
              readonly sessionEventBus: SessionEventBus) {}


  get loggedInUsingSSO(): boolean {
    return this._loggedInUsingSSO;
  }

  get organizationSessionInfo(): OrganizationSessionInfoClientSide|null {
    return this._organizationSessionInfo;
  }

  get platformSessionInfo() {
    if(this._platformSessionInfo === null) {
      throw new Error("Platform session info not available");
    } else {
      return this._platformSessionInfo;
    }
  }

  logout() {

    if(this.sessionToken === null) {
      this.clearOrganizationSession();
    } else {

      this.getRequest("session/logout", () => {
        console.log("Server logged out");
      }, () => {
        console.log("Server log out  error");
      })

      const sessionToken = this.sessionToken;
      this.sessionToken = null;
      this.clearOrganizationSession();
      this.sessionEventBus.userLoggedOut(sessionToken);

    }
  }

  private refreshSessionInfo(onDone: () => void, onFailed: () => void): void {

    const storedOrganizationSessionToken = this.sessionCookiesService.getOrganizationSessionToken();
    const storedApplicationSessionToken = this.sessionCookiesService.getApplicationSessionToken();

    if (storedOrganizationSessionToken.isDefined()) {

      this.sessionToken = storedOrganizationSessionToken.get();

      this.checkOrganizationSession(storedOrganizationSessionToken.get(), response => {
        this.handleOrganizationSessionInfoResponse(response);
        if(response.isDefined()) {
          onDone();
        } else {
          onFailed();
        }
      },() => {});
    } else {
      this._organizationSessionInfo = null;
      this.organizationSessionInfoSubject.next(null);
    }

    if (storedApplicationSessionToken.isDefined()) {
      this.sessionToken = storedApplicationSessionToken.get();
      this.checkApplicationSession(storedApplicationSessionToken.get(), response => {
        this.handleApplicationSessionInfoResponse(response);
        if(response.isDefined()) {
          onDone();
        } else {
          onFailed();
        }
      },() => {});
    } else {
      this._platformSessionInfo = null;
    }

    if(storedApplicationSessionToken.isEmpty() && storedOrganizationSessionToken.isEmpty()) {
      onFailed();
    }
  }

  loginToOrganizationAutomatically(onSuccess: () => void, onFailure: () => void) {
    if(this.loginPending) {
      this.loginQueries.push([onSuccess, onFailure]);
    } else {
      this.loginPending = true;
      clearArray(this.loginQueries);
      if(global.config.kerberosSsoEnabled) {
        this.loginToOrganizationUsingSSO(() => {
          onSuccess();
          this.loginQueries.forEach(r => r[0]());
          this.loginPending = false;
          this._loggedInUsingSSO = true;
        }, () => {
          this.loginToOrganizationUsingRememberMe(() => {
            onSuccess();
            this.loginQueries.forEach(r => r[0]());
            this.loginPending = false;
            this._loggedInUsingSSO = false;
          }, () => {
            onFailure();
            this.loginQueries.forEach(r => r[1]());
            this.loginPending = false;
            this._loggedInUsingSSO = false;
          });
        });
      } else {
        this.loginToOrganizationUsingRememberMe(() => {
          onSuccess();
          this.loginQueries.forEach(r => r[0]());
          this.loginPending = false;
          this._loggedInUsingSSO = false;
        }, () => {
          onFailure();
          this.loginQueries.forEach(r => r[1]());
          this.loginPending = false;
          this._loggedInUsingSSO = false;
        });
      }

    }
  }

  private getWithSessionRequest<T>(url: string, sessionToken: string, onSuccess: (response: T) => void, onFailure: () => void): void {

    const promise: Observable<HttpResponse<any>> = <any>this.http.get(restUrl(url), {
      observe: "response",
      responseType: "json",
      headers: {
        'Authorization': sessionToken
      },
      withCredentials: true
    });

    promise.subscribe({
      next: (response: HttpResponse<any>) => {
        onSuccess(response.body)
      },
      error: (reason) => {
        onFailure();
      }
    });
  }


  private getRequest<T>(url: string, onSuccess: (response: T) => void, onFailure: (reason: any) => void) {

    const promise: Observable<HttpResponse<Typed<OrganizationLoginResponse>>> = <any>this.http.get(restUrl(url), {
      observe: "response",
      responseType: "json",
      withCredentials: true
    });

    promise.subscribe({
      next: (response: HttpResponse<any>) => {
        onSuccess(response.body);
      },
      error: (reason) => {onFailure(reason)}
    });

  }

  private postRequest<T>(url: string, body: any, onSuccess: (response: T) => void, onFailure: (reason: any) => void) {

    const promise: Observable<HttpResponse<Typed<OrganizationLoginResponse>>> = <any>this.http.post(restUrl(url), body, {
      observe: "response",
      responseType: "json",
      withCredentials: true
    });

    promise.subscribe({
      next: (response: HttpResponse<any>) => {
        onSuccess(response.body);
      },
      error: (reason) => {onFailure(reason)}
    });

  }

  checkOrganizationSession(sessionToken: string, onSuccess: (response: Option<OrganizationSessionInfoClientSide>) => void, onFailure: () => void) {
    this.getWithSessionRequest<Option<OrganizationSessionInfoClientSide>>("session/organization-get-session-info", sessionToken, (response) => {
      onSuccess(Option.copy(response, OrganizationSessionInfoClientSide.copy))
    }, () => {
      this.sessionToken = null;
      onFailure();
    });
  }

  checkApplicationSession(sessionToken: string, onSuccess: (response: Option<ApplicationSessionInfoClientSide>) => void, onFailure: () => void) {
    this.getWithSessionRequest<Option<OrganizationSessionInfoClientSide>>("session/application-get-session-info", sessionToken, (response) => {
      onSuccess(Option.copy(response, OrganizationSessionInfoClientSide.copy))
    }, () => {
      this.sessionToken = null;
      onFailure();
    });
  }


  loginToOrganizationUsingRememberMe(onSuccess: () => void, onFailure: () => void) {
    this.getRequest<Typed<OrganizationLoginResponse>>("session/organization-remember-me", response => {
      this.handleLoginResponse(Typed.value(OrganizationLoginResponseFactory.copyTyped(response)), () => {
          onSuccess();
        }, () => {throw new Error("MFA not supported in remember-me")}
        , () => {throw new Error("Temporary disabled not supported in remember-me")}, () => {
          onFailure();
        });
    }, reason => {
      onFailure();
    });
  }

  loginToOrganizationUsingSSO(onSuccess: () => void, onFailure: () => void) {
    this.getRequest<Typed<OrganizationLoginResponse>>("session/organization-sso", response => {
      this.handleLoginResponse(Typed.value(OrganizationLoginResponseFactory.copyTyped(response)), () => onSuccess(),
        () => {throw new Error("MFA not supported in login-as")}, () => {throw new Error("Temporary disabled not supported in login-as")}, () => onFailure());

    }, reason => {
      onFailure();
    })
  }


  verifyUserIdentity(userToken: string, onConfirmed: (info: BasicAuthenticatedUserInfo) => void,
                     onNotConfirmed: () => void, onIncorrect: () => void, onFailure: () => void) {


    if(this.sessionToken === null) {
      onNotConfirmed();
    } else {
      const promise: Observable<HttpResponse<any>> = <any>this.http.get(restUrl("session/verify-user-identity/"+userToken), {
        observe: "response",
        responseType: "json",
        headers: {
          'Authorization': this.sessionToken
        },
        withCredentials: true
      });

      promise.subscribe({
        next: (response: HttpResponse<any>) => {
          const result = UserVerificationResult.copy(response.body);
          if(result.isVerified()) {
            onConfirmed(result.info.getOrError("User info not available"));
          } else if(result.isNotVerified()) {
            onNotConfirmed();
          } else if(result.isIncorrect()) {
            onIncorrect();
          } else {
            throw new Error("Unknown status '"+result.status+"'");
          }
        },
        error: (reason) => {
          onFailure();
        }
      });
    }

  }



  loginToOrganization(login: string, password: string, rememberMe: boolean,
                      identityServiceUserToken: Option<string>, onSuccess: () => void, onMFARequired: (loginAttemptId: string) => void, onFailure: () => void, onTemporaryDisabled: (minutes: number) => void) {
    this.postRequest("session/organization-login", new OrganizationLoginRequest(login, password, rememberMe, identityServiceUserToken),
      (response: Typed<OrganizationLoginResponse>) => {
        this._loggedInUsingSSO = false;
      this.handleLoginResponse(Typed.value(OrganizationLoginResponseFactory.copyTyped(response)), onSuccess, (loginAttemptId: string) => onMFARequired(loginAttemptId), (minutes: number) => onTemporaryDisabled(minutes), onFailure);
    }, (reason) => {
      handleError(reason)
    });
  }


  continueMFALogin(loginAttemptId: string, mfaToken: string, identityServiceUserToken: Option<string>, onSuccess: () => void, onFailure: () => void) {

    this.postRequest("session/continue-mfa-login", new ContinueMFALogin(loginAttemptId, mfaToken, None(), identityServiceUserToken),
      (response: Typed<OrganizationLoginResponse>) => {
        this.handleLoginResponse(Typed.value(OrganizationLoginResponseFactory.copyTyped(response)), onSuccess,
          () => {throw new Error("MFA not supported in continue-mfa-login")},
          () => {throw new Error("Temporary disabled not supported in continue-mfa-login")}, onFailure);
        }, (reason) => {
        handleError(reason)
      });

  }

  loginAsToOrganization(credentials: string, onSuccess: () => void, onFailure: () => void) {

    this.postRequest("session/organization-login-as", new OrganizationLoginAsRequest(credentials),
    (response: Typed<OrganizationLoginResponse>) => {
      this.handleLoginResponse(Typed.value(OrganizationLoginResponseFactory.copyTyped(response)), onSuccess,
        () => {throw new Error("MFA not supported in login-as")},
        () => {throw new Error("Temporary disabled not supported in login-as")}, onFailure);
    }, (reason) => {
      handleError(reason)
    });


  }

  private handleLoginResponse(response: OrganizationLoginResponse, onSuccess: () => void, onMFARequired: (loginAttemptId: string) => void, onTemporaryDisabled: (minutes: number) => void, onFailure: () => void) {

    if(response instanceof OrganizationLoginSuccess) {
      this.handleOrganizationSessionInfoResponse(Some(response.sessionInfo));
      this.sessionEventBus.userLoggedIn();
      onSuccess();
    } else if(response instanceof OrganizationAuthenticationOnlySuccess) {
      onSuccess();
    } else if(response instanceof OrganizationLoginMFATokenRequired) {
      onMFARequired(response.loginAttemptId);
    } else if(response instanceof OrganizationLoginFailure) {
      onFailure();
    } else if(response instanceof OrganizationLoginTemporaryDisabled) {
      onTemporaryDisabled(response.minutes);
    } else {
      throw new Error("Login response type not supported ["+response.className()+"]");
    }
  }

  private handleOrganizationSessionInfoResponse(sessionInfo: Option<OrganizationSessionInfoClientSide>) {
    this.clearApplicationSession();
    if(sessionInfo.isDefined()) {
      this.createNewOrganizationSession(sessionInfo.getOrError("No info"));
    }
  }

  private handleApplicationSessionInfoResponse(response: Option<ApplicationSessionInfoClientSide>) {
    this.clearOrganizationSession();
    if(response.isDefined()) {
      this.createNewApplicationSession(response.get());
    }
  }

  loginToApplication(email: string, password: string, onSuccess: () => void, onFailure: () => void) {

    this.postRequest<ApplicationLoginResponse>("session/application-login", new ApplicationLoginRequest(email, password), response => {
      if (response.success) {
        this.clearOrganizationSession();
        this.createNewApplicationSession(response.sessionInfo.get());
        onSuccess();
        this.sessionEventBus.userLoggedIn();
      } else {
        onFailure();
      }
    }, reason => {handleError(reason)});
  }

  initImpersonate(impersonateId: string, onSuccess: () => void, onFailure: () => void) {

    this.getRequest<Typed<OrganizationLoginResponse>>("session/organization-impersonate"+"/"+impersonateId, response => {
      const loginResponse: OrganizationLoginResponse = Typed.value(OrganizationLoginResponseFactory.copyTyped(response));

      if(loginResponse instanceof OrganizationLoginSuccess) {
        this.clearApplicationSession();
        this.createNewOrganizationSession(loginResponse.sessionInfo);
        onSuccess();
        this.sessionEventBus.userLoggedIn();
      } else if (loginResponse instanceof OrganizationLoginMFATokenRequired) {
        throw new Error("MFA not supported in impersonate")
      } else if (loginResponse instanceof OrganizationLoginTemporaryDisabled) {
        throw new Error("Temprary disabled login not supported in impersonate")
      } else if (loginResponse instanceof OrganizationLoginFailure) {
        onFailure();
      }
    }, reason => handleError(reason));
  }

  getOrganization(onSuccess: (data: AggregateId) => void, onFailure: () => void):void {
    if (this.organizationCache !== null) {
      if(this.organizationCache.isDefined()) {
        onSuccess(this.organizationCache.get());
      } else {
        onFailure();
      }
    } else {
      this.getRequest<Option<AggregateId>>("organization/get-by-alias", response => {
        this.organizationCache = Option.copy(response);
        if(this.organizationCache.isDefined()) {
          onSuccess(this.organizationCache.get());
        } else {
          onFailure();
        }
      }, reason => handleError(reason));
    }
  }

  isAuthenticated(callback: (authenticated: boolean) => void) {
    if(this.loginPending) {
      this.loginQueries.push([() => {
        callback(true);
      }, () => {
        callback(false);
      }])
    } else {
      callback(this.sessionToken !== null);
    }
  }

  clearOrganizationSession(): void {
    this._organizationSessionInfo = null;
    this.organizationSessionInfoSubject.next(null);
  }

  clearApplicationSession(): void {
    this._platformSessionInfo = null;
  }

  createNewOrganizationSession(info: OrganizationSessionInfoClientSide):void {

    // this.userSettingsService.setUserServerSettings(Some(info.language), Some(info.timezone), info.region, info.hoursFormat, info.firstDayOfWeek, info.weekend);
    this.sessionToken = info.sessionToken;
    this.sessionCookiesService.setOrganizationSessionToken(info.sessionToken);
    this.setOrganizationSessionInfo(info);

    // this.localSettings.setTimezone(this._organizationSessionInfo!.timezone);
  }

  createNewApplicationSession(sessionInfo: ApplicationSessionInfoClientSide):void {
    this.setApplicationSessionInfo(sessionInfo);

    this.sessionToken = sessionInfo.sessionToken;

    this.sessionCookiesService.setApplicationSessionToken(sessionInfo.sessionToken);
  }

  downloadUrl(id: number, name: string): string {
    return restUrl(`download/attachment/${id}/${encodeURIComponent(name)}`);
  }

  private setOrganizationSessionInfo(info:OrganizationSessionInfoClientSide) {
    this._organizationSessionInfo = info;
    this.organizationSessionInfoSubject.next(info);
    // app.factory("organizationSessionInfo", () => info);
  }

  setApplicationSessionInfo(info:ApplicationSessionInfoClientSide) {
    this._platformSessionInfo = info;
    // app.factory("applicationSessionInfo", () => info);
  }

  loadSessionInfo(onDone: () => void): void {
    this.refreshSessionInfo(onDone, () => {
      this.loginToOrganizationAutomatically(() => {
        this.sessionToken = this.sessionCookiesService.getOrganizationSessionToken().getOrNull();
        onDone();
      }, () => {
        this.sessionToken = null;
        onDone();
      });
    });
  }

  setLastRequestedUrl(url: string): void {
    this.lastRequestedUrl = Some(url);
  }

  //
  // login(username: string, password: string, onSuccess: () => void, onIncorrectCredentials: () => void) {
  //   if (username === "Marcin" && password === "marcin") {
  //     this.loggedIn = true;
  //     sessionStorage.setItem("loggedIn", "true");
  //     onSuccess();
  //   } else {
  //     onIncorrectCredentials();
  //   }
  // }
  //
  // logout() {
  //   if (this.loggedIn) {
  //     this.loggedIn = false;
  //     sessionStorage.removeItem("loggedIn");
  //   }
  // }
  //
  isLoggedIn() {
    return this.sessionToken !== null;
  }

  get organizationSessionInfoObservable(): Observable<OrganizationSessionInfoClientSide|null> {
    return this.organizationSessionInfoSubject.asObservable();
  }

  initAnonymousSession() {
    this.sessionToken = "";
    this._organizationSessionInfo = new OrganizationSessionInfoClientSide("", "", "", "", None(), Constants.webClientUserId.id, "", Constants.platformOrganizationId.toAggregateId(), "", false, DepartmentCalendar.empty(), None(), true, false, None(), false);
  }
}
