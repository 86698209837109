import {__, FormElementId, FormElementRefId, GridSize, GridXY, I18nText, Option, Some, Trilean, Typed} from "@utils";
import {
  ArrayVariable,
  BusinessVariablesValidation,
  BusinessVariableType,
  BusinessVariableValidationFactory,
  ExpressionWithAst,
  FileArrayValidation,
  VariableTypePath
} from "@shared-model";
import {InputElement, InputElementRef} from "../FormModel";
import {LabelPosition} from "../FormField";

export class AttachmentField implements InputElement {

  static className = "AttachmentField";

  constructor(public id: FormElementId,
              public tooltip: I18nText,
              public variableTypePath: VariableTypePath,
              public validation: Option<Typed<BusinessVariablesValidation>>,
              public hiddenExpression: Option<ExpressionWithAst>,
              public requiredExpression: Option<ExpressionWithAst>,
              public readOnlyExpression: Option<ExpressionWithAst>
             ) {}

  cssClassName(): string {
    return "attachmentField";
  }

  defaultVariable(variableType: BusinessVariableType) {
    return Some(new ArrayVariable([]));
  }

  className() {
    return AttachmentField.className;
  }

  isInputElement(): boolean {
    return true;
  }

  validationUnwrapped() {
    return this.validation.map(Typed.value);
  }

  maxFileCount(): number {
    const validation = this.validationUnwrapped().getOrUndefined();
    if(validation instanceof FileArrayValidation) {
      return validation.arrayValidation.maximumLength;
    } else {
      return 10;
    }
  }

  minFileCount(): number {
    const validation = this.validationUnwrapped().getOrUndefined();
    if(validation instanceof FileArrayValidation) {
      return validation.arrayValidation.minimumLength;
    } else {
      return 10;
    }
  }

  allowedExtensions(): Array<string> {
    const validation = this.validationUnwrapped().getOrUndefined();
    if(validation instanceof FileArrayValidation) {
      return validation.fileValidation.allowedExtensions;
    } else {
      return [];
    }
  }

  extensionValid(fileName: string): boolean {
    const allowed = this.allowedExtensions();
    if(allowed.length > 0) {
      const splitted = fileName.split(".");
      if(splitted.length > 1) {
        return __(this.allowedExtensions()).contains(__(splitted).last().toLowerCase());
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  static copy(other: AttachmentField): AttachmentField {
    return new AttachmentField(other.id, I18nText.copy(other.tooltip),
      VariableTypePath.copy(other.variableTypePath),
      Option.copy(other.validation).map(BusinessVariableValidationFactory.copyTyped),
      Option.copy(other.hiddenExpression).map(ExpressionWithAst.copy),
      Option.copy(other.requiredExpression).map(ExpressionWithAst.copy),
      Option.copy(other.readOnlyExpression).map(ExpressionWithAst.copy));
  }

}


export class AttachmentFieldRef implements InputElementRef {
  static className = "AttachmentFieldRef";

  className(): string {
    return AttachmentFieldRef.className;
  }

  isInputElementRef() {
    return true;
  }

  constructor(public id: FormElementRefId,
              public elementId: FormElementId,
              public gridXY: GridXY,
              public gridSize: GridSize,
              public label: I18nText,
              public labelPosition: LabelPosition,
              public labelGridShift: GridXY,
              public required: Trilean,
              public readOnly: Trilean,
              public hidden: Trilean,
              public filesReadOnly: boolean, // means user cannot add or remove files, but can change them
              public visibleInSummary: boolean,
              public visibleInTaskBox: boolean
  ) {}

  static copy(other: AttachmentFieldRef): AttachmentFieldRef {
    return new AttachmentFieldRef(other.id, other.elementId, GridXY.copy(other.gridXY), GridSize.copy(other.gridSize),
      I18nText.copy(other.label), LabelPosition.copy(other.labelPosition), GridXY.copy(other.labelGridShift),
      Trilean.copy(other.required), Trilean.copy(other.readOnly), Trilean.copy(other.hidden),
      other.filesReadOnly, other.visibleInSummary, other.visibleInTaskBox);
  }

}
