import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewContainerRef} from "@angular/core";
import {
  __,
  myRequestAnimationFrame,
  mySetInterval,
  None,
  Option,
  required,
  setPageVisibleBox,
  Some,
  toastr,
} from "@utils";
import {
  AdHocTaskHelper,
  ApplicationIcon,
  ApplicationsSharedService,
  FilesSharedService,
  FlowsSharedService,
  PersonsSharedService,
  ProcessesNamingQueryService,
  TaskListManagerService,
  TasksEventBus,
  TasksStatusManager
} from "@shared-model";
import {
  Constants,
  DepartmentCalendar,
  GlobalEventBus,
  I18nService,
  NavigationService,
  ScreenInstanceSharedWrapperService,
  ServerEventsService,
  SystemSettingsService
} from "@shared";
import {TaskEventListener, TaskServerModel} from "./TaskServerModel";
import {TaskEventBus} from "./TaskEventBus";
import {FlowService} from "./service/FlowService";
import {AttachmentsUrlViewModel} from "./FormData";
import {TaskFormViewModel} from "./task-form.view-model";
import {TaskModel} from "./model/ProcessFlow";
import {TaskViewModel} from "./TaskViewModel";
import {TasksServerModel} from "./service/TasksServerModel";

export class StartTaskPortletController {
  private sizeInterval = 0;
  constructor(readonly element: HTMLElement,
              readonly iframeId: string|undefined,
              readonly fontSizePx: number,
              readonly backgroundColor: string) {
  }

  start() {

    // Resize iframe
    this.sizeInterval = <any>mySetInterval(() => {
      if(this.element !== null) {
        const height = this.element.clientHeight;
        const iframeIdPart = this.iframeId === undefined ? "" : this.iframeId + "::";
        parent.postMessage("taskFrameResize::" + iframeIdPart + height, "*");
      }
    }, 100);
  }


  stop() {
    clearInterval(this.sizeInterval);
    this.sizeInterval = 0;
  }

}

export class StartTaskPortletViewModel {

}


@Component({
  selector: 'my-start-task-portlet',
  templateUrl: './start-task-portlet.component.html'
})
export class StartTaskPortletComponent implements OnInit, OnDestroy {

  iframeId: string|null = null;
  controller!: StartTaskPortletController;

  fullScreen = true;

  viewModel?: TaskFormViewModel;
  taskViewModel?: TaskViewModel;
  activeTaskTitle: string = "";

  taskSubmitted = false;
  formRendered: boolean = false;

  constructor(private readonly viewContainerRef: ViewContainerRef,
              private changeDetectorRef: ChangeDetectorRef,
              readonly flowService: FlowService,
              readonly flowsSharedService: FlowsSharedService,
              readonly filesSharedService: FilesSharedService,
              readonly globalEventBus: GlobalEventBus,
              readonly personsSharedService: PersonsSharedService,
              readonly i18nService: I18nService,
              readonly tasksStatusManager: TasksStatusManager,
              readonly applicationsSharedService: ApplicationsSharedService,
              readonly taskListManagerService: TaskListManagerService,
              readonly serverEventsService: ServerEventsService,
              readonly adHocTaskHelper: AdHocTaskHelper,
              readonly systemSettingsService: SystemSettingsService,
              readonly navigationService: NavigationService,
              readonly processesNamingQueryService: ProcessesNamingQueryService,
              readonly screenInstanceSharedWrapperService: ScreenInstanceSharedWrapperService) {}

  private lastHash = "";

  ngOnInit() {

    console.log("Portlet");

    this.init();
    this.lastHash = window.location.pathname;

    mySetInterval(this.onLocationChanged, 100); // there seem to be no way to listen on url search params



    // Handling setting of visible box size from external page (if portlet is in iframe)

    const eventMethod = <any>window.addEventListener ? "addEventListener" : "attachEvent";
    const eventer: any = window[<any>eventMethod];
    const messageEvent = eventMethod === "attachEvent" ? "onmessage" : "message";

    eventer(messageEvent,(e: any) => {
      if((typeof e.data == "string") && e.data.indexOf('portletVisibleBox::') !== -1) {
        const visibleBox = JSON.parse(e.data.substring("portletVisibleBox::".length));
        setPageVisibleBox(visibleBox);
      }
    })
  }

  private onLocationChanged = () => {
    if(window.location.pathname !== this.lastHash) {
      this.lastHash = window.location.pathname;
      this.init();
    }
  }


  private init() {

    const parsed = this.parseUrl();

    if(parsed.applicationIdentifier && parsed.processIdentifier && parsed.nodeIdentifier) {
      const taskEventBus = new TaskEventBus();
      const tasksEventBus = new TasksEventBus();

      const tasksServerModel = new TasksServerModel(this.taskListManagerService, this.processesNamingQueryService, this.personsSharedService,
        this.flowService, this.globalEventBus, Some(taskEventBus), tasksEventBus, this.i18nService, Constants.webClientUserId);

      const taskServerModel = new TaskServerModel(taskEventBus, tasksEventBus,
        this.flowService,
        this.flowsSharedService,
        Constants.webClientUserId, "", "", "",
        this.personsSharedService,
        new TaskEventListener(this.serverEventsService, taskEventBus),
        this.i18nService,
        this.applicationsSharedService);

      taskServerModel.openNewTaskExternal(parsed.applicationIdentifier, parsed.processIdentifier, parsed.nodeIdentifier, [])

      taskEventBus.on(taskEventBus.taskLoaded, (task: TaskModel, applicationName: string, applicationIdentifier: string, applicationIcon: ApplicationIcon, openForm: boolean) => {

        this.taskViewModel = TaskViewModel.of(this.fullScreen, task, applicationName, applicationIcon, taskServerModel, tasksServerModel, taskEventBus,
          tasksEventBus,
          this.filesSharedService, Constants.webClientUserId, DepartmentCalendar.empty(), this.systemSettingsService.getEffectiveTimeZone(),
          this.tasksStatusManager, this.i18nService, this.taskListManagerService, this.flowsSharedService, this.navigationService, this.adHocTaskHelper);

        const taskTitle = task.flowDescription.trim().length === 0
          ? task.processName
          : task.processName + " - " + task.flowDescription.trim();
        this.activeTaskTitle = taskTitle;

        const attachmentsUrlViewModel = new AttachmentsUrlViewModel(task.flowIdUnwrapped(), task.nodeId, None(), false, true, false);
        Option.of(this.viewModel).forEach(f => f.destroy());
        this.viewModel = new TaskFormViewModel(taskEventBus, tasksEventBus, taskServerModel,
          Constants.webClientUserId, task, attachmentsUrlViewModel, false,
          () => {
            return this.screenInstanceSharedWrapperService.runForFlowTask(task.toTaskIdentifier(), false);
          },  () => {
            this.taskSubmitted = true;
          });

        setTimeout(() => {
          this.taskFormRendered();
        }, 300)

      })




      //
      // taskServerModel.
      //
      // const attachmentsUrlViewModel = new AttachmentsUrlViewModel(task.flowIdUnwrapped(), task.nodeId, None(), false, true, false);
      // this.activeTaskForm.forEach(f => f.destroy());
      // this.activeTaskForm = Some(new TaskFormViewModel(this.taskEventBus, this.tasksEventBus, this.taskServerModel,
      //   PersonId.of(sessionInfo.personId), task, attachmentsUrlViewModel, false,
      //   () => {
      //     return this.screenInstanceSharedWrapperService.runForFlowTask(task.toTaskIdentifier(), false);
      //   },  () => {
      //     this.taskCompleted.emit();
      //   }));
      //
      // this.taskViewModel = this.activeTaskDetails.get();
      // this.viewModel = this.activeTaskForm.get();





      //
      // if(parsed.screenInstanceId !== undefined) {
      //   newViewModel = ScreenPortletViewModel.forGivenScreenInstance(this.screenInstanceService, this.screenInstanceSharedService, this.iframeId,
      //     new ScreenInstanceId(parsed.screenInstanceId));
      // } else if(parsed.applicationIdentifier !== undefined && parsed.screenIdentifier !== undefined) {
      //   newViewModel = ScreenPortletViewModel.forNewScreenInstance(this.screenInstanceService, this.screenInstanceSharedService, this.iframeId,
      //     parsed.applicationIdentifier, parsed.screenIdentifier, this.getParamsFromUrl());
      // } else {
      //   throw new Error("Incorrect params");
      // }

      this.controller = new StartTaskPortletController(this.viewContainerRef.element.nativeElement, undefined, 13, "#ffffff");
      this.controller.start();



      this.initGlobalStyles(parsed.fontSizePx, parsed.backgroundColor, parsed.fontFamily, parsed.pageScroll);

      // TODO make it responsive
      // const int = mySetInterval(() => {
      //   if(newViewModel.screenInstanceId !== null || newViewModel.error !== null) {
      //     // this.viewModel = newViewModel;
      //     this.changeDetectorRef.detectChanges();
      //     clearInterval(int);
      //     mySetTimeoutNoAngular(() => { // TODO this can be improved by getting callback from screen view model, or maybe from screen component
      //       parent.postMessage("screenPortletLoaded","*");
      //     });
      //   }
      // }, 8);



    } else {
      toastr.error("Incorrect url");
    }

  }

  private getParamsFromUrl() {
    const params: Record<string, string> = {};
    for (const [key, value] of (new URLSearchParams(window.location.search).entries())) {
      params[key] = value;
    }
    return params;
  }

  private initGlobalStyles(fontSizePx: number|undefined, backgroundColor: string|undefined, fontFamily: string|undefined, pageScroll: "on"|"off"|undefined) {
    let style = "";

    if (fontSizePx !== undefined) {
      style += `html, body {font-size: ${fontSizePx}px !important;}`;
    }

    if (backgroundColor !== undefined) {
      style += "html, body {background-color: #" + backgroundColor + " !important;} #pageWelcome {background-color: #" + backgroundColor + " !important;}";
    }

    if (pageScroll === "on") {
      style += `html {overflow-y: scroll !important;}`;
    } else if (pageScroll === "off") {
      style += `html {overflow-y: hidden !important;}`;
    }

    if (style.length > 0) {
      required(document.getElementById("predefinedStyle"), "No predefinedStyle").innerHTML = style;
    }
  }

  private parseUrl(): {
    applicationIdentifier?: string,
    processIdentifier?: string,
    nodeIdentifier: string,
    backgroundColor?: string,
    pageScroll?: "on"|"off",
    fontSizePx?: number,
    fontFamily?: string,
    iframeId?: string
  } {


    // Removes first segment of URL
    const urlWithoutFirstSegment = window.location.pathname.substring(window.location.pathname.indexOf("/", 1));

    let questionMarkIndex = urlWithoutFirstSegment.indexOf("?");
    const hash = questionMarkIndex >= 0
      ? urlWithoutFirstSegment.substring(1, questionMarkIndex)
      : urlWithoutFirstSegment.substring(1);

    const urlPath = hash.split("/").filter(t => t.length > 0);

    let applicationIdentifier: string|undefined = undefined;
    let processIdentifier: string|undefined = undefined
    let nodeIdentifier: string|undefined = undefined;
    let backgroundColor: string|undefined = undefined;
    let fontSizePx: number|undefined = undefined;
    let fontFamily: string|undefined = undefined;
    let iframeId: string|undefined = undefined;
    let pageScroll: string|undefined = undefined;


    let portletStyleParams: Array<string> = [];
    if(urlPath.length >= 3) {
      applicationIdentifier = urlPath[0];
      processIdentifier = urlPath[1];
      nodeIdentifier = urlPath[2];
      portletStyleParams = urlPath.slice(2);
    } else {
      throw new Error("Incorrect URL");
    }

    __(portletStyleParams).find(p => p.toLowerCase().match(/^n-background-color=[0-9a-f]{6}$/) !== null).forEach(definition => {
      backgroundColor = definition.substring("n-background-color=".length);
    });

    __(portletStyleParams).find(p => p.toLowerCase().match(/^n-font-size=[1-9]?[0-9]+$/) !== null).forEach(definition => {
      fontSizePx = parseInt(definition.substring("n-font-size=".length));
    });

    __(portletStyleParams).find(p => p.toLowerCase().match(/^n-scroll=(on|off)$/) !== null).forEach(definition => {
      pageScroll = definition.substring("n-scroll=".length);
    });

    __(portletStyleParams).find(p => p.toLowerCase().match(/^n-iframe-id=[1-9]?[0-9]+$/) !== null).forEach(definition => {
      iframeId = definition.substring("n-iframe-id=".length);
    });

    return {applicationIdentifier: applicationIdentifier,
      processIdentifier: processIdentifier,
      nodeIdentifier: nodeIdentifier,
      backgroundColor: backgroundColor,
      fontSizePx: fontSizePx,
      pageScroll: pageScroll,
      fontFamily: fontFamily,
      iframeId: iframeId
    };

  }

  ngOnDestroy(): void {
    window.removeEventListener('locationchange', this.onLocationChanged);
    this.controller.stop();
  }


  taskFormRendered() {
    this.formRendered = true;
  }
}
