import {PositionXY} from "./PositionXY";
import {Size} from "./Size";

export type ResizeType = "NW"|"NE"|"SW"|"SE"|"N"|"S"|"W"|"E";

export class RectXY {

  constructor(readonly x: number, readonly y: number, readonly width: number, readonly height: number) {}

  get centerPosition(): PositionXY {
    return new PositionXY(this.x + this.width / 2, this.y + this.height / 2);
  }

  get size(): Size {
    return new Size(this.width, this.height);
  }

  get position(): PositionXY {
    return new PositionXY(this.x, this.y);
  }

  get endX(): number {
    return this.x + this.width;
  }

  get endY(): number {
    return this.y + this.height;
  }

  get centerX(): number {
    return this.x + this.width / 2;
  }

  get centerY(): number {
    return this.y + this.height / 2;
  }

  contains(point: PositionXY): boolean {
    return point.x >= this.x && point.x < this.x + this.width
      && point.y >= this.y && point.y < this.y + this.height;
  }

  containsRect(rect: RectXY): boolean {
    return rect.x >= this.x && rect.endX <= this.x + this.width
      && rect.y >= this.y && rect.endY <= this.y + this.height;
  }

  containsWithMargin(point: PositionXY, margin: number): boolean {
    return point.x >= this.x - margin && point.x < this.x + this.width + margin
      && point.y >= this.y - margin && point.y < this.y + this.height + margin;
  }

  containsY(pointY: number): boolean {
    return pointY >= this.y && pointY < this.y + this.height;
  }

  containsXY(x: number, y: number): boolean {
    return x >= this.x && x < this.x + this.width
      && y >= this.y && y < this.y + this.height;
  }

  combineWith(other: RectXY): RectXY {
    const minX = Math.min(this.x, other.x);
    const minY = Math.min(this.y, other.y);
    const maxX = Math.max(this.x + this.width, other.x + other.width);
    const maxY = Math.max(this.y + this.height, other.y + other.height);
    return new RectXY(minX,minY, maxX - minX, maxY - minY);
  }

  shift(position: PositionXY): RectXY {
    return new RectXY(this.x + position.x, this.y + position.y, this.width, this.height);
  }

  unshift(position: PositionXY): RectXY {
    return new RectXY(this.x - position.x, this.y - position.y, this.width, this.height);
  }

  equals(other: RectXY) {
    return this.x === other.x && this.y === other.y && this.width === other.width && this.height === other.height;
  }

  enlarge(size: number) {
    return new RectXY(this.x - size, this.y - size, this.width + 2 * size, this.height + 2 * size);
  }

  touchesTop(y: number, paddingIn: number, paddingOut: number) {
    return y >= this.y - paddingOut && y < this.y + paddingIn;
  }

  touchesBottom(y: number, paddingIn: number, paddingOut: number) {
    return y <= this.endY + paddingOut && y > this.endY - paddingIn;
  }

  touchesLeft(x: number, paddingIn: number, paddingOut: number) {
    return x >= this.x - paddingOut && x < this.x + paddingIn;
  }

  touchesRight(x: number, paddingIn: number, paddingOut: number) {
    return x <= this.endX + paddingOut && x > this.endX - paddingIn;
  }

  getResize(pointer: PositionXY, paddingIn: number = 0, paddingOut: number = 0): ResizeType|undefined {
    if(this.touchesTop(pointer.y, paddingIn, paddingOut)) {
      if(this.touchesLeft(pointer.x, paddingIn, paddingOut)) {
        return "NW";
      } else if(this.touchesRight(pointer.x, paddingIn, paddingOut)) {
        return "NE";
      } else {
        return "N";
      }
    } else if(this.touchesBottom(pointer.y, paddingIn, paddingOut)) {
      if(this.touchesLeft(pointer.x, paddingIn, paddingOut)) {
        return "SW";
      } else if(this.touchesRight(pointer.x, paddingIn, paddingOut)) {
        return "SE";
      } else {
        return "S";
      }
    } else if(this.touchesLeft(pointer.x, paddingIn, paddingOut)) {
      return "W";
    } else if(this.touchesRight(pointer.x, paddingIn, paddingOut)) {
      return "E";
    } else {
      return undefined;
    }
  }

  static of(position: PositionXY, size: Size) {
    return new RectXY(position.x, position.y, size.width, size.height);
  }
}
