/**
 * It gets position of the element on the page.
 * @param element DOM Node
 */
import {PositionXY} from "../data-types/PositionXY";
import {RectXY} from "../data-types/RectXY";
import {Size} from "../data-types/Size";
import {$$, $$Element} from "../$$";

// This visibleBox might be injected when screen is used within an iframe
let visibleBox: { top: number, bottom: number, left: number, right: number } | undefined = undefined;

export function setPageVisibleBox(newVisibleBox: { top: number, bottom: number, left: number, right: number }) {
  visibleBox = newVisibleBox;
}

export function getVisibleBox(): { top: number, bottom: number, left: number, right: number } {

  const windowScroll = getWindowScroll();

  if (visibleBox === undefined) {
    return {
      top: windowScroll.y,
      bottom: windowScroll.y + window.innerHeight,
      left: windowScroll.x,
      right: windowScroll.x + window.innerWidth
    }
  } else {
    return {
      top: windowScroll.y + visibleBox.top,
      bottom: windowScroll.y + visibleBox.bottom,
      left: windowScroll.x + visibleBox.left,
      right: windowScroll.x + visibleBox.right
    }
  }
}

export function getElementPosition(element: Element | Node | $$Element): PositionXY {
  const finalElement = element instanceof $$Element ? element.getAsElement() : element;
  const rect = (<any>finalElement).getBoundingClientRect();
  const windowScroll = getWindowScroll();
  return new PositionXY(rect.left + windowScroll.x, rect.top + windowScroll.y);
}

export function getElementRelativePosition(element: Element | Node | $$Element, relativeTo: Element | Node | $$Element): PositionXY {
  const elementPosition = getElementPosition(element);
  const relativeToPosition = getElementPosition(relativeTo);
  return new PositionXY(elementPosition.x - relativeToPosition.x, elementPosition.y - relativeToPosition.y);
}


let windowsScrollCache: PositionXY|undefined = undefined
window.onscroll = () => {
  windowsScrollCache = undefined;
}

export function getWindowScroll(): PositionXY {
  if (!windowsScrollCache) {
    const doc = document.documentElement;
    const windowLeftScroll = (window.pageXOffset || doc.scrollLeft) - (doc.clientLeft || 0);
    const windowTopScroll = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    windowsScrollCache = new PositionXY(windowLeftScroll, windowTopScroll);
  }
  return windowsScrollCache;
}

export function getElementPositionAndSize(element: Element | Node|$$Element): RectXY {
  const finalElement = element instanceof $$Element ? element.getAsElement() : element;
  const rect = (<any>finalElement).getBoundingClientRect();
  const windowScroll = getWindowScroll();
  return new RectXY(rect.left + windowScroll.x, rect.top + windowScroll.y, rect.right - rect.left, rect.bottom - rect.top);
}


export function getElementSize(element: Element|Node|$$Element): Size {
  const finalElement = element instanceof $$Element ? element.getAsElement() : element;
  const rect = (<any>finalElement).getBoundingClientRect();
  return new Size(rect.right - rect.left, rect.bottom - rect.top);
}

export function getElementHeight(element: Element|Node|$$Element): number {
  const finalElement = element instanceof $$Element ? element.getAsElement() : element;
  const rect = (<any>finalElement).getBoundingClientRect();
  return rect.bottom - rect.top;
}

export function getElementWidth(element: Element|Node|$$Element): number {
  const finalElement = element instanceof $$Element ? element.getAsElement() : element;
  const rect = (<any>finalElement).getBoundingClientRect();
  return rect.right - rect.left;
}

let remSize = parseInt(window.getComputedStyle(document.body).fontSize);
let windowSize = new Size(document.body.clientWidth, window.innerHeight);

window.addEventListener("resize", () => {
  updateWindowBasedSize();
});

export function updateWindowBasedSize() {
  remSize = parseInt(window.getComputedStyle(document.body).fontSize);
  windowSize = new Size(document.body.clientWidth, window.innerHeight);
}

export function getREMSize(): number {
  return remSize;
}

export function getWindowSize(): Size {
  return windowSize;
}

export function getElementCenterPosition(element: Element|Node|$$Element): PositionXY {
  const finalElement = element instanceof $$Element ? element.getAsElement() : element;
  const position = getElementPosition(finalElement);
  const size = getElementSize(finalElement);
  return new PositionXY(position.x + size.width / 2, position.y + size.height / 2);
}

export function getScrollPosition(element: Element|Node|$$Element): PositionXY {
  const finalElement = element instanceof $$Element ? element.getAsElement() : element;
  return new PositionXY((<any>finalElement).scrollLeft, (<any>finalElement).scrollTop);
}

export function getScrollSize(element: Element|Node|$$Element): Size {
  const finalElement = element instanceof $$Element ? element.getAsElement() : element;
  return new Size((<any>finalElement).scrollWidth, (<any>finalElement).scrollHeight);
}

export function setScrollYPosition(element: HTMLElement|$$Element, scrollY: number): void {
  const finalElement = element instanceof $$Element ? element.getAsElement() : element;
  finalElement.scrollTop = scrollY;
}

export function setScrollXPosition(element: HTMLElement|$$Element, scrollX: number) {
  const finalElement = element instanceof $$Element ? element.getAsElement() : element;
  finalElement.scrollLeft = scrollX;
}

export function shiftScrollPosition(element: Element | Node, scrollXShift: number, scrollYShift: number) {
  if(scrollYShift !== 0) {
    setScrollYPosition(<HTMLElement>element, (<HTMLElement>element).scrollTop + scrollYShift);
  }
  if(scrollXShift !== 0) {
    setScrollXPosition(<HTMLElement>element, (<HTMLElement>element).scrollLeft + scrollXShift);
  }
}

export function isChildOf(containter: Element|$$Element, child: Element|$$Element): boolean {
  const finalContainer = containter instanceof $$Element ? containter.getAsElement() : containter;
  const finalChild = child instanceof $$Element ? child.getAsElement() : child;
  return finalContainer.contains(finalChild);
}

export function elementContainsPoint(element: Element|$$Element, point: PositionXY): boolean {
  const finalElement = element instanceof $$Element ? element.getAsElement() : element;
  const rect = (<any>finalElement).getBoundingClientRect();
  return point.x >= rect.left && point.x <= rect.right && point.y >= rect.top && point.y <= rect.bottom;
}


export function toHtmlElement(element: Element | Node | EventTarget | null): HTMLElement {
  if (element === null) {
    throw new Error("Element is null");
  }
  if (element instanceof HTMLElement) {
    return element;
  } else {
    throw new Error("Element is not an HTMLElement");
  }

}


export function scrollHorizontalIntoViewWithinContainer(container: HTMLElement | $$Element, element: HTMLElement | $$Element) {
  const finalElement: Element = element instanceof $$Element ? element.getAsElement() : element;
  const finalScrollableContainer: Element = container instanceof $$Element ? container.getAsElement() : container;

  const elementBounds = finalElement.getBoundingClientRect();
  const containerBounds = finalScrollableContainer.getBoundingClientRect();
  const visibleXFrom = finalScrollableContainer.scrollLeft;
  const visibleXTo = visibleXFrom + containerBounds.width;
  const elementXWithinContainer = elementBounds.x - containerBounds.x + finalScrollableContainer.scrollLeft;
  const elementXWithinContainerEnd = elementXWithinContainer + elementBounds.width;

  if (elementXWithinContainer < visibleXFrom) {
    finalScrollableContainer.scrollBy({left: - (visibleXFrom - elementXWithinContainer), behavior: "smooth"});
  } else if (elementXWithinContainerEnd > visibleXTo) {
    finalScrollableContainer.scrollBy({left: (elementXWithinContainerEnd - visibleXTo), behavior: "smooth"});
  }
}

export function tryToFocusElement(element: HTMLElement) {
  element.focus();
  if (element instanceof HTMLInputElement) {
    if($$(element).attr("type") !== "email") { // workaround for: The input element's type ('email') does not support selection.
      try {
        element.selectionStart = element.selectionEnd = element.value.length;
      } catch (e) {
        // we've tried
      }
    }
  } else {
    try {
      if(document.createRange)
      {
        const range = document.createRange();
        range.selectNodeContents(element);
        range.collapse(false);
        const selection: Selection|null = window.getSelection();
        if(selection) {
          selection.removeAllRanges();
          selection.addRange(range);
        }
      }
    } catch (e) {
      // we've tried
    }
  }
}


export function tryToSelectElement(element: HTMLElement) {
  if (element instanceof HTMLInputElement) {
    if($$(element).attr("type") !== "email") { // workaround for: The input element's type ('email') does not support selection.
      try {
        element.select();
        element.selectionStart = 0;
        element.selectionEnd = element.value.length;
      } catch (e) {
        // we've tried
      }
    }
  } else {

    try {
      if (document.createRange) {
        const range = document.createRange();
        range.selectNodeContents(element);
        const selection: Selection | null = window.getSelection();
        if (selection) {
          selection.removeAllRanges();
          selection.addRange(range);
        }
      }
    } catch (e) {
      // we've tried
    }
  }
}



export function isAttachedToBody(element: HTMLElement):boolean {
  let parent = element.parentElement;
  while(parent) {
    if(parent === document.body) {
      return true;
    }
    parent = parent.parentElement;
  }
  return false;
}


export function scrollElementToView(container: $$Element, element: $$Element, margin: number = 100) {

    const scrollPosition = getScrollPosition(container);

    const position = getElementRelativePosition(element, container).shiftXY(0, scrollPosition.y);
    container.getAsHtmlElement().scroll({behavior: "smooth", top: position.y - margin});
}

export function noActiveInputInDocument(): boolean {
  return document.activeElement == null ||
    document.activeElement.tagName.toUpperCase() != "INPUT" &&
    document.activeElement.tagName.toUpperCase() != "TEXTAREA" &&
    (!(document.activeElement instanceof HTMLElement) || !(<HTMLElement>document.activeElement).isContentEditable);
}

export function createSvgElement(tag: string): $$Element {
  return $$(document.createElementNS("http://www.w3.org/2000/svg", tag));
}

export function blurAnyFocusedElement() {
  if (document.activeElement) {
    (document.activeElement as HTMLElement).blur();
  }
}
