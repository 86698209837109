import {LabelPosition} from "../FormField";
import {FormElementId, FormElementRefId, GridSize, GridXY, I18nText, Option, Trilean, Typed} from "@utils";
import {InputElement, InputElementRef} from "../FormModel";
import {
  BusinessVariablesValidation,
  BusinessVariableType,
  BusinessVariableValidationFactory,
  ExpressionWithAst,
  GeoCoordinate,
  GeoCoordinateVariable,
  VariableTypePath
} from "@shared-model";

export class MapField implements InputElement {
  static className = "MapField";

  defaultVariable(variableType: BusinessVariableType) {
    return new GeoCoordinateVariable(new GeoCoordinate(52.2296756, 21.0122287));
  }


  cssClassName(): string {
    return "mapField";
  }

  className() {
    return MapField.className;
  }

  isInputElement(): boolean {
    return true;
  }

  constructor(public id: FormElementId,
              public tooltip: I18nText,
              public variableTypePath: VariableTypePath,
              public validation: Option<Typed<BusinessVariablesValidation>>,
              public hiddenExpression: Option<ExpressionWithAst>,
              public requiredExpression: Option<ExpressionWithAst>,
              public readOnlyExpression: Option<ExpressionWithAst>) {}

  static copy(other: MapField): MapField {
    return new MapField(other.id, I18nText.copy(other.tooltip),
      VariableTypePath.copy(other.variableTypePath),
      Option.copy(other.validation, BusinessVariableValidationFactory.copyTyped),
      Option.copy(other.hiddenExpression, ExpressionWithAst.copy),
      Option.copy(other.requiredExpression, ExpressionWithAst.copy),
      Option.copy(other.readOnlyExpression, ExpressionWithAst.copy));
  }
}

export class MapFieldRef implements InputElementRef {
  static className = "MapFieldRef";

  className(): string {
    return MapFieldRef.className;
  }

  isInputElementRef() {
    return true;
  }

  constructor(public id: FormElementRefId,
              public elementId: FormElementId,
              public gridXY: GridXY,
              public gridSize: GridSize,
              public label: I18nText,
              public labelPosition: LabelPosition,
              public labelGridShift: GridXY,
              public required: Trilean,
              public readOnly: Trilean,
              public hidden: Trilean,
              public visibleInSummary: boolean,
              public visibleInTaskBox: boolean) {}

  static copy(other: MapFieldRef): MapFieldRef {
    return new MapFieldRef(other.id, other.elementId, GridXY.copy(other.gridXY), GridSize.copy(other.gridSize),
      I18nText.copy(other.label), LabelPosition.copy(other.labelPosition), GridXY.copy(other.labelGridShift),
      Trilean.copy(other.required), Trilean.copy(other.readOnly), Trilean.copy(other.hidden), other.visibleInSummary, other.visibleInTaskBox);
  }
}
