export function arraysEqual(arrayA: Array<any>|ReadonlyArray<any>, arrayB: Array<any>|ReadonlyArray<any>) {
  if (arrayA.length !== arrayB.length) {
    return false;
  } else {
    for (let i = 0; i < arrayA.length; i++) {
      if (arrayA[i] !== arrayB[i]) {
        return false;
      }
    }
    return true;
  }
}

export function arraysEqualBy<T>(arrayA: ReadonlyArray<T>|Array<T>, arrayB: ReadonlyArray<T>|Array<T>, equals: (a: T, b: T) => boolean) {
  if (arrayA.length !== arrayB.length) {
    return false;
  } else {
    for (let i = 0; i < arrayA.length; i++) {
      if (!equals(arrayA[i], arrayB[i])) {
        return false;
      }
    }
    return true;
  }
}


export function range(length: number): Array<number>;
export function range(from: number, to: number): Array<number>;
export function range(a: number, b?: number): Array<number> {
  const from = b === undefined ? 0 : a;
  const to = b === undefined ? a : b;
  const r = [];
  for(let i = from; i < to; i++) {
    r.push(i);
  }
  return r;
}

export function arrayReplace<T>(array: Array<T>, newValue: Array<T>) {
  array.splice(0, array.length, ...newValue);
}

export function arrayMove<T>(array: Array<T>, fromIndex: number, toIndex: number) {
  const element = array[fromIndex];
  array.splice(fromIndex, 1);
  array.splice(toIndex, 0, element);
}

export function arrayReplaceSwitchElements<T>(array: Array<T>, index1: number, index2: number) {
  const element1 = array[index1];
  array[index1] = array[index2];
  array[index2] = element1;
}


export function arrayFromIterator<T>(iterator: Iterator<T>): Array<T> {
  const result: Array<T> = [];
  let iteratorResult = iterator.next();

  while (!iteratorResult.done) {
    result.push(iteratorResult.value);
    iteratorResult = iterator.next();
  }

  return result;
}
