import {Language} from "./data-types/Language";
import {global} from "./global";
import {I18nText} from "./data-types/I18nText";
import {LocalDateTime} from "./data-types/Time";

export interface I18nServiceInterface {
  translate: (key: string) => string;
  currentLanguage: () => Language;
  currentLocale: () => string;
  formatMediumDateTime(timestamp: number|Date|LocalDateTime): string;
}

const cache = new Map<string, string>();

export function i18n(key: I18nText): string;
export function i18n(key: string, options?: any): string;
export function i18n(key: I18nText|string, options?: any): string {

  if(key === "") {
    return "";
  } else if(key instanceof I18nText) {
    return key.getCurrentWithFallback();
  } else {
    if(options !== undefined) {
      let translatedValue = global.i18nService.translate(key);
      for (const [key, value] of Object.entries(options)) {
        const toFind = "{{" + key + "}}";
        const found = translatedValue.indexOf(toFind);
        if(found > 0) {
          translatedValue = translatedValue.replace(toFind, JSON.stringify(value));
        }
      }
      return translatedValue;
    } else {

      const cached = cache.get(key);
      if(cached) {
        return cached;
      } else {
        const t = global.i18nService.translate(key);
        cache.set(key, t);
        return t;
      }
    }
  }
}

export function i18nLanguage(): Language {
  return global.i18nService.currentLanguage();
}
