import {Component, OnInit} from "@angular/core";
import {NavigationService, SessionServiceProvider} from "@shared";
import {ActivatedRoute} from "@angular/router";
import {toastr, valueOrDefault} from "@utils";

@Component({
  selector: 'my-impersonate-page',
  templateUrl: './impersonate-page.component.html',
})
export class ImpersonatePageComponent implements OnInit {

  constructor(private readonly sessionServiceProvider: SessionServiceProvider,
              private readonly activatedRoute: ActivatedRoute,
              private readonly navigationService: NavigationService) {}

  ngOnInit(): void {

    const impersonateId = this.activatedRoute.snapshot.paramMap.get("impersonateId");

    this.sessionServiceProvider.getSessionService(sessionService => {
      sessionService.initImpersonate(valueOrDefault(impersonateId, ""), () => {
        this.navigationService.navigateToMainPage();
      }, () => {
        toastr.error("Error while trying to impersonate");
      })
    })
  }



}
