import {
  BasicPersonInfo,
  BusinessVariable, CursorInfo,
  FlowImportance,
  FlowUrgency,
  PersonsSharedService,
  PersonSummary, ProcessEdgeId,
  ProcessesNamingQueryService,
  TaskIdentifier,
  TaskListManagerService,
  TaskModelSummary,
  TasksEventBus, TaskStatus
} from "@shared-model";
import {GlobalEventBus, I18nService, SessionServiceProvider} from "@shared";
import {
  __,
  ___,
  AggregateVersion,
  AnyFlowId,
  AnyFlowIdHelper,
  LocalDateTime,
  None,
  Option, PersonId,
  Some,
  toastr,
  ToastrCategory, Typed
} from "@utils";
import {FlowService} from "./FlowService";
import {TaskEventBus} from "../TaskEventBus";
import {
  SubmitFormFailedResponseHandler,
  SubmitFormFailureResponse,
  SubmitFormSuccessResponse
} from "../model/ProcessFlowValidationErrors";

export class TasksServerModel {

  private tasksToBeMoved: Array<TaskModelSummary> = [];
  // private tasks: Array<TaskModelSummary> = [];
  private archivedTasks: Option<Array<TaskModelSummary>> = None();
  private includeArchived: boolean = false;

  constructor(readonly taskListManagerService: TaskListManagerService,
              readonly processesNamingQueryService: ProcessesNamingQueryService,
              readonly personsSharedService: PersonsSharedService,
              readonly flowService: FlowService,
              readonly globalEventBus: GlobalEventBus,
              readonly taskEventBus: Option<TaskEventBus>,
              readonly tasksEventBus: TasksEventBus,
              readonly i18nService: I18nService,
              readonly currentPerson: PersonId) {
  }


  private withVariableNames(tasks: Array<TaskModelSummary>, onDone: () => void) {

    const variables: Array<BusinessVariable> = [];

    tasks.forEach(t => t.systemLabelsUnwrapped().forEach(l => {
      if (!__(variables).exists(v => v.isEqual(l))) {
        variables.push(l);
      }
    }))

    if (variables.length > 0) {
      this.processesNamingQueryService.preloadNamesForVariables(variables, () => {
        onDone();
      });
    } else {
      onDone();
    }


  }

  private withPersons(tasks: Array<TaskModelSummary>, callback: (persons: Array<PersonSummary>) => void) {
    this.personsSharedService.findPersonBasicInfo(___(tasks).filter(t => t.personsAssigned.length > 0).flatMap(t => t.personsAssignedUnwrapped()).value(), (data: { [personId: string]: BasicPersonInfo }) => {
      const persons = Object.values(data).map((p: BasicPersonInfo) => p.toPersonSummary());
      callback(persons);
    });
  }


  private onTasksListUpdated(added: Array<TaskModelSummary>, updated: Array<TaskModelSummary>, removed: Array<TaskIdentifier>, tasksToBeMoved: Array<TaskModelSummary>) {
    // this.reloadTasks(added, updated, removed, tasksToBeMoved);

    const existingUpdated = __(updated)
      .filter( u => !__(this.tasksToBeMoved).exists(t => AnyFlowIdHelper.equals(u.flowIdUnwrapped(), t.flowIdUnwrapped()) && u.nodeId == t.nodeId));

    const filteredUpdated = this.filterTasks(existingUpdated);

    const removedUpdated = existingUpdated.filter(u => !__(filteredUpdated).exists(t => AnyFlowIdHelper.equals(u.flowIdUnwrapped(), t.flowIdUnwrapped()) && u.nodeId == t.nodeId));

    this.withPersons(this.filterTasks(added.concat(filteredUpdated)), persons => {
      this.tasksEventBus.tasksUpdated(this.filterTasks(added), filteredUpdated, removed.concat(removedUpdated.map(t => t.toTaskIdentifier())), persons);
    });
  }


  // private reloadTasks(added: TaskModelSummary[], updated: TaskModelSummary[], removed: TaskIdentifier[], tasksToBeMoved: TaskModelSummary[]){
  //   console.log("reloadTasks");
  //   // it is possible that which is updated is during move to a different node - filter out to prevent flickering
  //   const existingUpdated = __(updated)
  //     .filter( u => !__(this.tasksToBeMoved).exists(t => AnyFlowIdHelper.equals(u.flowIdUnwrapped(), t.flowIdUnwrapped()) && u.nodeId == t.nodeId));
  //
  //   this.tasksToBeMoved = this.tasksToBeMoved
  //     .filter(t => !__(added).exists( a => AnyFlowIdHelper.equals(a.flowIdUnwrapped(), t.flowIdUnwrapped()) && a.nodeId == t.nodeId))
  //     .concat(tasksToBeMoved);
  //
  //   this.taskListManagerService.getTasks(tasks => {
  //
  //   });
  //
  //   this.tasks = this.tasks
  //     .filter(t => !__(removed).exists(identifier => t.is(identifier)))
  //     .filter(t => !__(added).exists(u => AnyFlowIdHelper.equals(u.flowIdUnwrapped(), t.flowIdUnwrapped()) && u.nodeId == t.nodeId))
  //     .filter(t => !__(existingUpdated).exists(u => AnyFlowIdHelper.equals(u.flowIdUnwrapped(), t.flowIdUnwrapped()) && u.nodeId == t.nodeId))
  //     .concat(added)
  //     .concat(existingUpdated);
  // }

  private filterTasks(tasks: Array<TaskModelSummary>): Array<TaskModelSummary> {
    return tasks;
  }

  overrideUrgency(taskId: TaskIdentifier, urgency: Option<FlowUrgency>) {

    this.taskListManagerService.getTasks(tasks => {
      __(tasks).find(t => t.is(taskId)).forEach(task => {
        this.flowService.overrideUrgency(task.flowIdUnwrapped(), task.cursorInfo.getOrError("No cursor info").cursorId, task.cursorInfo.getOrError("No cursor info").cursorVersion, urgency, () => {
        });
      });
    });

  }

  overrideImportance(taskId: TaskIdentifier, importance: Option<FlowImportance>) {
    this.taskListManagerService.getTasks(tasks => {
      __(tasks).find(t => t.is(taskId)).forEach(task => {
        this.flowService.overrideImportance(task.flowIdUnwrapped(), task.cursorInfo.getOrError("No cursor info").cursorId, task.cursorInfo.getOrError("No cursor info").cursorVersion, importance, () => {
        });
      });
    });
  }

  overrideDeadline(taskId: TaskIdentifier, deadline: Option<Option<LocalDateTime>>) {
    this.taskListManagerService.getTasks(tasks => {
      __(tasks).find(t => t.is(taskId)).forEach(task => {
        this.flowService.overrideTaskDeadline(task.flowIdUnwrapped(), task.nodeId, deadline, () => undefined);
      });
    });
  }


  changeDeadline(taskId: TaskIdentifier, deadline: Option<LocalDateTime>) {
    this.taskListManagerService.getTasks(tasks => {
      __(tasks).find(t => t.is(taskId)).forEach(task => {
        this.flowService.changeTaskDeadline(task.flowIdUnwrapped(), task.nodeId, deadline, () => undefined);
      });
    });
  }

  startTask(taskId: TaskIdentifier) {
    // ignore not available tasks
    this.taskListManagerService.getTasks(tasks => {
      __(tasks).find(t => t.is(taskId)).forEach(task => {
        const taskSnapshot = TaskModelSummary.copy(task);
        this.flowService.startTask(task.flowIdUnwrapped(), task.cursorInfo.get().cursorId, task.cursorInfo.get().cursorVersion, () => {
          this.tasksEventBus.tasksMoveConfirmed([task.toTaskIdentifier()]);
        }, () => {
          this.onTasksListUpdated([], [taskSnapshot], [], []);
          this.tasksEventBus.tasksMoveFailed([task.toTaskIdentifier()]);
        });
        task.started = Some(LocalDateTime.now());
        this.onTasksListUpdated([], [task], [], []);
      });
    });
  }

  assignAndStartTasks(tasks: Array<TaskIdentifier>) {
    tasks.forEach(t => this.assignAndStartTask(t));
  }

  assignAndStartTask(taskId: TaskIdentifier) {
    this.taskListManagerService.getTasks(tasks => {
      // ignore not available tasks as probably are unavailable
      __(tasks).find(t => t.is(taskId)).forEach(task => {
        const taskSnapshot = TaskModelSummary.copy(task);

        if (task.isAvailable()) {
          this.flowService.assignSelfAndStartTask(task.flowIdUnwrapped(), task.roleId, task.cursorInfo.get().cursorId, task.cursorInfo.get().cursorVersion, () => {
            this.tasksEventBus.tasksMoveConfirmed([task.toTaskIdentifier()]);
          }, () => {
            this.onTasksListUpdated([], [taskSnapshot], [], []);
            this.tasksEventBus.tasksMoveFailed([task.toTaskIdentifier()]);
          });
          task.personsAssigned = [Typed.of(this.currentPerson)];
          task.started = Some(LocalDateTime.now());
          this.onTasksListUpdated([], [task], [], []);
        } else if (task.isTodo()) {
          this.flowService.startTask(task.flowIdUnwrapped(), task.cursorInfo.get().cursorId, task.cursorInfo.get().cursorVersion, () => {
            this.tasksEventBus.tasksMoveConfirmed([task.toTaskIdentifier()]);
          }, () => {
            this.onTasksListUpdated([], [taskSnapshot], [], []);
            this.tasksEventBus.tasksMoveFailed([task.toTaskIdentifier()]);
          });
          task.started = Some(LocalDateTime.now());
          this.onTasksListUpdated([], [task], [], []);
        } else if (task.isDone()) {
          toastr.error("Operation not yet supported");
        }
      });
    });
  }

  stopTask(taskId: TaskIdentifier) {
    this.taskListManagerService.getTasks(tasks => {
      // ignore not available tasks
      __(tasks).find(t => t.is(taskId)).forEach(task => {
        const taskSnapshot = TaskModelSummary.copy(task);
        this.flowService.stopTask(task.flowIdUnwrapped(), task.cursorInfo.get().cursorId, task.cursorInfo.get().cursorVersion, () => {
          this.tasksEventBus.tasksMoveConfirmed([task.toTaskIdentifier()]);
        }, () => {
          this.onTasksListUpdated([], [taskSnapshot], [], []);
          this.tasksEventBus.tasksMoveFailed([task.toTaskIdentifier()]);
        });
        task.started = None();
        this.onTasksListUpdated([], [task], [], []);
      });
    });
  }

  markTaskWaiting(taskId: TaskIdentifier) {

    this.taskListManagerService.getTasks(tasks => {
      // ignore not available tasks
      __(tasks).find(t => t.is(taskId)).forEach(task => {
        const taskSnapshot = TaskModelSummary.copy(task);
        this.flowService.markTaskWaiting(task.flowIdUnwrapped(), task.cursorInfo.get().cursorId, task.cursorInfo.get().cursorVersion, () => {
          this.tasksEventBus.tasksMoveConfirmed([task.toTaskIdentifier()]);
        }, () => {
          this.onTasksListUpdated([], [taskSnapshot], [], []);
          this.tasksEventBus.tasksMoveFailed([task.toTaskIdentifier()]);
        });
        task.started = None();
        this.onTasksListUpdated([], [task], [], []);
      });
    });
  }


  finishTask(task: TaskIdentifier, edgeId: ProcessEdgeId) {
    const endTask = (task: TaskModelSummary, taskSnapshot: TaskModelSummary) => {
      this.flowService.submitForm(edgeId, task.flowIdUnwrapped(), task.cursorInfo.get().cursorId, task.cursorInfo.get().cursorVersion,
        (newId) => {
          this.tasksEventBus.flowIdChanged(task.flowIdUnwrapped(), newId);
        },
        (id: AnyFlowId, version: AggregateVersion, response: SubmitFormSuccessResponse) => {
          toastr.clearMessagesWithTag(ToastrCategory.Task);
          this.tasksEventBus.tasksMoveConfirmed([task.toTaskIdentifier()]);
        },
        (id: AnyFlowId, version: AggregateVersion, response: SubmitFormFailureResponse) => {
          toastr.clearMessagesWithTag(ToastrCategory.Task);
          toastr.error(response.translate().join(", "), ToastrCategory.Task);
          this.onTasksListUpdated([], [taskSnapshot], [], []);
          this.tasksEventBus.tasksMoveFailed([task.toTaskIdentifier()]);
          this.taskEventBus.forEach(eventBus => {
            SubmitFormFailedResponseHandler.handle(eventBus, task.flowIdUnwrapped(), task.nodeId, response, this.i18nService);
          });

        },
        () => {
          toastr.clearMessagesWithTag(ToastrCategory.Task);
          this.onTasksListUpdated([], [taskSnapshot], [], []);
          this.tasksEventBus.tasksMoveFailed([task.toTaskIdentifier()]);
        },
        () => {
        });
    };



    this.taskListManagerService.getTasks(tasks => {
      // ignore not available tasks as probably are unavailable
      __(tasks).find(t => t.is(task)).forEach(task => {
        const taskSnapshot = TaskModelSummary.copy(task);

        if (task.isDone()) {
          toastr.error("Trying to complete completed task");
        } else if (task.isInProgress() || task.isInQueue()) {
          endTask(task, taskSnapshot);
          // task.taskStatus = TaskStatus.completed;
          // task.completed = Some(LocalDateTime.now());
          // this.onTasksListUpdated([], [task], [], []);
        } else {
          toastr.error("Trying to complete task that is not in progress, nor in queue");
        }
      });
    });
  }


  revertTask(taskId: TaskIdentifier) {

    this.taskListManagerService.getTask(taskId).then(taskOption => {
      taskOption.forEach(task => {

        const pullAction = task.pullActions[0];

        this.flowService.pullAction(task.flowIdUnwrapped(), pullAction.cursorId, pullAction.edgeId, None(), () => {
          // do nothing
        }, () => {
          toastr.error("Failed to revert task");
        });
      });
    });
  }

  assignTaskToCurrentUser(taskIdentifier: TaskIdentifier, roleId: number, personId: PersonId) {
    this.flowService.assignPersonToRole(taskIdentifier.flowIdUnwrapped(), roleId, personId, () => {

    }, () => {
      toastr.error("Failed to assign task to current user");
    });

    this.tasksEventBus.taskAssigned(taskIdentifier, this.currentPerson);

  }

  unassignPersonFromTask(taskIdentifier: TaskIdentifier, roleId: number, personId: PersonId) {
    this.flowService.unassignPersonsFromRole(taskIdentifier.flowIdUnwrapped(), roleId, [personId], () => {

    }, () => {
      toastr.error("Failed to un assign task to current user");
    });
    this.tasksEventBus.taskAssigned(taskIdentifier, this.currentPerson);

  }

  addLabel(taskIdentifier: TaskIdentifier, cursor: CursorInfo, newLabel: string) {
    this.flowService.addFlowLabel(taskIdentifier.flowIdUnwrapped(), cursor.cursorId, cursor.cursorVersion, newLabel, () => {

    }, () => {

    });
  }

  removeLabel(taskIdentifier: TaskIdentifier, cursor: CursorInfo, newLabel: string) {
    this.flowService.removeFlowLabel(taskIdentifier.flowIdUnwrapped(), cursor.cursorId, cursor.cursorVersion, newLabel, () => {

    }, () => {

    });
  }
}
