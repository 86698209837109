import {FormFieldState} from "./model/ProcessFlow";
import {
  AnyFlowId,
  documentRepositoryUri,
  FileUri,
  None,
  ObjectId,
  Option,
  supervisorAttachmentDownloadUrl,
  taskFlowAttachmentDownloadUrl
} from "@utils";
import {FormSectionId, FormSectionRefId} from "@shared"
import {BusinessVariable, BusinessVariableType, ProcessNodeId, VariablePath} from "@shared-model";
import {Subject} from "rxjs";
import {Checkbox, DropList, FormElement, FormElementRef, RadioButton, StaticElementRef} from "../process-common.module";

export interface SimpleElementWithDataInterface {
  elementRef: FormElementRef;
  element: FormElement;
  sectionId: FormSectionId;
  contextObjectId: Option<ObjectId>;
  gridShiftY: number;
}


export class ElementWithData implements SimpleElementWithDataInterface {

  constructor(readonly elementRef: FormElementRef,
              readonly element: FormElement,
              readonly sectionRefId: FormSectionRefId,
              readonly sectionId: FormSectionId,
              readonly contextObjectId: Option<ObjectId>,
              readonly variableWithType: VariableWithType, public elementState: FormFieldState,
              public gridShiftY: number, readonly invalid: boolean) {}

  entriesListNotYetInitialized() {
    if(this.element.className() === Checkbox.className ||
      this.element.className() === RadioButton.className ||
      this.element.className() === DropList.className) {
      return (<Checkbox>this.element).entriesExpression.isDefined() && this.elementState.entriesIsEmpty;
    } else {
      return false;
    }
  }
}

export class SimpleElementWithData implements SimpleElementWithDataInterface{
  constructor(readonly elementRef: FormElementRef,
              readonly element: FormElement,
              readonly sectionId: FormSectionId,
              readonly contextObjectId: Option<ObjectId>,
              readonly gridShiftY: number,
              public elementState: FormFieldState) {}
}

export class StaticElementWithData implements SimpleElementWithDataInterface{
  constructor(readonly elementRef: StaticElementRef,
              readonly element: FormElement,
              readonly sectionId: FormSectionId,
              public elementState: FormFieldState,
              readonly gridShiftY: number,
              readonly contextObjectId: Option<ObjectId>) {}
}

export class VariableWithType {
  private _variable: Option<BusinessVariable>;
  private variableSubject = new Subject<Option<BusinessVariable>>();
  constructor(readonly path: VariablePath,
              variable: Option<BusinessVariable>,
              readonly variableType: BusinessVariableType) {
    this._variable = variable;
  }

  get variable(): Option<BusinessVariable> {
    return this._variable;
  }
  set variable(variable: Option<BusinessVariable>) {
    this._variable = variable;
    this.variableSubject.next(variable);
  }
  getVariableObservable() {
    return this.variableSubject.asObservable();
  }

  variableIsEmpty() {
    return this.variable.isEmpty() || this.variable.get().isEmpty();
  }

  getNonEmptyVariable(): Option<BusinessVariable> {
    if (this.variableIsEmpty()) {
      return None();
    } else {
      return this.variable;
    }
  }
}


export class AttachmentsUrlViewModel {

  constructor(public flowId: AnyFlowId, public nodeId: ProcessNodeId,
              readonly cursorVersion: Option<number>, readonly isFlowPreview: boolean,
              readonly isOperatorView: boolean, readonly isSupervisorView: boolean) {}

  getAttachmentUrl(fileUri: FileUri): string {
    if (this.isFlowPreview) {
      return documentRepositoryUri(fileUri);
    } else if (this.isOperatorView) {
      return taskFlowAttachmentDownloadUrl(fileUri);
    } else if (this.isSupervisorView) {
      return supervisorAttachmentDownloadUrl(this.flowId, this.nodeId, this.cursorVersion.get(), fileUri);
    } else {
      return "";
    }
  }

}
