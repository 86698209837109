import {Component, Input, ViewContainerRef} from "@angular/core";
import {TaskViewModel} from "./TaskViewModel";
import {ResponsiveWidthMonitor} from "@utils";

@Component({
  selector: "my-task-action-buttons",
  templateUrl: "./task-action-buttons.component.html",
  host: {
    "[class.mobile]": "mobile",
  }
})

export class TaskActionButtonsComponent {
  @Input({required:true}) viewModel!: TaskViewModel;

  @Input() mobile: boolean = false;

  @Input() hideAlternativeButtons: boolean = false;


  constructor(readonly viewContainerRef: ViewContainerRef) {}
}
