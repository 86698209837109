import {StaticElement, StaticElementRef} from "../FormModel";
import {FormElementId, FormElementRefId, GridSize, GridXY, I18nText, Option, Trilean} from "@utils";
import {ExpressionWithAst} from "@shared-model";
import {AutomaticActionRef} from "@screen-common";

export class ActionButton implements StaticElement {
  static className = "ActionButton";
  static defaultLabel = "Button";

  cssClassName(): string {
    return "actionButtonField";
  }



  className() {
    return ActionButton.className;
  }

  isInputElement(): boolean {
    return false;
  }

  constructor(public id: FormElementId,
              public tooltip: I18nText,
              public label: I18nText,
              public actions: Array<AutomaticActionRef>,
              public hiddenExpression: Option<ExpressionWithAst>,
              public readOnlyExpression: Option<ExpressionWithAst>) {}

  static copy(other: ActionButton): ActionButton {
    return new ActionButton(other.id,
      I18nText.copy(other.tooltip),
      I18nText.copy(other.label),
      other.actions.map(AutomaticActionRef.copy),
      Option.copy(other.hiddenExpression).map(ExpressionWithAst.copy),
      Option.copy(other.readOnlyExpression).map(ExpressionWithAst.copy),
    );
  }
}


export class ActionButtonRef implements StaticElementRef {

  static className = "ActionButtonRef";

  className(): string {
    return ActionButtonRef.className;
  }

  isInputElementRef() {
    return false;
  }

  constructor(public id: FormElementRefId,
              public elementId: FormElementId,
              public gridXY: GridXY,
              public gridSize: GridSize,
              public readOnly: Trilean,
              public hidden: Trilean) {}

  static copy(other: ActionButtonRef): ActionButtonRef {
    return new ActionButtonRef(other.id, other.elementId, GridXY.copy(other.gridXY), GridSize.copy(other.gridSize),
      Trilean.copy(other.readOnly), Trilean.copy(other.hidden));
  }
}


