import {FormElementId, FormElementRefId, GridSize, GridXY, I18nText, None, Option, Trilean, Typed} from "@utils";
import {
  BusinessVariablesValidation,
  BusinessVariableType,
  BusinessVariableValidationFactory,
  DateTimeVariableType,
  DateVariableType,
  ExpressionWithAst,
  TimeVariableType,
  VariableTypePath
} from "@shared-model";
import {InputElement, InputElementRef} from "../FormModel";
import {LabelPosition} from "../FormField";

export class DateField implements InputElement {
  static className = "DateField";

  defaultVariable(variableType: BusinessVariableType) {
    switch (variableType.className()) {
      case DateVariableType.className:
        return None();
      case DateTimeVariableType.className:
        return None();
      case TimeVariableType.className:
        return None();
      default:
        throw new Error("Variable type incompatible with date field: " + variableType.className())
    }
  }

  cssClassName(): string {
    return "dateField";
  }

  className() {
    return DateField.className;
  }

  isInputElement(): boolean {
    return true;
  }

  constructor(public id: FormElementId,
              public tooltip: I18nText,
              public variableTypePath: VariableTypePath,
              public validation: Option<Typed<BusinessVariablesValidation>>,
              public hiddenExpression: Option<ExpressionWithAst>,
              public requiredExpression: Option<ExpressionWithAst>,
              public readOnlyExpression: Option<ExpressionWithAst>) {}

  static copy(other: DateField): DateField {
    return new DateField(other.id, I18nText.copy(other.tooltip),
      VariableTypePath.copy(other.variableTypePath),
      Option.copy(other.validation).map(BusinessVariableValidationFactory.copyTyped),
      Option.copy(other.hiddenExpression).map(ExpressionWithAst.copy),
      Option.copy(other.requiredExpression).map(ExpressionWithAst.copy),
      Option.copy(other.readOnlyExpression).map(ExpressionWithAst.copy));
  }
}

export class DateFieldRef implements InputElementRef {
  static className = "DateFieldRef";

  className(): string {
    return DateFieldRef.className;
  }

  isInputElementRef() {
    return true;
  }

  constructor(public id: FormElementRefId,
              public elementId: FormElementId,
              public gridXY: GridXY,
              public gridSize: GridSize,
              public label: I18nText,
              public labelPosition: LabelPosition,
              public labelGridShift: GridXY,
              public required: Trilean,
              public readOnly: Trilean,
              public hidden: Trilean,
              public visibleInSummary: boolean,
              public visibleInTaskBox: boolean) {}

  static copy(other: DateFieldRef): DateFieldRef {
    return new DateFieldRef(other.id, other.elementId, GridXY.copy(other.gridXY), GridSize.copy(other.gridSize),
      I18nText.copy(other.label), LabelPosition.copy(other.labelPosition), GridXY.copy(other.labelGridShift),
      Trilean.copy(other.required), Trilean.copy(other.readOnly), Trilean.copy(other.hidden), other.visibleInSummary, other.visibleInTaskBox);
  }
}
