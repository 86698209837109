<button #DropDownSelector class="dropDownSelector"
        [class.minimal]="minimal"
        [class.withIcon]="withIcon"
        [class.readOnly]="disabled" (click)="toggleDropDownMenuVisible()" [disabled]="disabled">

  <ng-container *ngIf="selectedOption?.icon">
    <i [class]="selectedOption?.icon"></i>
  </ng-container>
  <ng-content select="[prefix]"></ng-content>

  <ng-container *ngIf="!iconOnly">

    <div class="selectedValue empty" *ngIf="selectedOption === undefined">{{placeholder}}</div>
    <div class="selectedValue" *ngIf="selectedOption !== undefined">{{selectedOption.name}}</div>

    <ng-container *ngIf="!disabled && !minimal">
      <i class="toggleSelector mi-navigate-down theme-expand-arrow" [class.theme-expanded]="dropDownMenuVisible"></i>
    </ng-container>

    <ng-container *ngIf="!disabled && minimal">
      <i class="toggleSelector mi-caret-down" [class.theme-expanded]="dropDownMenuVisible"></i>
    </ng-container>

  </ng-container>

</button>

<my-drop-menu cssClass="dropDownSelectorMenu theme-drop-menu" [(visible)]="dropDownMenuVisible" [anchor]="DropDownSelector">

  <div class="theme-selector-drop-menu" *ngIf="dropDownMenuVisible">

    <ng-container *ngIf="searchable">

      <div class="theme-drop-menu-filter">
        <i class="mi-search"></i>
        <input type="text" placeholder="{{searchPlaceholder ? searchPlaceholder : 'common_search' | i18n}}"
               [(ngModel)]="searchValue" (ngModelChange)="handleSearch()" [focus-on-show]="true" >
        <button class="theme-clear-filter" (click)="clearSearch()" *ngIf="searchMode"><i class="mi-close"></i></button>
      </div>

      <hr class="theme-drop-menu-separator" />

    </ng-container>

    <my-scroll contentClass="dropDownSelectorOptions" [adjustHeight]="true" [adjustWidth]="true" [contentSizeChanged]="optionsContentSizeChanged" *ngIf="dropDownMenuOptions.length > 0">

      <button class="theme-drop-menu-entry" *ngIf="allowUnset" (click)="setValue(undefined)">-</button>

      <button class="theme-drop-menu-entry" [class.selected]="selectedOption && selectedOption.value === option.value" *ngFor="let option of dropDownMenuOptions; trackBy: trackByIndex" (click)="setValue(option)">
        <span class="optionName">
          <i *ngIf="option.icon" [class]="option.icon"></i>
          <ng-container *ngIf="option.nameOnList">
            {{option.nameOnList}}
          </ng-container>
          <ng-container *ngIf="!option.nameOnList">
            {{option.name}}
          </ng-container>
        </span>
        <button class="theme-selector">
          <i class="mi-check"></i>
        </button>

      </button>

    </my-scroll>

    <div class="theme-drop-menu-entry theme-disabled" *ngIf="dropDownMenuOptions.length === 0">
      <ng-container *ngIf="!emptyPlaceholder">
        {{'common_search_noResult' | i18n}}
      </ng-container>
      <ng-container *ngIf="emptyPlaceholder">
        {{emptyPlaceholder}}
      </ng-container>
    </div>

  </div>

</my-drop-menu>
