import {
  AggregateId,
  AnyPersonId,
  AnyPersonIdFactory,
  FileUri,
  FormElementId, LocalDateTime, None,
  ObjectId,
  Option,
  OrganizationNodeId,
  PersonId, RemotePersonId, Some,
  Typed
} from "@utils";
import {
  BooleanVariable,
  BusinessVariable,
  BusinessVariableFactory,
  ContextPath,
  ContextVariable,
  CursorPosition, FlowCursorId,
  ObjectVariable,
  ParentFlowCursor,
  PhaseWithStep, ProcessEdgeId, ProcessNodeId,
  RootVariable,
  StatusWithMessage
} from "@shared-model";
import {FlowLogEntry, FlowLogEntryFactory,} from "./ProcessFlow";
import {FormSectionId} from "@shared";

export class ProcessFlowCreatedV2 {
    static className = "ProcessFlowCreatedV2";
    className() {
      return ProcessFlowCreatedV2.className;
    }

    constructor(readonly processInstanceId: AggregateId,
                readonly processReleaseId: AggregateId,
                readonly processId: AggregateId,
                readonly organizationId: AggregateId,
                readonly parentsFlowsIds: Array<AggregateId>,
                readonly parentFlowCursor: Option<ParentFlowCursor>,
                readonly initialPersonId: Option<Typed<AnyPersonId>>,
                readonly startNodeId: ProcessNodeId,
                readonly _startRoleId: Option<number>,
                readonly startVariableData: Array<RootVariable<BusinessVariable>>,
                readonly processName: string,
                readonly nextCursorPhase: PhaseWithStep,
                readonly flowCodeOption: Option<string>,
                readonly materializedEventsCount: number) {}

    static copy(other: ProcessFlowCreatedV2) {
      return new ProcessFlowCreatedV2(other.processInstanceId, other.processReleaseId, other.processId,
        other.organizationId, other.parentsFlowsIds.slice(), Option.copy(other.parentFlowCursor),
        Option.copy(other.initialPersonId).map(AnyPersonIdFactory.copyTyped), other.startNodeId, Option.copy(other._startRoleId),
        other.startVariableData.map(v => RootVariable.copy(v)), other.processName, other.nextCursorPhase, Option.copy(other.flowCodeOption), other.materializedEventsCount)
    }

  }

  export class ProcessFlowTerminatedV1 {
    static className = "ProcessFlowTerminatedV1";
    className() {
      return ProcessFlowTerminatedV1.className;
    }

    constructor(readonly personId:PersonId,
                readonly comment:string) {}

    static copy(other: ProcessFlowTerminatedV1) {
      return new ProcessFlowTerminatedV1(other.personId, other.comment);
    }
  }

  export class PersonAssignedToRoleV4 {
    static className = "PersonAssignedToRoleV4";
    className() {
      return PersonAssignedToRoleV4.className;
    }

    constructor(readonly personId: number,
                readonly roleId: number,
                readonly _nodeIds: Array<number>) {}

    static copy(other: PersonAssignedToRoleV4):PersonAssignedToRoleV4 {
      return new PersonAssignedToRoleV4(other.personId, other.roleId, other._nodeIds.slice());
    }
  }

  export class LocalPersonAssignedToRole {
    static className = "LocalPersonAssignedToRole";
    className() {
      return LocalPersonAssignedToRole.className;
    }
    constructor(readonly person: string,
                readonly roleId: number) {}
    static copy(other: LocalPersonAssignedToRole):LocalPersonAssignedToRole {
      return new LocalPersonAssignedToRole(
        other.person,
        other.roleId
      );
    }
  }

  export class RemotePersonAssignedToRole {
    static className = "RemotePersonAssignedToRole";
    className() {
      return RemotePersonAssignedToRole.className;
    }
    constructor(readonly person: RemotePersonId,
                readonly roleId: number) {}
    static copy(other: RemotePersonAssignedToRole):RemotePersonAssignedToRole {
      return new RemotePersonAssignedToRole(
        RemotePersonId.copy(other.person),
        other.roleId
      );
    }
  }

  export class PersonUnAssignedFromRoleV4 {
    static className = "PersonUnAssignedFromRoleV4";
    className() {
      return PersonUnAssignedFromRoleV4.className;
    }

    constructor(readonly person: Typed<AnyPersonId>,
                readonly roleId: number) {}

    static copy(other: PersonUnAssignedFromRoleV4):PersonUnAssignedFromRoleV4 {
      return new PersonUnAssignedFromRoleV4(AnyPersonIdFactory.copyTyped(other.person), other.roleId);
    }
  }

  export class LocalPersonUnAssignedFromRole {
    static className = "LocalPersonUnAssignedFromRole";
    className() {
      return LocalPersonUnAssignedFromRole.className;
    }
    constructor(readonly person: string,
                readonly roleId: number) {}
    static copy(other: LocalPersonUnAssignedFromRole):LocalPersonUnAssignedFromRole {
      return new LocalPersonUnAssignedFromRole(
        other.person,
        other.roleId
      );
    }
  }

  export class RemotePersonUnAssignedFromRole {
    static className = "RemotePersonUnAssignedFromRole";
    className() {
      return RemotePersonUnAssignedFromRole.className;
    }
    constructor(readonly person: RemotePersonId,
                readonly roleId: number) {}
    static copy(other: RemotePersonUnAssignedFromRole):RemotePersonUnAssignedFromRole {
      return new RemotePersonUnAssignedFromRole(
        RemotePersonId.copy(other.person),
        other.roleId
      );
    }
  }


  export class PersonSelfAssignedToRoleV1 {
    static className = "PersonSelfAssignedToRoleV1";
    className() {
      return PersonSelfAssignedToRoleV1.className;
    }
    constructor(readonly person: Typed<AnyPersonId>,
                readonly roleId: number) {}
    static copy(other: PersonSelfAssignedToRoleV1):PersonSelfAssignedToRoleV1 {
      return new PersonSelfAssignedToRoleV1(AnyPersonIdFactory.copyTyped(other.person), other.roleId);
    }
  }

  export class PersonSelfUnAssignedFromRoleV1 {
    static className = "PersonSelfUnAssignedFromRoleV1";
    className() {
      return PersonSelfUnAssignedFromRoleV1.className;
    }
    constructor(readonly person: Typed<AnyPersonId>,
                readonly roleId: number) {}
    static copy(other: PersonSelfUnAssignedFromRoleV1):PersonSelfUnAssignedFromRoleV1 {
      return new PersonSelfUnAssignedFromRoleV1(AnyPersonIdFactory.copyTyped(other.person), other.roleId);
    }
  }

  export class LocalPersonSelfUnAssignedFromRole {
    static className = "LocalPersonSelfUnAssignedFromRole";
    className() {
      return LocalPersonSelfUnAssignedFromRole.className;
    }
    constructor(readonly person: string,
                readonly roleId: number) {}
    static copy(other: LocalPersonSelfUnAssignedFromRole):LocalPersonSelfUnAssignedFromRole {
      return new LocalPersonSelfUnAssignedFromRole(
        other.person,
        other.roleId
      );
    }
  }

  export class RemotePersonSelfUnAssignedFromRole {
    static className = "RemotePersonSelfUnAssignedFromRole";
    className() {
      return RemotePersonSelfUnAssignedFromRole.className;
    }
    constructor(readonly person: RemotePersonId,
                readonly roleId: number) {}
    static copy(other: RemotePersonSelfUnAssignedFromRole):RemotePersonSelfUnAssignedFromRole {
      return new RemotePersonSelfUnAssignedFromRole(
        RemotePersonId.copy(other.person),
        other.roleId
      );
    }
  }

  export class TaskMarkedWaiting {
    static className = "TaskMarkedWaiting";
    className() {
      return TaskMarkedWaiting.className;
    }

    constructor(readonly cursorId: FlowCursorId) {}

    static copy(other: TaskMarkedWaiting): TaskMarkedWaiting {
      return new TaskMarkedWaiting(other.cursorId)
    }
  }

  export class TaskStarted {
    static className = "TaskStarted";
    className() {
      return TaskStarted.className;
    }

    constructor(readonly cursorId: FlowCursorId) {}

    static copy(other: TaskStarted): TaskStarted {
      return new TaskStarted(other.cursorId)
    }
  }

  export class TaskStopped {
    static className = "TaskStopped";
    className() {
      return TaskStopped.className;
    }

    constructor(readonly cursorId: FlowCursorId) {}

    static copy(other: TaskStopped): TaskStopped {
      return new TaskStopped(other.cursorId)
    }
  }

  export class FlowCancelled {
    static className = "FlowCancelled";
    className() {
      return FlowCancelled.className;
    }

    static copy(other: FlowCancelled): FlowCancelled {
      return new FlowCancelled();
    }
}

  export class ActivityCheckedV1 {
    static className = "ActivityCheckedV1";
    className() {
      return ActivityCheckedV1.className;
    }

    constructor(readonly nodeId: ProcessNodeId,
                readonly activityId: number) {}

    static copy(other: ActivityCheckedV1):ActivityCheckedV1 {
      return new ActivityCheckedV1(other.nodeId, other.activityId);
    }

  }

  export class ActivityUncheckedV1 {
    static className = "ActivityUncheckedV1";
    className() {
      return ActivityUncheckedV1.className;
    }

    constructor(readonly nodeId: ProcessNodeId,
                readonly activityId: number) {}

    static copy(other: ActivityUncheckedV1):ActivityUncheckedV1 {
      return new ActivityUncheckedV1(other.nodeId, other.activityId);
    }

  }

  export class ProcessMaterialized {
    static className = "ProcessMaterialized";
    className() {
      return ProcessMaterialized.className;
    }

    static copy(other: ProcessMaterialized):ProcessMaterialized {
      return new ProcessMaterialized();
    }
  }

  export class VariablesFilledV2 {
    static className = "VariablesFilledV2";
    className() {
      return VariablesFilledV2.className;
    }

    constructor(readonly variablesData: ContextVariable<BusinessVariable>[],
                readonly clearedVariables: Array<ContextPath>,
                readonly variablesToEvaluate: Array<ContextPath>) {}

    static copy(other: VariablesFilledV2):VariablesFilledV2 {
      return new VariablesFilledV2(other.variablesData.map(ContextVariable.copy),
                                 other.clearedVariables.map(ContextPath.copy),
                                 other.variablesToEvaluate.map(ContextPath.copy));
    }

  }

  export class VariablesFilledByOtherFlow {
    static className = "VariablesFilledByOtherFlow";
    className() {
      return VariablesFilledByOtherFlow.className;
    }

    constructor(readonly variablesData: ContextVariable<BusinessVariable>[],
                readonly clearedVariables: Array<ContextPath>,
                readonly variablesToEvaluate: Array<ContextPath>,
                readonly flowId: AggregateId) {}

    static copy(other: VariablesFilledByOtherFlow):VariablesFilledV2 {
      return new VariablesFilledByOtherFlow(other.variablesData.map(ContextVariable.copy),
        other.clearedVariables.map(ContextPath.copy),
        other.variablesToEvaluate.map(ContextPath.copy),
        AggregateId.copy(other.flowId));
    }

  }


  export class FormFieldPropertyChangedV1 {
    static className = "FormFieldPropertyChangedV1";
    className() {
      return FormFieldPropertyChangedV1.className;
    }

    constructor(
      readonly contextObjectId: Option<ObjectId>,
      readonly elementId: FormElementId,
      readonly propertyName: string,
      readonly value: Typed<BusinessVariable>) {}

    static copy(other: FormFieldPropertyChangedV1) {
      return new FormFieldPropertyChangedV1(Option.copy(other.contextObjectId), other.elementId, other.propertyName, BusinessVariableFactory.copyTyped(other.value));
    }

    static noContext(elementId: number, propertyName: string, value: BusinessVariable): FormFieldPropertyChangedV1 {
      return new FormFieldPropertyChangedV1(None(), new FormElementId(elementId), propertyName, Typed.of(value));
    }

    static withContext(contextId: string, elementId: number, propertyName: string, value: BusinessVariable): FormFieldPropertyChangedV1 {
      return new FormFieldPropertyChangedV1(Some(new ObjectId(contextId)), new FormElementId(elementId), propertyName, Typed.of(value));
    }
  }

export interface FormFieldPropertyChangedV1Derivative {
  toFormFieldPropertyChangedV1(): FormFieldPropertyChangedV1;
}

export class FormFieldInitializedPropertyChangedToTrueNoContext implements FormFieldPropertyChangedV1Derivative {
  static className = "FormFieldInitializedPropertyChangedToTrueNoContext";
  className() {
    return FormFieldInitializedPropertyChangedToTrueNoContext.className;
  }
  constructor (readonly elementId: number) {}
  static copy(other: FormFieldInitializedPropertyChangedToTrueNoContext) {
    return new FormFieldInitializedPropertyChangedToTrueNoContext(other.elementId);
  }
  toFormFieldPropertyChangedV1(): FormFieldPropertyChangedV1 {
    return FormFieldPropertyChangedV1.noContext(this.elementId, "initialized", BooleanVariable.TRUE());
  }
}
export class FormFieldInitializedPropertyChangedToFalseNoContext implements FormFieldPropertyChangedV1Derivative {
  static className = "FormFieldInitializedPropertyChangedToFalseNoContext";
  className() {
    return FormFieldInitializedPropertyChangedToFalseNoContext.className;
  }
  constructor (readonly elementId: number) {}
  static copy(other: FormFieldInitializedPropertyChangedToFalseNoContext) {
    return new FormFieldInitializedPropertyChangedToFalseNoContext(other.elementId);
  }
  toFormFieldPropertyChangedV1(): FormFieldPropertyChangedV1 {
    return FormFieldPropertyChangedV1.noContext(this.elementId, "initialized", BooleanVariable.FALSE());
  }
}
export class FormFieldInitializedPropertyChangedToTrueInContext implements FormFieldPropertyChangedV1Derivative {
  static className = "FormFieldInitializedPropertyChangedToTrueInContext";
  className() {
    return FormFieldInitializedPropertyChangedToTrueInContext.className;
  }
  constructor (readonly contextId: string, readonly elementId: number) {}
  static copy(other: FormFieldInitializedPropertyChangedToTrueInContext) {
    return new FormFieldInitializedPropertyChangedToTrueInContext(other.contextId, other.elementId);
  }
  toFormFieldPropertyChangedV1(): FormFieldPropertyChangedV1 {
    return FormFieldPropertyChangedV1.withContext(this.contextId, this.elementId, "initialized", BooleanVariable.TRUE());
  }
}
export class FormFieldInitializedPropertyChangedToFalseInContext implements FormFieldPropertyChangedV1Derivative {
  static className = "FormFieldInitializedPropertyChangedToFalseInContext";
  className() {
    return FormFieldInitializedPropertyChangedToFalseInContext.className;
  }
  constructor (readonly contextId: string, readonly elementId: number) {}
  static copy(other: FormFieldInitializedPropertyChangedToFalseInContext) {
    return new FormFieldInitializedPropertyChangedToFalseInContext(other.contextId, other.elementId);
  }
  toFormFieldPropertyChangedV1(): FormFieldPropertyChangedV1 {
    return FormFieldPropertyChangedV1.withContext(this.contextId, this.elementId, "initialized", BooleanVariable.FALSE());
  }
}

export class FormFieldReadOnlyPropertyChangedToTrueNoContext implements FormFieldPropertyChangedV1Derivative {
  static className = "FormFieldReadOnlyPropertyChangedToTrueNoContext";
  className() {
    return FormFieldReadOnlyPropertyChangedToTrueNoContext.className;
  }
  constructor (readonly elementId: number) {}
  static copy(other: FormFieldReadOnlyPropertyChangedToTrueNoContext) {
    return new FormFieldReadOnlyPropertyChangedToTrueNoContext(other.elementId);
  }
  toFormFieldPropertyChangedV1(): FormFieldPropertyChangedV1 {
    return FormFieldPropertyChangedV1.noContext(this.elementId, "readOnly", BooleanVariable.TRUE());
  }
}
export class FormFieldReadOnlyPropertyChangedToFalseNoContext implements FormFieldPropertyChangedV1Derivative {
  static className = "FormFieldReadOnlyPropertyChangedToFalseNoContext";
  className() {
    return FormFieldReadOnlyPropertyChangedToFalseNoContext.className;
  }
  constructor (readonly elementId: number) {}
  static copy(other: FormFieldReadOnlyPropertyChangedToFalseNoContext) {
    return new FormFieldReadOnlyPropertyChangedToFalseNoContext(other.elementId);
  }
  toFormFieldPropertyChangedV1(): FormFieldPropertyChangedV1 {
    return FormFieldPropertyChangedV1.noContext(this.elementId, "readOnly", BooleanVariable.FALSE());
  }
}
export class FormFieldReadOnlyPropertyChangedToTrueInContext implements FormFieldPropertyChangedV1Derivative {
  static className = "FormFieldReadOnlyPropertyChangedToTrueInContext";
  className() {
    return FormFieldReadOnlyPropertyChangedToTrueInContext.className;
  }
  constructor (readonly contextId: string, readonly elementId: number) {}
  static copy(other: FormFieldReadOnlyPropertyChangedToTrueInContext) {
    return new FormFieldReadOnlyPropertyChangedToTrueInContext(other.contextId, other.elementId);
  }
  toFormFieldPropertyChangedV1(): FormFieldPropertyChangedV1 {
    return FormFieldPropertyChangedV1.withContext(this.contextId, this.elementId, "readOnly", BooleanVariable.TRUE());
  }
}
export class FormFieldReadOnlyPropertyChangedToFalseInContext implements FormFieldPropertyChangedV1Derivative {
  static className = "FormFieldReadOnlyPropertyChangedToFalseInContext";
  className() {
    return FormFieldReadOnlyPropertyChangedToFalseInContext.className;
  }
  constructor (readonly contextId: string, readonly elementId: number) {}
  static copy(other: FormFieldReadOnlyPropertyChangedToFalseInContext) {
    return new FormFieldReadOnlyPropertyChangedToFalseInContext(other.contextId, other.elementId);
  }
  toFormFieldPropertyChangedV1(): FormFieldPropertyChangedV1 {
    return FormFieldPropertyChangedV1.withContext(this.contextId, this.elementId, "readOnly", BooleanVariable.FALSE());
  }
}

export class FormFieldHiddenPropertyChangedToTrueNoContext implements FormFieldPropertyChangedV1Derivative {
  static className = "FormFieldHiddenPropertyChangedToTrueNoContext";
  className() {
    return FormFieldHiddenPropertyChangedToTrueNoContext.className;
  }
  constructor (readonly elementId: number) {}
  static copy(other: FormFieldHiddenPropertyChangedToTrueNoContext) {
    return new FormFieldHiddenPropertyChangedToTrueNoContext(other.elementId);
  }
  toFormFieldPropertyChangedV1(): FormFieldPropertyChangedV1 {
    return FormFieldPropertyChangedV1.noContext(this.elementId, "hidden", BooleanVariable.TRUE());
  }
}
export class FormFieldHiddenPropertyChangedToFalseNoContext implements FormFieldPropertyChangedV1Derivative {
  static className = "FormFieldHiddenPropertyChangedToFalseNoContext";
  className() {
    return FormFieldHiddenPropertyChangedToFalseNoContext.className;
  }
  constructor (readonly elementId: number) {}
  static copy(other: FormFieldHiddenPropertyChangedToFalseNoContext) {
    return new FormFieldHiddenPropertyChangedToFalseNoContext(other.elementId);
  }
  toFormFieldPropertyChangedV1(): FormFieldPropertyChangedV1 {
    return FormFieldPropertyChangedV1.noContext(this.elementId, "hidden", BooleanVariable.FALSE());
  }
}
export class FormFieldHiddenPropertyChangedToTrueInContext implements FormFieldPropertyChangedV1Derivative {
  static className = "FormFieldHiddenPropertyChangedToTrueInContext";
  className() {
    return FormFieldHiddenPropertyChangedToTrueInContext.className;
  }
  constructor (readonly contextId: string, readonly elementId: number) {}
  static copy(other: FormFieldHiddenPropertyChangedToTrueInContext) {
    return new FormFieldHiddenPropertyChangedToTrueInContext(other.contextId, other.elementId);
  }
  toFormFieldPropertyChangedV1(): FormFieldPropertyChangedV1 {
    return FormFieldPropertyChangedV1.withContext(this.contextId, this.elementId, "hidden", BooleanVariable.TRUE());
  }
}
export class FormFieldHiddenPropertyChangedToFalseInContext implements FormFieldPropertyChangedV1Derivative {
  static className = "FormFieldHiddenPropertyChangedToFalseInContext";
  className() {
    return FormFieldHiddenPropertyChangedToFalseInContext.className;
  }
  constructor (readonly contextId: string, readonly elementId: number) {}
  static copy(other: FormFieldHiddenPropertyChangedToFalseInContext) {
    return new FormFieldHiddenPropertyChangedToFalseInContext(other.contextId, other.elementId);
  }
  toFormFieldPropertyChangedV1(): FormFieldPropertyChangedV1 {
    return FormFieldPropertyChangedV1.withContext(this.contextId, this.elementId, "hidden", BooleanVariable.FALSE());
  }
}

export class FormFieldRequiredPropertyChangedToTrueNoContext implements FormFieldPropertyChangedV1Derivative {
  static className = "FormFieldRequiredPropertyChangedToTrueNoContext";
  className() {
    return FormFieldRequiredPropertyChangedToTrueNoContext.className;
  }
  constructor (readonly elementId: number) {}
  static copy(other: FormFieldRequiredPropertyChangedToTrueNoContext) {
    return new FormFieldRequiredPropertyChangedToTrueNoContext(other.elementId);
  }
  toFormFieldPropertyChangedV1(): FormFieldPropertyChangedV1 {
    return FormFieldPropertyChangedV1.noContext(this.elementId, "required", BooleanVariable.TRUE());
  }
}
export class FormFieldRequiredPropertyChangedToFalseNoContext implements FormFieldPropertyChangedV1Derivative {
  static className = "FormFieldRequiredPropertyChangedToFalseNoContext";
  className() {
    return FormFieldRequiredPropertyChangedToFalseNoContext.className;
  }
  constructor (readonly elementId: number) {}
  static copy(other: FormFieldRequiredPropertyChangedToFalseNoContext) {
    return new FormFieldRequiredPropertyChangedToFalseNoContext(other.elementId);
  }
  toFormFieldPropertyChangedV1(): FormFieldPropertyChangedV1 {
    return FormFieldPropertyChangedV1.noContext(this.elementId, "required", BooleanVariable.FALSE());
  }
}
export class FormFieldRequiredPropertyChangedToTrueInContext implements FormFieldPropertyChangedV1Derivative {
  static className = "FormFieldRequiredPropertyChangedToTrueInContext";
  className() {
    return FormFieldRequiredPropertyChangedToTrueInContext.className;
  }
  constructor (readonly contextId: string, readonly elementId: number) {}
  static copy(other: FormFieldRequiredPropertyChangedToTrueInContext) {
    return new FormFieldRequiredPropertyChangedToTrueInContext(other.contextId, other.elementId);
  }
  toFormFieldPropertyChangedV1(): FormFieldPropertyChangedV1 {
    return FormFieldPropertyChangedV1.withContext(this.contextId, this.elementId, "required", BooleanVariable.TRUE());
  }
}
export class FormFieldRequiredPropertyChangedToFalseInContext implements FormFieldPropertyChangedV1Derivative {
  static className = "FormFieldRequiredPropertyChangedToFalseInContext";
  className() {
    return FormFieldRequiredPropertyChangedToFalseInContext.className;
  }
  constructor (readonly contextId: string, readonly elementId: number) {}
  static copy(other: FormFieldRequiredPropertyChangedToFalseInContext) {
    return new FormFieldRequiredPropertyChangedToFalseInContext(other.contextId, other.elementId);
  }
  toFormFieldPropertyChangedV1(): FormFieldPropertyChangedV1 {
    return FormFieldPropertyChangedV1.withContext(this.contextId, this.elementId, "required", BooleanVariable.FALSE());
  }
}

  export class FormFieldPropertyClearedV1 {
    static className = "FormFieldPropertyClearedV1";
    className() {
      return FormFieldPropertyClearedV1.className;
    }

    constructor(
      readonly contextObjectId: Option<ObjectId>,
      readonly elementId: FormElementId,
      readonly propertyName: string) {}

    static copy(other: FormFieldPropertyClearedV1) {
      return new FormFieldPropertyClearedV1(Option.copy(other.contextObjectId), other.elementId, other.propertyName);
    }
  }

  export class AutoVariablesFilledV4 {
    static className = "AutoVariablesFilledV4";
    className() {
      return AutoVariablesFilledV4.className;
    }

    constructor(readonly variablesData: ContextVariable<BusinessVariable>[],
                readonly clearedVariables: Array<ContextPath>,
                readonly variablesEvaluated: Array<ContextPath>) {}

    static copy(other: AutoVariablesFilledV4): AutoVariablesFilledV4 {
      return new AutoVariablesFilledV4(other.variablesData.map(ContextVariable.copy),
        other.clearedVariables.map(ContextPath.copy),
        other.variablesEvaluated.map(ContextPath.copy));
    }

  }

  export class DescriptionUpdated {
    static className = "DescriptionUpdated";
    className() {
      return DescriptionUpdated.className;
    }
    constructor(readonly description: Option<string>) {}

    static copy(other: DescriptionUpdated) {
      return new DescriptionUpdated(Option.copy(other.description));
    }
  }

  export class DescriptionUpdatedV1 {
    static className = "DescriptionUpdatedV1";
    className() {
      return DescriptionUpdatedV1.className;
    }
    constructor(readonly description: string) {}
    static copy(other: DescriptionUpdatedV1):DescriptionUpdatedV1 {
      return new DescriptionUpdatedV1(
        other.description
      );
    }
  }

  export class DescriptionCleared {
    static className = "DescriptionCleared";
    className() {
      return DescriptionCleared.className;
    }
    static copy(other: DescriptionCleared):DescriptionCleared {
      return new DescriptionCleared();
    }
  }

  export class SectionVisibilityChanged {
    static className = "SectionVisibilityChanged";
    className() {
      return SectionVisibilityChanged.className;
    }

    constructor(readonly shownSections: Array<FormSectionId>,
                readonly hiddenSections: Array<FormSectionId>) {}
    static copy(other: SectionVisibilityChanged) {
      return new SectionVisibilityChanged(other.shownSections.slice(), other.hiddenSections.slice());
    }
  }

  export class DeadlinesUpdatedV2 {
    static className = "DeadlinesUpdatedV2";
    className() {
      return DeadlinesUpdatedV2.className;
    }

    constructor() {}
    static copy(other: DeadlinesUpdatedV2) {
      return new DeadlinesUpdatedV2();
    }
  }

  export class SectionLengthUpdatedV3 {
    static className = "SectionLengthUpdatedV3";
    className() {
      return SectionLengthUpdatedV3.className;
    }

    constructor(readonly sectionVariableName:string,
                readonly removedRows:Array<ObjectId>,
                readonly addedRows:Array<ObjectVariable>
    ) {}

    static copy(other: SectionLengthUpdatedV3): SectionLengthUpdatedV3 {
      return new SectionLengthUpdatedV3(other.sectionVariableName, other.removedRows.slice(), other.addedRows.map(o => <ObjectVariable>BusinessVariableFactory.copyByType(o, ObjectVariable.className)));
    }
  }

  export class ActionSubmittedV3 {
    static className = "ActionSubmittedV3";
    className() {
      return ActionSubmittedV3.className;
    }

    static copy(other: ActionSubmittedV3):ActionSubmittedV3 {
      return new ActionSubmittedV3();
    }
  }

  export class ActionSubmittedBySubFlow {
    static className = "ActionSubmittedBySubFlow";
    className() {
      return ActionSubmittedBySubFlow.className;
    }

    static copy(other: ActionSubmittedBySubFlow):ActionSubmittedBySubFlow {
      return new ActionSubmittedBySubFlow();
    }
  }

  export class ActionSubmittedByManager {
    static className = "ActionSubmittedByManager";
    className() {
      return ActionSubmittedByManager.className;
    }

    static copy(other: ActionSubmittedByManager):ActionSubmittedByManager {
      return new ActionSubmittedByManager();
    }
  }



  export class SubFlowCreated {
    static className = "SubFlowCreated";
    className() {
      return SubFlowCreated.className;
    }

    static copy(other: SubFlowCreated):SubFlowCreated {
      return new SubFlowCreated();
    }
  }

  export class FlowCursorDeletedV1 {
    static className = "FlowCursorDeletedV1";
    className() {
      return FlowCursorDeletedV1.className;
    }

    constructor(readonly cursorId: FlowCursorId) {}

    static copy(other: FlowCursorDeletedV1): FlowCursorDeletedV1 {
      return new FlowCursorDeletedV1(other.cursorId );
    }
  }

  export class FlowCursorCompleted {
    static className = "FlowCursorCompleted";
    className() {
      return FlowCursorCompleted.className;
    }

    constructor(readonly cursorId: FlowCursorId,
                readonly _finishReached: Option<number>) {}

    static copy(other: FlowCursorCompleted): FlowCursorCompleted {
      return new FlowCursorCompleted(other.cursorId, Option.copy(other._finishReached));
    }
  }

  export class FlowCursorCompletedNoFinish {
    static className = "FlowCursorCompletedNoFinish";
    className() {
      return FlowCursorCompletedNoFinish.className;
    }
    constructor(readonly cursorId: FlowCursorId) {}
    static copy(other: FlowCursorCompletedNoFinish):FlowCursorCompletedNoFinish {
      return new FlowCursorCompletedNoFinish(
        other.cursorId
      );
    }
  }

  export class FlowCursorCompletedWithFinish {
    static className = "FlowCursorCompletedWithFinish";
    className() {
      return FlowCursorCompletedWithFinish.className;
    }
    constructor(readonly cursorId: FlowCursorId, readonly _finishReached: number) {}
    static copy(other: FlowCursorCompletedWithFinish):FlowCursorCompletedWithFinish {
      return new FlowCursorCompletedWithFinish(
        other.cursorId,
        other._finishReached
      );
    }
  }

  export class FlowCursorCreatedV1 {
    static className = "FlowCursorCreatedV1";
    className() {
      return FlowCursorCreatedV1.className;
    }

    constructor(readonly cursorId: FlowCursorId,
                readonly cursorPosition: CursorPosition,
                readonly currentActorId: Option<number>,
                readonly parentsCursorsId: number[],
                readonly phase: PhaseWithStep,
                readonly status: StatusWithMessage) {}

    static copy(other: FlowCursorCreatedV1): FlowCursorCreatedV1 {
      return new FlowCursorCreatedV1(other.cursorId, CursorPosition.copy(other.cursorPosition),
        Option.copy(other.currentActorId), other.parentsCursorsId.slice(), PhaseWithStep.copy(other.phase), other.status);
    }
  }

  export class ProcessFlowCompleted {
    static className = "ProcessFlowCompleted";
    className() {
      return ProcessFlowCompleted.className;
    }

    static copy(other: ProcessFlowCompleted):ProcessFlowCompleted {
      return new ProcessFlowCompleted();
    }
  }


export class ProcessFlowReopened {
  static className = "ProcessFlowReopened";
  className() {
    return ProcessFlowReopened.className;
  }

  static copy(other: ProcessFlowReopened):ProcessFlowReopened {
    return new ProcessFlowReopened();
  }
}


export class CommentAddedV3 {
    static className = "CommentAddedV3";
    className() {
      return CommentAddedV3.className;
    }

    constructor(readonly commentId: number,
                readonly person: Typed<AnyPersonId>,
                readonly nodeId: Option<number>,
                readonly commentText: string,
                readonly attachments: Array<FileUri>,
                readonly extraRecipients: Array<Typed<AnyPersonId>>) {}

    static copy(other: CommentAddedV3):CommentAddedV3 {
      return new CommentAddedV3(other.commentId, AnyPersonIdFactory.copyTyped(other.person), Option.copy(other.nodeId), other.commentText,
      other.attachments.map(FileUri.copy), other.extraRecipients.map(AnyPersonIdFactory.copyTyped));
    }

  }

  export class CommentChanged {
    static className = "CommentChanged";
    className() {
      return CommentChanged.className;
    }

    constructor(readonly commentId: number, readonly newCommentText: string) {}

    static copy(other: CommentChanged):CommentChanged {
      return new CommentChanged(other.commentId, other.newCommentText);
    }

  }

  export class CommentDeleted {
    static className = "CommentDeleted";
    className() {
      return CommentDeleted.className;
    }

    constructor(readonly commentId: number) {}

    static copy(other: CommentDeleted):CommentDeleted {
      return new CommentDeleted(other.commentId);
    }

  }

  export class LogAdded {
    static className = "LogAdded";
    className() {
      return LogAdded.className;
    }

    constructor(readonly log: Typed<FlowLogEntry>) {}

    static copy(other: LogAdded): LogAdded {
      return new LogAdded(FlowLogEntryFactory.copyTyped(other.log));
    }
  }

	export class FlowReleaseChanged {
    static className = "FlowReleaseChanged";
    className() {
      return FlowReleaseChanged.className;
    }

    constructor(readonly newReleaseId: AggregateId) {}

		static copy(other: FlowReleaseChanged): FlowReleaseChanged {
			return new FlowReleaseChanged(AggregateId.copy(other.newReleaseId));
		}
	}

	export class ActionRedirected {
    static className = "ActionRedirected";
    className() {
      return ActionRedirected.className;
    }

		constructor(readonly cursorId: FlowCursorId,
                readonly newCursorPhase: PhaseWithStep,
                readonly edgeId: ProcessEdgeId) {}

		static copy(other: ActionRedirected): ActionRedirected {
			return new ActionRedirected(other.cursorId, PhaseWithStep.copy(other.newCursorPhase), other.edgeId);
		}
	}

	export class ActionButtonExecutedV1 {
    static className = "ActionButtonExecutedV1";
    className() {
      return ActionButtonExecutedV1.className;
    }

		constructor(readonly cursorId: FlowCursorId,
                readonly sectionId: number,
                readonly elementId: number,
                readonly contextObjectId: Option<ObjectId>) {}

		static copy(other: ActionButtonExecutedV1): ActionButtonExecutedV1 {
			return new ActionButtonExecutedV1(other.cursorId, other.sectionId, other.elementId, Option.copy(other.contextObjectId));
		}
	}

  export class TaskMarkedAsSeenV1 {
    static className = "TaskMarkedAsSeenV1";
    className() {
      return TaskMarkedAsSeenV1.className;
    }

    constructor(readonly nodeId: ProcessNodeId,
                readonly personId: AggregateId) {}

    static copy(other: TaskMarkedAsSeenV1) {
      return new TaskMarkedAsSeenV1(other.nodeId, other.personId);
    }
  }

  export class TaskMarkedAsSeenLocalPerson {
    static className = "TaskMarkedAsSeenLocalPerson";
    className() {
      return TaskMarkedAsSeenLocalPerson.className;
    }
    constructor(readonly nodeId: number,
                readonly person: string) {}
    static copy(other: TaskMarkedAsSeenLocalPerson):TaskMarkedAsSeenLocalPerson {
      return new TaskMarkedAsSeenLocalPerson(
        other.nodeId,
        other.person
      );
    }
  }

  export class TaskMarkedAsSeenRemotePerson {
    static className = "TaskMarkedAsSeenRemotePerson";
    className() {
      return TaskMarkedAsSeenRemotePerson.className;
    }
    constructor(readonly nodeId: number,
                readonly person: RemotePersonId) {}
    static copy(other: TaskMarkedAsSeenRemotePerson):TaskMarkedAsSeenRemotePerson {
      return new TaskMarkedAsSeenRemotePerson(
        other.nodeId,
        RemotePersonId.copy(other.person)
      );
    }
  }

  export class TaskUnmarkedAsSeenLocalPerson {
    static className = "TaskUnmarkedAsSeenLocalPerson";
    className() {
      return TaskUnmarkedAsSeenLocalPerson.className;
    }
    constructor(readonly nodeId: number,
                readonly person: string) {}
    static copy(other: TaskUnmarkedAsSeenLocalPerson):TaskUnmarkedAsSeenLocalPerson {
      return new TaskUnmarkedAsSeenLocalPerson(
        other.nodeId,
        other.person
      );
    }
  }

  export class TaskUnmarkedAsSeenRemotePerson {
    static className = "TaskUnmarkedAsSeenRemotePerson";
    className() {
      return TaskUnmarkedAsSeenRemotePerson.className;
    }
    constructor(readonly nodeId: number,
                readonly person: RemotePersonId) {}
    static copy(other: TaskUnmarkedAsSeenRemotePerson):TaskUnmarkedAsSeenRemotePerson {
      return new TaskUnmarkedAsSeenRemotePerson(
        other.nodeId,
        RemotePersonId.copy(other.person)
      );
    }
  }

  export class NodeListsSetForRoles {
    static className = "NodeListsSetForRoles";
    className() {
      return NodeListsSetForRoles.className;
    }

    constructor(readonly rolesNodeLists: Array<[number, Array<OrganizationNodeId>]>) {}

    static copy(other: NodeListsSetForRoles) {
      return new NodeListsSetForRoles(other.rolesNodeLists.map((entry: [number, Array<OrganizationNodeId>]) =>
        <[number, Array<OrganizationNodeId>]> [entry[0], entry[1].map(OrganizationNodeId.copy)]));
    }
  }

  export class NodeListForRoleChanged {
    static className = "NodeListForRoleChanged";
    className() {
      return NodeListForRoleChanged.className;
    }
    constructor(readonly role: number,
                readonly nodes: Array<string>) {}
    static copy(other: NodeListForRoleChanged):NodeListForRoleChanged {
      return new NodeListForRoleChanged(
        other.role,
        other.nodes.slice()
      );
    }
  }

  export class CanChangeImportanceChangedForRoles {
    static className = "CanChangeImportanceChangedForRoles";
    className() {
      return CanChangeImportanceChangedForRoles.className;
    }

    constructor(readonly canChange: Array<[number, boolean]>) {}

    static copy(other: CanChangeImportanceChangedForRoles) {
      return new CanChangeImportanceChangedForRoles(other.canChange.map((entry: [number, boolean]) =>
        <[number, boolean]> [entry[0], entry[1]]));
    }
  }

  export class CanChangeImportanceAllowedForRole {
    static className = "CanChangeImportanceAllowedForRole";
    className() {
      return CanChangeImportanceAllowedForRole.className;
    }
    constructor(readonly role: number) {}
    static copy(other: CanChangeImportanceAllowedForRole):CanChangeImportanceAllowedForRole {
      return new CanChangeImportanceAllowedForRole(
        other.role
      );
    }
  }

  export class CanChangeImportanceDeniedForRole {
    static className = "CanChangeImportanceDeniedForRole";
    className() {
      return CanChangeImportanceDeniedForRole.className;
    }
    constructor(readonly role: number) {}
    static copy(other: CanChangeImportanceDeniedForRole):CanChangeImportanceDeniedForRole {
      return new CanChangeImportanceDeniedForRole(
        other.role
      );
    }
  }


  export class CanChangeUrgencyChangedForRoles {
    static className = "CanChangeUrgencyChangedForRoles";
    className() {
      return CanChangeUrgencyChangedForRoles.className;
    }

    constructor(readonly canChange: Array<[number, boolean]>) {}

    static copy(other: CanChangeUrgencyChangedForRoles) {
      return new CanChangeUrgencyChangedForRoles(other.canChange.map((entry: [number, boolean]) =>
        <[number, boolean]> [entry[0], entry[1]]));
    }
  }


  export class CanChangeUrgencyAllowedForRole {
    static className = "CanChangeUrgencyAllowedForRole";
    className() {
      return CanChangeUrgencyAllowedForRole.className;
    }
    constructor(readonly role: number) {}
    static copy(other: CanChangeUrgencyAllowedForRole):CanChangeUrgencyAllowedForRole {
      return new CanChangeUrgencyAllowedForRole(
        other.role
      );
    }
  }

  export class CanChangeUrgencyDeniedForRole {
    static className = "CanChangeUrgencyDeniedForRole";
    className() {
      return CanChangeUrgencyDeniedForRole.className;
    }
    constructor(readonly role: number) {}
    static copy(other: CanChangeUrgencyDeniedForRole):CanChangeUrgencyDeniedForRole {
      return new CanChangeUrgencyDeniedForRole(
        other.role
      );
    }
  }


  export class CanChangeLabelsChangedForRoles {
    static className = "CanChangeLabelsChangedForRoles";
    className() {
      return CanChangeLabelsChangedForRoles.className;
    }

    constructor(readonly canChange: Array<[number, boolean]>) {}

    static copy(other: CanChangeLabelsChangedForRoles) {
      return new CanChangeLabelsChangedForRoles(other.canChange.map((entry: [number, boolean]) =>
        <[number, boolean]> [entry[0], entry[1]]));
    }
  }


  export class CanChangeLabelsAllowedForRole {
    static className = "CanChangeLabelsAllowedForRole";
    className() {
      return CanChangeLabelsAllowedForRole.className;
    }
    constructor(readonly role: number) {}
    static copy(other: CanChangeLabelsAllowedForRole):CanChangeLabelsAllowedForRole {
      return new CanChangeLabelsAllowedForRole(
        other.role
      );
    }
  }

  export class CanChangeLabelsDeniedForRole {
    static className = "CanChangeLabelsDeniedForRole";
    className() {
      return CanChangeLabelsDeniedForRole.className;
    }
    constructor(readonly role: number) {}
    static copy(other: CanChangeLabelsDeniedForRole):CanChangeLabelsDeniedForRole {
      return new CanChangeLabelsDeniedForRole(
        other.role
      );
    }
  }

  export class FlowEditAuthorizationAdded {
    static className = "FlowEditAuthorizationAdded";
    className() {
      return FlowEditAuthorizationAdded.className;
    }

    static copy(other: FlowEditAuthorizationAdded) {
      return new FlowEditAuthorizationAdded();
    }
  }

  export class FlowEditAuthorizationRemoved {
    static className = "FlowEditAuthorizationRemoved";
    className() {
      return FlowEditAuthorizationRemoved.className;
    }

    static copy(other: FlowEditAuthorizationRemoved) {
      return new FlowEditAuthorizationRemoved();
    }
  }

  export class FlowPreviewAuthorizationAdded {
    static className = "FlowPreviewAuthorizationAdded";
    className() {
      return FlowPreviewAuthorizationAdded.className;
    }
    static copy(other: FlowPreviewAuthorizationAdded) {
      return new FlowPreviewAuthorizationAdded();
    }
  }

  export class FlowPreviewAuthorizationRemoved {
    static className = "FlowPreviewAuthorizationRemoved";
    className() {
      return FlowPreviewAuthorizationRemoved.className;
    }

    static copy(other: FlowPreviewAuthorizationRemoved) {
      return new FlowPreviewAuthorizationRemoved();
    }
  }

  export class FlowPermanentlyDeleted {
    static className = "FlowPermanentlyDeleted";
    className() {
      return FlowPermanentlyDeleted.className;
    }

    static copy(other: FlowPermanentlyDeleted) {
      return new FlowPermanentlyDeleted();
    }
  }

  export class FlowDataAnonymized {
    static className = "FlowDataAnonymized";
    className() {
      return FlowDataAnonymized.className;
    }
    constructor(readonly variablesData: ContextVariable<BusinessVariable>[]) {}

    static copy(other: FlowDataAnonymized):FlowDataAnonymized {
      return new FlowDataAnonymized(other.variablesData.map(ContextVariable.copy));
    }
  }

export class ImportanceUpdated {
  static className = "ImportanceUpdated";
  className() {
    return ImportanceUpdated.className;
  }
  constructor(readonly importance: number) {}
  static copy(other: ImportanceUpdated):ImportanceUpdated {
    return new ImportanceUpdated(other.importance);
  }
}

export class ImportanceOverrode {
  static className = "ImportanceOverrode";
  className() {
    return ImportanceOverrode.className;
  }
  constructor(readonly importance: number) {}
  static copy(other: ImportanceOverrode):ImportanceOverrode {
    return new ImportanceOverrode(other.importance);
  }
}

export class ImportanceOverrideCleared {
  static className = "ImportanceOverrideCleared";
  className() {
    return ImportanceOverrideCleared.className;
  }
  constructor() {}
  static copy(other: ImportanceOverrideCleared):ImportanceOverrideCleared {
    return new ImportanceOverrideCleared();
  }
}


  export class UrgencyUpdated {
    static className = "UrgencyUpdated";
    className() {
      return UrgencyUpdated.className;
    }
    constructor(readonly urgency: number) {}
    static copy(other: UrgencyUpdated):UrgencyUpdated {
      return new UrgencyUpdated(other.urgency);
    }
  }

  export class UrgencyOverrode {
    static className = "UrgencyOverrode";
    className() {
      return UrgencyOverrode.className;
    }
    constructor(readonly urgency: number) {}
    static copy(other: UrgencyOverrode):UrgencyOverrode {
      return new UrgencyOverrode(other.urgency);
    }
  }

  export class UrgencyOverrideCleared {
    static className = "UrgencyOverrideCleared";
    className() {
      return UrgencyOverrideCleared.className;
    }
    constructor() {}
    static copy(other: UrgencyOverrideCleared):UrgencyOverrideCleared {
      return new UrgencyOverrideCleared();
    }
  }


export class ColorUpdated {
  static className = "ColorUpdated";
  className() {
    return ColorUpdated.className;
  }
  constructor(readonly color: Option<string>) {}
      static copy(other: ColorUpdated):ColorUpdated {
      return new ColorUpdated(Option.copy(other.color));
    }
}

export class ColorOverrode {
  static className = "ColorOverrode";
  className() {
    return ColorOverrode.className;
  }
  constructor(readonly color: Option<string>) {}
      static copy(other: ColorOverrode):ColorOverrode {
      return new ColorOverrode(Option.copy(other.color));
    }
}

export class ColorOverrideCleared {
  static className = "ColorOverrideCleared";
  className() {
    return ColorOverrideCleared.className;
  }
  constructor() {}
      static copy(other: ColorOverrideCleared):ColorOverrideCleared {
      return new ColorOverrideCleared();
    }
}


export class TaskProgressSet {
  static className = "TaskProgressSet";
  className() {
    return TaskProgressSet.className;
  }
  constructor(readonly nodeId: ProcessNodeId, readonly percent: number) {}
      static copy(other: TaskProgressSet):TaskProgressSet {
      return new TaskProgressSet(other.nodeId, other.percent);
    }
}

export class TaskProgressCleared {
  static className = "TaskProgressCleared";
  className() {
    return TaskProgressCleared.className;
  }
  constructor(readonly nodeId: ProcessNodeId) {}
      static copy(other: TaskProgressCleared):TaskProgressCleared {
      return new TaskProgressCleared(other.nodeId);
    }
}

export class TaskDeadlineChanged {
  static className = "TaskDeadlineChanged";
  className() {
    return TaskDeadlineChanged.className;
  }
  constructor(readonly nodeId: ProcessNodeId, readonly deadline: LocalDateTime) {}
  static copy(other: TaskDeadlineChanged):TaskDeadlineChanged {
    return new TaskDeadlineChanged(other.nodeId, LocalDateTime.copy(other.deadline));
  }
}

export class TaskDeadlineChangedV1 {
  static className = "TaskDeadlineChangedV1";
  className() {
    return TaskDeadlineChangedV1.className;
  }

  constructor(readonly nodeId: number,
              readonly deadline: string) {}

  static copy(other: TaskDeadlineChangedV1):TaskDeadlineChangedV1 {
    return new TaskDeadlineChangedV1(
      other.nodeId,
      other.deadline);
  }

  deadlineTime(): LocalDateTime {
    return LocalDateTime.ofUTC(this.deadline, "T").result;
  }
}


export class TaskDeadlineCleared {
  static className = "TaskDeadlineCleared";
  className() {
    return TaskDeadlineCleared.className;
  }
  constructor(readonly nodeId: ProcessNodeId, readonly deadline: LocalDateTime) {}
  static copy(other: TaskDeadlineCleared):TaskDeadlineCleared {
    return new TaskDeadlineCleared(other.nodeId, LocalDateTime.copy(other.deadline));
  }
}

export class TaskDeadlineOverrodeV1 {
  static className = "TaskDeadlineOverrodeV1";
  className() {
    return TaskDeadlineOverrodeV1.className;
  }
  constructor(readonly nodeId: ProcessNodeId, readonly deadline: Option<LocalDateTime>) {}
      static copy(other: TaskDeadlineOverrodeV1):TaskDeadlineOverrodeV1 {
      return new TaskDeadlineOverrodeV1(other.nodeId, Option.copy(other.deadline, LocalDateTime.copy));
    }
}

export class TaskDeadlineOverrodeCleared {
  static className = "TaskDeadlineOverrodeCleared";
  className() {
    return TaskDeadlineOverrodeCleared.className;
  }
  constructor(readonly nodeId: ProcessNodeId) {}
    static copy(other: TaskDeadlineOverrodeCleared):TaskDeadlineOverrodeCleared {
    return new TaskDeadlineOverrodeCleared(other.nodeId);
  }
}

  export class FlowLabelAdded {
    static className = "FlowLabelAdded";
    className() {
      return FlowLabelAdded.className;
    }
    constructor(readonly label: string) {}
    static copy(other: FlowLabelAdded):FlowLabelAdded {
      return new FlowLabelAdded(other.label);
    }
  }

  export class FlowLabelRemoved {
    static className = "FlowLabelRemoved";
    className() {
      return FlowLabelRemoved.className;
    }
    constructor(readonly label: string) {}
    static copy(other: FlowLabelRemoved):FlowLabelRemoved {
      return new FlowLabelRemoved(other.label);
    }
  }

  export class FlowMarkedAsWorking {
    static className = "FlowMarkedAsWorking";
    className() {
      return FlowMarkedAsWorking.className;
    }
    constructor(readonly person: Typed<AnyPersonId>) {}
    static copy(other: FlowMarkedAsWorking):FlowMarkedAsWorking {
      return new FlowMarkedAsWorking(AnyPersonIdFactory.copyTyped(other.person));
    }
  }

  export class FlowUnMarkedAsWorking {
    static className = "FlowUnMarkedAsWorking";
    className() {
      return FlowUnMarkedAsWorking.className;
    }
    constructor() {}
    static copy(other: FlowUnMarkedAsWorking):FlowUnMarkedAsWorking {
      return new FlowUnMarkedAsWorking();
    }
  }

  export class EdgeMarkedOutAllowed {
    static className = "EdgeMarkedOutAllowed";
    className() {
      return EdgeMarkedOutAllowed.className;
    }
    constructor(readonly edgeId: ProcessEdgeId) {}
    static copy(other: EdgeMarkedOutAllowed):EdgeMarkedOutAllowed {
      return new EdgeMarkedOutAllowed(other.edgeId);
    }
  }

  export class EdgeMarkedOutDenied {
    static className = "EdgeMarkedOutDenied";
    className() {
      return EdgeMarkedOutDenied.className;
    }
    constructor(readonly edgeId: ProcessEdgeId) {}
    static copy(other: EdgeMarkedOutDenied):EdgeMarkedOutDenied {
      return new EdgeMarkedOutDenied(other.edgeId);
    }
  }


export class EdgeMarkedInAllowed {
  static className = "EdgeMarkedInAllowed";
  className() {
    return EdgeMarkedInAllowed.className;
  }
  constructor(readonly edgeId: ProcessEdgeId) {}
  static copy(other: EdgeMarkedInAllowed):EdgeMarkedInAllowed {
    return new EdgeMarkedInAllowed(other.edgeId);
  }
}

export class EdgeMarkedInDenied {
  static className = "EdgeMarkedInDenied";
  className() {
    return EdgeMarkedInDenied.className;
  }
  constructor(readonly edgeId: ProcessEdgeId) {}
  static copy(other: EdgeMarkedInDenied):EdgeMarkedInDenied {
    return new EdgeMarkedInDenied(other.edgeId);
  }
}

export class EntryObjectAddedToArray {
  static className = "EntryObjectAddedToArray";
  className() {
    return EntryObjectAddedToArray.className;
  }
  constructor(readonly path: string,
              readonly entry: ObjectVariable) {}
  static copy(other: EntryObjectAddedToArray):EntryObjectAddedToArray {
    return new EntryObjectAddedToArray(other.path, ObjectVariable.copy(other.entry));
  }
}

export class EntryDeletedFromArray {
  static className = "EntryDeletedFromArray";
  className() {
    return EntryDeletedFromArray.className;
  }
  constructor(readonly path: string,
              readonly index: number) {}
  static copy(other: EntryDeletedFromArray):EntryDeletedFromArray {
    return new EntryDeletedFromArray(other.path, other.index);
  }
}
