import {
  __,
  ___,
  AggregateId,
  AggregateVersion,
  AnyFlowId,
  AnyFlowIdFactory,
  AnyFlowIdHelper,
  AnyPersonId,
  AnyPersonIdHelper,
  displayExceptions,
  FileUri,
  FlowId,
  FormElementId,
  FormElementRefId,
  htmlEscape,
  InstanceId,
  LocalDateTime, mySetTimeout,
  None,
  ObjectId,
  Option,
  PersonId,
  Some,
  toastr,
  ToastrCategory,
  Typed,
  values
} from "@utils";
import {
  ArrayVariable,
  BasicPersonInfo,
  BusinessVariable,
  BusinessVariableFactory,
  CommentPersonInfo,
  ContextPath,
  ContextVariable,
  CommentViewModel,
  ObjectVariable,
  PersonsSharedService,
  PersonSummary,
  ProcessFlowComment,
  ProcessFlowEventInfo,
  RootVariable,
  TaskIdentifier,
  TaskModelSummary,
  TasksEventBus,
  VariablePath,
  ApplicationsSharedService,
  ApplicationName,
  ApplicationIcons,
  ProcessEdgeId,
  FlowCursorId,
  ProcessNodeId,
  FlowsSharedService
} from "@shared-model";
import {TaskInfo} from "./task-form.view-model";
import {
  FormSectionId,
  FormSectionRefId,
  I18nService,
  OrganizationSessionInfoClientSide,
  ServerEventsService
} from "@shared"
import {TaskEventBus} from "./TaskEventBus";
import {
  SubmitFormFailedResponseHandler,
  SubmitFormFailureResponse,
  SubmitFormSuccessResponse
} from "./model/ProcessFlowValidationErrors";
import {TaskModel} from "./model/ProcessFlow";
import {
  ActionButtonExecutedV1,
  ActionRedirected,
  ActionSubmittedByManager,
  ActionSubmittedBySubFlow,
  ActionSubmittedV3,
  ActivityCheckedV1,
  ActivityUncheckedV1,
  AutoVariablesFilledV4,
  CanChangeImportanceChangedForRoles,
  CanChangeLabelsChangedForRoles,
  CanChangeUrgencyChangedForRoles,
  ColorOverrideCleared,
  ColorOverrode,
  ColorUpdated,
  CommentAddedV3,
  CommentChanged,
  CommentDeleted,
  DeadlinesUpdatedV2,
  DescriptionUpdated,
  EdgeMarkedInAllowed,
  EdgeMarkedInDenied,
  EdgeMarkedOutAllowed,
  EdgeMarkedOutDenied,
  EntryDeletedFromArray,
  EntryObjectAddedToArray,
  FlowCancelled,
  FlowCursorCompleted,
  FlowCursorCreatedV1,
  FlowCursorDeletedV1,
  FlowDataAnonymized,
  FlowEditAuthorizationAdded,
  FlowEditAuthorizationRemoved,
  FlowLabelAdded,
  FlowLabelRemoved,
  FlowMarkedAsWorking,
  FlowPermanentlyDeleted,
  FlowPreviewAuthorizationAdded,
  FlowPreviewAuthorizationRemoved,
  FlowReleaseChanged,
  FlowUnMarkedAsWorking,
  FormFieldPropertyChangedV1,
  FormFieldPropertyClearedV1,
  ImportanceOverrideCleared,
  ImportanceOverrode,
  ImportanceUpdated,
  LogAdded,
  NodeListsSetForRoles,
  PersonAssignedToRoleV4,
  PersonSelfAssignedToRoleV1,
  PersonSelfUnAssignedFromRoleV1,
  PersonUnAssignedFromRoleV4,
  ProcessFlowCompleted,
  ProcessFlowCreatedV2,
  ProcessFlowReopened,
  ProcessFlowTerminatedV1,
  ProcessMaterialized,
  SectionLengthUpdatedV3,
  SectionVisibilityChanged,
  SubFlowCreated,
  TaskDeadlineOverrodeV1,
  TaskDeadlineOverrodeCleared,
  TaskMarkedAsSeenV1,
  TaskMarkedWaiting,
  TaskProgressCleared,
  TaskProgressSet,
  TaskStarted,
  TaskStopped,
  UrgencyOverrideCleared,
  UrgencyOverrode,
  UrgencyUpdated,
  VariablesFilledByOtherFlow,
  VariablesFilledV2,
  TaskDeadlineChanged,
  TaskDeadlineCleared,
  LocalPersonAssignedToRole,
  RemotePersonAssignedToRole,
  LocalPersonUnAssignedFromRole,
  RemotePersonUnAssignedFromRole,
  LocalPersonSelfUnAssignedFromRole,
  RemotePersonSelfUnAssignedFromRole,
  DescriptionUpdatedV1,
  DescriptionCleared,
  FlowCursorCompletedNoFinish,
  FlowCursorCompletedWithFinish,
  TaskMarkedAsSeenLocalPerson,
  TaskMarkedAsSeenRemotePerson,
  TaskUnmarkedAsSeenLocalPerson,
  TaskUnmarkedAsSeenRemotePerson,
  NodeListForRoleChanged,
  CanChangeImportanceAllowedForRole,
  CanChangeImportanceDeniedForRole,
  CanChangeUrgencyAllowedForRole,
  CanChangeUrgencyDeniedForRole,
  CanChangeLabelsAllowedForRole,
  CanChangeLabelsDeniedForRole,
  TaskDeadlineChangedV1,
  FormFieldInitializedPropertyChangedToTrueNoContext,
  FormFieldInitializedPropertyChangedToFalseNoContext,
  FormFieldInitializedPropertyChangedToTrueInContext,
  FormFieldInitializedPropertyChangedToFalseInContext,
  FormFieldReadOnlyPropertyChangedToTrueNoContext,
  FormFieldReadOnlyPropertyChangedToFalseNoContext,
  FormFieldReadOnlyPropertyChangedToTrueInContext,
  FormFieldReadOnlyPropertyChangedToFalseInContext,
  FormFieldHiddenPropertyChangedToTrueNoContext,
  FormFieldHiddenPropertyChangedToFalseNoContext,
  FormFieldHiddenPropertyChangedToTrueInContext,
  FormFieldHiddenPropertyChangedToFalseInContext,
  FormFieldRequiredPropertyChangedToTrueNoContext,
  FormFieldRequiredPropertyChangedToFalseNoContext,
  FormFieldRequiredPropertyChangedToTrueInContext,
  FormFieldRequiredPropertyChangedToFalseInContext,
  FormFieldPropertyChangedV1Derivative
} from "./model/ProcessFlowEvents";
import {FlowService} from "./service/FlowService";
import {FieldId, FieldWithData} from "./service/FlowMessages";
import {FormSectionInfo, InputElementRef} from "../process-common.module";


export class ProcessFlowEventInfoFactory {
  static copy(other: ProcessFlowEventInfo): ProcessFlowEventInfo {

    const eventClassName = Typed.className(other.event);
    const eventUnwrapped = Typed.value(other.event);

    let eventUnwrappedCopy: any;

    switch (eventClassName) {

      case LocalPersonAssignedToRole.className: eventUnwrappedCopy = LocalPersonAssignedToRole.copy(<LocalPersonAssignedToRole>eventUnwrapped); break;
      case RemotePersonAssignedToRole.className: eventUnwrappedCopy = RemotePersonAssignedToRole.copy(<RemotePersonAssignedToRole>eventUnwrapped); break;
      case LocalPersonUnAssignedFromRole.className: eventUnwrappedCopy = LocalPersonUnAssignedFromRole.copy(<LocalPersonUnAssignedFromRole>eventUnwrapped); break;
      case RemotePersonUnAssignedFromRole.className: eventUnwrappedCopy = RemotePersonUnAssignedFromRole.copy(<RemotePersonUnAssignedFromRole>eventUnwrapped); break;
      case LocalPersonSelfUnAssignedFromRole.className: eventUnwrappedCopy = LocalPersonSelfUnAssignedFromRole.copy(<LocalPersonSelfUnAssignedFromRole>eventUnwrapped); break;
      case RemotePersonSelfUnAssignedFromRole.className: eventUnwrappedCopy = RemotePersonSelfUnAssignedFromRole.copy(<RemotePersonSelfUnAssignedFromRole>eventUnwrapped); break;
      case DescriptionUpdatedV1.className: eventUnwrappedCopy = DescriptionUpdatedV1.copy(<DescriptionUpdatedV1>eventUnwrapped); break;
      case DescriptionCleared.className: eventUnwrappedCopy = DescriptionCleared.copy(<DescriptionCleared>eventUnwrapped); break;
      case FlowCursorCompletedNoFinish.className: eventUnwrappedCopy = FlowCursorCompletedNoFinish.copy(<FlowCursorCompletedNoFinish>eventUnwrapped); break;
      case FlowCursorCompletedWithFinish.className: eventUnwrappedCopy = FlowCursorCompletedWithFinish.copy(<FlowCursorCompletedWithFinish>eventUnwrapped); break;
      case TaskMarkedAsSeenLocalPerson.className: eventUnwrappedCopy = TaskMarkedAsSeenLocalPerson.copy(<TaskMarkedAsSeenLocalPerson>eventUnwrapped); break;
      case TaskMarkedAsSeenRemotePerson.className: eventUnwrappedCopy = TaskMarkedAsSeenRemotePerson.copy(<TaskMarkedAsSeenRemotePerson>eventUnwrapped); break;
      case TaskUnmarkedAsSeenLocalPerson.className: eventUnwrappedCopy = TaskUnmarkedAsSeenLocalPerson.copy(<TaskUnmarkedAsSeenLocalPerson>eventUnwrapped); break;
      case TaskUnmarkedAsSeenRemotePerson.className: eventUnwrappedCopy = TaskUnmarkedAsSeenRemotePerson.copy(<TaskUnmarkedAsSeenRemotePerson>eventUnwrapped); break;
      case NodeListForRoleChanged.className: eventUnwrappedCopy = NodeListForRoleChanged.copy(<NodeListForRoleChanged>eventUnwrapped); break;
      case CanChangeImportanceAllowedForRole.className: eventUnwrappedCopy = CanChangeImportanceAllowedForRole.copy(<CanChangeImportanceAllowedForRole>eventUnwrapped); break;
      case CanChangeImportanceDeniedForRole.className: eventUnwrappedCopy = CanChangeImportanceDeniedForRole.copy(<CanChangeImportanceDeniedForRole>eventUnwrapped); break;
      case CanChangeUrgencyAllowedForRole.className: eventUnwrappedCopy = CanChangeUrgencyAllowedForRole.copy(<CanChangeUrgencyAllowedForRole>eventUnwrapped); break;
      case CanChangeUrgencyDeniedForRole.className: eventUnwrappedCopy = CanChangeUrgencyDeniedForRole.copy(<CanChangeUrgencyDeniedForRole>eventUnwrapped); break;
      case CanChangeLabelsAllowedForRole.className: eventUnwrappedCopy = CanChangeLabelsAllowedForRole.copy(<CanChangeLabelsAllowedForRole>eventUnwrapped); break;
      case CanChangeLabelsDeniedForRole.className: eventUnwrappedCopy = CanChangeLabelsDeniedForRole.copy(<CanChangeLabelsDeniedForRole>eventUnwrapped); break;
      case TaskDeadlineChangedV1.className: eventUnwrappedCopy = TaskDeadlineChangedV1.copy(<TaskDeadlineChangedV1>eventUnwrapped); break;
      case VariablesFilledV2.className: eventUnwrappedCopy = VariablesFilledV2.copy(<VariablesFilledV2>eventUnwrapped); break;
      case VariablesFilledByOtherFlow.className: eventUnwrappedCopy = VariablesFilledByOtherFlow.copy(<VariablesFilledByOtherFlow>eventUnwrapped); break;
      case AutoVariablesFilledV4.className: eventUnwrappedCopy = AutoVariablesFilledV4.copy(<AutoVariablesFilledV4>eventUnwrapped); break;
      case FormFieldPropertyChangedV1.className: eventUnwrappedCopy = FormFieldPropertyChangedV1.copy(<FormFieldPropertyChangedV1>eventUnwrapped); break;

      case FormFieldInitializedPropertyChangedToTrueNoContext.className: eventUnwrappedCopy = FormFieldInitializedPropertyChangedToTrueNoContext.copy(<FormFieldInitializedPropertyChangedToTrueNoContext>eventUnwrapped); break;
      case FormFieldInitializedPropertyChangedToFalseNoContext.className: eventUnwrappedCopy = FormFieldInitializedPropertyChangedToFalseNoContext.copy(<FormFieldInitializedPropertyChangedToFalseNoContext>eventUnwrapped); break;
      case FormFieldInitializedPropertyChangedToTrueInContext.className: eventUnwrappedCopy = FormFieldInitializedPropertyChangedToTrueInContext.copy(<FormFieldInitializedPropertyChangedToTrueInContext>eventUnwrapped); break;
      case FormFieldInitializedPropertyChangedToFalseInContext.className: eventUnwrappedCopy = FormFieldInitializedPropertyChangedToFalseInContext.copy(<FormFieldInitializedPropertyChangedToFalseInContext>eventUnwrapped); break;
      case FormFieldReadOnlyPropertyChangedToTrueNoContext.className: eventUnwrappedCopy = FormFieldReadOnlyPropertyChangedToTrueNoContext.copy(<FormFieldReadOnlyPropertyChangedToTrueNoContext>eventUnwrapped); break;
      case FormFieldReadOnlyPropertyChangedToFalseNoContext.className: eventUnwrappedCopy = FormFieldReadOnlyPropertyChangedToFalseNoContext.copy(<FormFieldReadOnlyPropertyChangedToFalseNoContext>eventUnwrapped); break;
      case FormFieldReadOnlyPropertyChangedToTrueInContext.className: eventUnwrappedCopy = FormFieldReadOnlyPropertyChangedToTrueInContext.copy(<FormFieldReadOnlyPropertyChangedToTrueInContext>eventUnwrapped); break;
      case FormFieldReadOnlyPropertyChangedToFalseInContext.className: eventUnwrappedCopy = FormFieldReadOnlyPropertyChangedToFalseInContext.copy(<FormFieldReadOnlyPropertyChangedToFalseInContext>eventUnwrapped); break;
      case FormFieldHiddenPropertyChangedToTrueNoContext.className: eventUnwrappedCopy = FormFieldHiddenPropertyChangedToTrueNoContext.copy(<FormFieldHiddenPropertyChangedToTrueNoContext>eventUnwrapped); break;
      case FormFieldHiddenPropertyChangedToFalseNoContext.className: eventUnwrappedCopy = FormFieldHiddenPropertyChangedToFalseNoContext.copy(<FormFieldHiddenPropertyChangedToFalseNoContext>eventUnwrapped); break;
      case FormFieldHiddenPropertyChangedToTrueInContext.className: eventUnwrappedCopy = FormFieldHiddenPropertyChangedToTrueInContext.copy(<FormFieldHiddenPropertyChangedToTrueInContext>eventUnwrapped); break;
      case FormFieldHiddenPropertyChangedToFalseInContext.className: eventUnwrappedCopy = FormFieldHiddenPropertyChangedToFalseInContext.copy(<FormFieldHiddenPropertyChangedToFalseInContext>eventUnwrapped); break;
      case FormFieldRequiredPropertyChangedToTrueNoContext.className: eventUnwrappedCopy = FormFieldRequiredPropertyChangedToTrueNoContext.copy(<FormFieldRequiredPropertyChangedToTrueNoContext>eventUnwrapped); break;
      case FormFieldRequiredPropertyChangedToFalseNoContext.className: eventUnwrappedCopy = FormFieldRequiredPropertyChangedToFalseNoContext.copy(<FormFieldRequiredPropertyChangedToFalseNoContext>eventUnwrapped); break;
      case FormFieldRequiredPropertyChangedToTrueInContext.className: eventUnwrappedCopy = FormFieldRequiredPropertyChangedToTrueInContext.copy(<FormFieldRequiredPropertyChangedToTrueInContext>eventUnwrapped); break;
      case FormFieldRequiredPropertyChangedToFalseInContext.className: eventUnwrappedCopy = FormFieldRequiredPropertyChangedToFalseInContext.copy(<FormFieldRequiredPropertyChangedToFalseInContext>eventUnwrapped); break;

      case FormFieldPropertyClearedV1.className: eventUnwrappedCopy = FormFieldPropertyClearedV1.copy(<FormFieldPropertyClearedV1>eventUnwrapped); break;
      case SectionLengthUpdatedV3.className: eventUnwrappedCopy = SectionLengthUpdatedV3.copy(<SectionLengthUpdatedV3>eventUnwrapped); break;
      case ActivityCheckedV1.className: eventUnwrappedCopy = ActivityCheckedV1.copy(<ActivityCheckedV1>eventUnwrapped); break;
      case ActivityUncheckedV1.className: eventUnwrappedCopy = ActivityUncheckedV1.copy(<ActivityUncheckedV1>eventUnwrapped); break;
      case TaskStarted.className: eventUnwrappedCopy = TaskStarted.copy(<TaskStarted>eventUnwrapped); break;
      case TaskStopped.className: eventUnwrappedCopy = TaskStopped.copy(<TaskStopped>eventUnwrapped); break;
      case TaskMarkedWaiting.className: eventUnwrappedCopy = TaskMarkedWaiting.copy(<TaskMarkedWaiting>eventUnwrapped); break;
      case FlowCancelled.className: eventUnwrappedCopy = FlowCancelled.copy(eventUnwrapped); break;
      case ActionSubmittedV3.className: eventUnwrappedCopy = ActionSubmittedV3.copy(eventUnwrapped); break;
      case ActionSubmittedBySubFlow.className: eventUnwrappedCopy = ActionSubmittedBySubFlow.copy(eventUnwrapped); break;
      case "FlowCursorAdvancedV6": eventUnwrappedCopy = eventUnwrapped; break;
      case "FlowCursorAdvancedToNodeError":  eventUnwrappedCopy = eventUnwrapped; break;
      case "FlowCursorAdvancedToEdgeError":  eventUnwrappedCopy = eventUnwrapped; break;
      case "FlowCursorAdvancedToNodeOK":  eventUnwrappedCopy = eventUnwrapped; break;
      case "FlowCursorAdvancedToEdgeOK":  eventUnwrappedCopy = eventUnwrapped; break;
      case FlowCursorCreatedV1.className: eventUnwrappedCopy = FlowCursorCreatedV1.copy(<FlowCursorCreatedV1>eventUnwrapped); break;
      case FlowCursorDeletedV1.className: eventUnwrappedCopy = FlowCursorDeletedV1.copy(<FlowCursorDeletedV1>eventUnwrapped); break;
      case FlowCursorCompleted.className: eventUnwrappedCopy = FlowCursorCompleted.copy(<FlowCursorCompleted>eventUnwrapped); break;
      case ProcessFlowCompleted.className: eventUnwrappedCopy = ProcessFlowCompleted.copy(eventUnwrapped); break;
      case ProcessFlowReopened.className: eventUnwrappedCopy = ProcessFlowReopened.copy(eventUnwrapped); break;
      case PersonAssignedToRoleV4.className: eventUnwrappedCopy = PersonAssignedToRoleV4.copy(<PersonAssignedToRoleV4>eventUnwrapped); break;
      case PersonUnAssignedFromRoleV4.className: eventUnwrappedCopy = PersonUnAssignedFromRoleV4.copy(<PersonUnAssignedFromRoleV4>eventUnwrapped); break;
      case PersonSelfAssignedToRoleV1.className: eventUnwrappedCopy = PersonSelfAssignedToRoleV1.copy(<PersonSelfAssignedToRoleV1>eventUnwrapped); break;
      case PersonSelfUnAssignedFromRoleV1.className: eventUnwrappedCopy = PersonSelfUnAssignedFromRoleV1.copy(<PersonSelfUnAssignedFromRoleV1>eventUnwrapped); break;
      case ProcessFlowCreatedV2.className: eventUnwrappedCopy = ProcessFlowCreatedV2.copy(<ProcessFlowCreatedV2>eventUnwrapped); break;
      case CommentAddedV3.className: eventUnwrappedCopy = CommentAddedV3.copy(<CommentAddedV3>eventUnwrapped); break;
      case CommentChanged.className: eventUnwrappedCopy = CommentChanged.copy(<CommentChanged>eventUnwrapped); break;
      case CommentDeleted.className: eventUnwrappedCopy = CommentDeleted.copy(<CommentDeleted>eventUnwrapped); break;
      case ImportanceUpdated.className: eventUnwrappedCopy = ImportanceUpdated.copy(<ImportanceUpdated>eventUnwrapped); break;
      case ImportanceOverrode.className: eventUnwrappedCopy = ImportanceOverrode.copy(<ImportanceOverrode>eventUnwrapped); break;
      case ImportanceOverrideCleared.className: eventUnwrappedCopy = ImportanceOverrideCleared.copy(<ImportanceOverrideCleared>eventUnwrapped); break;
      case UrgencyUpdated.className: eventUnwrappedCopy = UrgencyUpdated.copy(<UrgencyUpdated>eventUnwrapped); break;
      case UrgencyOverrode.className: eventUnwrappedCopy = UrgencyOverrode.copy(<UrgencyOverrode>eventUnwrapped); break;
      case UrgencyOverrideCleared.className: eventUnwrappedCopy = UrgencyOverrideCleared.copy(<UrgencyOverrideCleared>eventUnwrapped); break;
      case ColorUpdated.className: eventUnwrappedCopy = ColorUpdated.copy(<ColorUpdated>eventUnwrapped); break;
      case ColorOverrode.className: eventUnwrappedCopy = ColorOverrode.copy(<ColorOverrode>eventUnwrapped); break;
      case ColorOverrideCleared.className: eventUnwrappedCopy = ColorOverrideCleared.copy(<ColorOverrideCleared>eventUnwrapped); break;
      case ProcessFlowTerminatedV1.className: eventUnwrappedCopy = ProcessFlowTerminatedV1.copy(<ProcessFlowTerminatedV1>eventUnwrapped); break;
      case LogAdded.className: eventUnwrappedCopy = LogAdded.copy(<LogAdded>eventUnwrapped); break;
      case FlowReleaseChanged.className: eventUnwrappedCopy = FlowReleaseChanged.copy(<FlowReleaseChanged>eventUnwrapped); break;
      case ActionRedirected.className: eventUnwrappedCopy = ActionRedirected.copy(<ActionRedirected>eventUnwrapped); break;
      case ActionButtonExecutedV1.className: eventUnwrappedCopy = ActionButtonExecutedV1.copy(<ActionButtonExecutedV1>eventUnwrapped); break;
      case TaskMarkedAsSeenV1.className: eventUnwrappedCopy = TaskMarkedAsSeenV1.copy(<TaskMarkedAsSeenV1>eventUnwrapped); break;
      case NodeListsSetForRoles.className: eventUnwrappedCopy = NodeListsSetForRoles.copy(<NodeListsSetForRoles>eventUnwrapped); break;
      case CanChangeImportanceChangedForRoles.className: eventUnwrappedCopy = CanChangeImportanceChangedForRoles.copy(<CanChangeImportanceChangedForRoles>eventUnwrapped); break;
      case CanChangeUrgencyChangedForRoles.className: eventUnwrappedCopy = CanChangeUrgencyChangedForRoles.copy(<CanChangeUrgencyChangedForRoles>eventUnwrapped); break;
      case CanChangeLabelsChangedForRoles.className: eventUnwrappedCopy = CanChangeLabelsChangedForRoles.copy(<CanChangeLabelsChangedForRoles>eventUnwrapped); break;
      case ProcessMaterialized.className: eventUnwrappedCopy = ProcessMaterialized.copy(<ProcessMaterialized>eventUnwrapped); break;
      case DescriptionUpdated.className: eventUnwrappedCopy = DescriptionUpdated.copy(<DescriptionUpdated>eventUnwrapped); break;
      case SectionVisibilityChanged.className: eventUnwrappedCopy = SectionVisibilityChanged.copy(<SectionVisibilityChanged>eventUnwrapped); break;
      case DeadlinesUpdatedV2.className: eventUnwrappedCopy = DeadlinesUpdatedV2.copy(<DeadlinesUpdatedV2>eventUnwrapped); break;
      case ActionSubmittedByManager.className: eventUnwrappedCopy = ActionSubmittedByManager.copy(<ActionSubmittedByManager>eventUnwrapped); break;
      case SubFlowCreated.className: eventUnwrappedCopy = SubFlowCreated.copy(<SubFlowCreated>eventUnwrapped); break;
      case FlowEditAuthorizationAdded.className: eventUnwrappedCopy = FlowEditAuthorizationAdded.copy(<FlowEditAuthorizationAdded>eventUnwrapped); break;
      case FlowEditAuthorizationRemoved.className: eventUnwrappedCopy = FlowEditAuthorizationRemoved.copy(<FlowEditAuthorizationRemoved>eventUnwrapped); break;
      case FlowPreviewAuthorizationAdded.className: eventUnwrappedCopy = FlowPreviewAuthorizationAdded.copy(<FlowPreviewAuthorizationAdded>eventUnwrapped); break;
      case FlowPreviewAuthorizationRemoved.className: eventUnwrappedCopy = FlowPreviewAuthorizationRemoved.copy(<FlowPreviewAuthorizationRemoved>eventUnwrapped); break;
      case FlowPermanentlyDeleted.className: eventUnwrappedCopy = FlowPermanentlyDeleted.copy(<FlowPermanentlyDeleted>eventUnwrapped); break;
      case FlowDataAnonymized.className: eventUnwrappedCopy = FlowDataAnonymized.copy(<FlowDataAnonymized>eventUnwrapped); break;
      case TaskProgressSet.className: eventUnwrappedCopy = TaskProgressSet.copy(<TaskProgressSet>eventUnwrapped); break;
      case TaskProgressCleared.className: eventUnwrappedCopy = TaskProgressCleared.copy(<TaskProgressCleared>eventUnwrapped); break;
      case TaskDeadlineOverrodeV1.className: eventUnwrappedCopy = TaskDeadlineOverrodeV1.copy(<TaskDeadlineOverrodeV1>eventUnwrapped); break;
      case TaskDeadlineOverrodeCleared.className: eventUnwrappedCopy = TaskDeadlineOverrodeCleared.copy(<TaskDeadlineOverrodeCleared>eventUnwrapped); break;
      case TaskDeadlineChanged.className: eventUnwrappedCopy = TaskDeadlineChanged.copy(<TaskDeadlineChanged>eventUnwrapped); break;
      case TaskDeadlineCleared.className: eventUnwrappedCopy = TaskDeadlineCleared.copy(<TaskDeadlineCleared>eventUnwrapped); break;
      case FlowLabelAdded.className: eventUnwrappedCopy = FlowLabelAdded.copy(<FlowLabelAdded>eventUnwrapped); break;
      case FlowLabelRemoved.className: eventUnwrappedCopy = FlowLabelRemoved.copy(<FlowLabelRemoved>eventUnwrapped); break;
      case FlowMarkedAsWorking.className: eventUnwrappedCopy = FlowMarkedAsWorking.copy(<FlowMarkedAsWorking>eventUnwrapped); break;
      case FlowUnMarkedAsWorking.className: eventUnwrappedCopy = FlowUnMarkedAsWorking.copy(<FlowUnMarkedAsWorking>eventUnwrapped); break;
      case EdgeMarkedOutAllowed.className: eventUnwrappedCopy = EdgeMarkedOutAllowed.copy(<EdgeMarkedOutAllowed>eventUnwrapped); break;
      case EdgeMarkedOutDenied.className: eventUnwrappedCopy = EdgeMarkedOutDenied.copy(<EdgeMarkedOutDenied>eventUnwrapped); break;
      case EdgeMarkedInAllowed.className: eventUnwrappedCopy = EdgeMarkedInAllowed.copy(<EdgeMarkedInAllowed>eventUnwrapped); break;
      case EdgeMarkedInDenied.className: eventUnwrappedCopy = EdgeMarkedInDenied.copy(<EdgeMarkedInDenied>eventUnwrapped); break;
      case EntryObjectAddedToArray.className: eventUnwrappedCopy = EntryObjectAddedToArray.copy(<EntryObjectAddedToArray>eventUnwrapped); break;
      case EntryDeletedFromArray.className: eventUnwrappedCopy = EntryDeletedFromArray.copy(<EntryDeletedFromArray>eventUnwrapped); break;
      default:
        throw new Error(`Unsupported event type ${eventClassName}, add event copying`);
    }

    return new ProcessFlowEventInfo(AnyFlowIdFactory.copyTyped(other.flowId), other.flowVersion, Typed.of(eventUnwrappedCopy));
  }
}



export class TaskEventListener {

  private lastFlowSubscriptionCode: Option<string> = None();
  private flowEventsSubscription: Option<number> = None();

  constructor(readonly serverEventsService: ServerEventsService,
              readonly taskEventBus: TaskEventBus) {

  }


  unsubscribeFromEventListening() {
    this.lastFlowSubscriptionCode.forEach(code => this.serverEventsService.unsubscribe(code));
    this.flowEventsSubscription.forEach(id => this.serverEventsService.serverEventBus.unsubscribe(id));
    this.lastFlowSubscriptionCode = None();
    this.flowEventsSubscription = None();
  }

  subscribeForFlowEvents(flowId: AnyFlowId, flowVersion: AggregateVersion) {

    this.unsubscribeFromEventListening();

    this.serverEventsService.subscribeForFlowEventInfo(flowId, flowVersion, (subscriptionCode: string) => {
      this.lastFlowSubscriptionCode = Some(subscriptionCode);
    });


    this.flowEventsSubscription = Some(this.serverEventsService.serverEventBus.on(this.serverEventsService.serverEventBus.flowEvent,
      (rawEventInfo: ProcessFlowEventInfo) => {
        let eventInfo = rawEventInfo;
        switch (Typed.className(eventInfo.event)) { // process event based on type
          case AutoVariablesFilledV4.className:
            eventInfo = ProcessFlowEventInfoFactory.copy(rawEventInfo);
            this.onAutoVariablesFilled(eventInfo.flowIdUnwrapped(), <AutoVariablesFilledV4>Typed.value(eventInfo.event));
            break;
          case DescriptionUpdated.className:
            eventInfo = ProcessFlowEventInfoFactory.copy(rawEventInfo);
            this.onDescriptionUpdated(eventInfo.flowIdUnwrapped(), (<DescriptionUpdated>Typed.value(eventInfo.event)).description.getOrElse(""));
            break;
          case DescriptionUpdatedV1.className:
            eventInfo = ProcessFlowEventInfoFactory.copy(rawEventInfo);
            this.onDescriptionUpdated(eventInfo.flowIdUnwrapped(), (<DescriptionUpdatedV1>Typed.value(eventInfo.event)).description);
            break;
          case DescriptionCleared.className:
            eventInfo = ProcessFlowEventInfoFactory.copy(rawEventInfo);
            this.onDescriptionUpdated(eventInfo.flowIdUnwrapped(), "");
            break;
          case SectionVisibilityChanged.className:
            eventInfo = ProcessFlowEventInfoFactory.copy(rawEventInfo);
            this.onSectionVisibilityChanged(eventInfo.flowIdUnwrapped(), <SectionVisibilityChanged>Typed.value(eventInfo.event));
            break;
          case FormFieldPropertyChangedV1.className:
            eventInfo = ProcessFlowEventInfoFactory.copy(rawEventInfo);
            this.onFormFieldPropertyChanged(eventInfo.flowIdUnwrapped(), <FormFieldPropertyChangedV1>Typed.value(eventInfo.event));
            break;
          case FormFieldInitializedPropertyChangedToTrueNoContext.className:
          case FormFieldInitializedPropertyChangedToFalseNoContext.className:
          case FormFieldInitializedPropertyChangedToTrueInContext.className:
          case FormFieldInitializedPropertyChangedToFalseInContext.className:
          case FormFieldReadOnlyPropertyChangedToTrueNoContext.className:
          case FormFieldReadOnlyPropertyChangedToFalseNoContext.className:
          case FormFieldReadOnlyPropertyChangedToTrueInContext.className:
          case FormFieldReadOnlyPropertyChangedToFalseInContext.className:
          case FormFieldHiddenPropertyChangedToTrueNoContext.className:
          case FormFieldHiddenPropertyChangedToFalseNoContext.className:
          case FormFieldHiddenPropertyChangedToTrueInContext.className:
          case FormFieldHiddenPropertyChangedToFalseInContext.className:
          case FormFieldRequiredPropertyChangedToTrueNoContext.className:
          case FormFieldRequiredPropertyChangedToFalseNoContext.className:
          case FormFieldRequiredPropertyChangedToTrueInContext.className:
          case FormFieldRequiredPropertyChangedToFalseInContext.className:
            eventInfo = ProcessFlowEventInfoFactory.copy(rawEventInfo);
            this.onFormFieldPropertyChanged(eventInfo.flowIdUnwrapped(), (<FormFieldPropertyChangedV1Derivative>Typed.value(eventInfo.event)).toFormFieldPropertyChangedV1());
            break;
          case FormFieldPropertyClearedV1.className:
            eventInfo = ProcessFlowEventInfoFactory.copy(rawEventInfo);
            this.onFormFieldPropertyCleared(eventInfo.flowIdUnwrapped(), <FormFieldPropertyClearedV1>Typed.value(eventInfo.event));
            break;
          case VariablesFilledV2.className:
            eventInfo = ProcessFlowEventInfoFactory.copy(rawEventInfo);
            this.onVariablesFilled(eventInfo.flowIdUnwrapped(), <VariablesFilledV2>Typed.value(eventInfo.event));
            break;
          case VariablesFilledByOtherFlow.className:
            eventInfo = ProcessFlowEventInfoFactory.copy(rawEventInfo);
            const e = <VariablesFilledByOtherFlow>Typed.value(eventInfo.event);
            this.onVariablesFilled(eventInfo.flowIdUnwrapped(), new VariablesFilledV2(e.variablesData, e.clearedVariables, e.variablesToEvaluate));
            break;
          case FlowDataAnonymized.className:
            eventInfo = ProcessFlowEventInfoFactory.copy(rawEventInfo);
            this.onVariablesFilled(eventInfo.flowIdUnwrapped(), new VariablesFilledV2((<VariablesFilledV2>Typed.value(eventInfo.event)).variablesData, [], []));
            break;
          case SectionLengthUpdatedV3.className:
            eventInfo = ProcessFlowEventInfoFactory.copy(rawEventInfo);
            this.onSectionLengthUpdated(eventInfo.flowIdUnwrapped(), <SectionLengthUpdatedV3>Typed.value(eventInfo.event));
            break;
          case ActivityCheckedV1.className:
            eventInfo = ProcessFlowEventInfoFactory.copy(rawEventInfo);
            this.onActivityChecked(eventInfo.flowIdUnwrapped(), <ActivityCheckedV1>Typed.value(eventInfo.event));
            break;
          case ActivityUncheckedV1.className:
            eventInfo = ProcessFlowEventInfoFactory.copy(rawEventInfo);
            this.onActivityUnchecked(eventInfo.flowIdUnwrapped(), <ActivityUncheckedV1>Typed.value(eventInfo.event));
            break;
          case CommentAddedV3.className:
            eventInfo = ProcessFlowEventInfoFactory.copy(rawEventInfo);
            this.onCommentAddedV3(eventInfo.flowIdUnwrapped(), <CommentAddedV3>Typed.value(eventInfo.event));
            break;
          case CommentChanged.className:
            eventInfo = ProcessFlowEventInfoFactory.copy(rawEventInfo);
            this.onCommentChanged(eventInfo.flowIdUnwrapped(), <CommentChanged>Typed.value(eventInfo.event));
            break;
          case CommentDeleted.className:
            eventInfo = ProcessFlowEventInfoFactory.copy(rawEventInfo);
            this.onCommentDeleted(eventInfo.flowIdUnwrapped(), <CommentDeleted>Typed.value(eventInfo.event));
            break;
          case FlowCursorDeletedV1.className:
            eventInfo = ProcessFlowEventInfoFactory.copy(rawEventInfo);
            this.onFlowCursorDeleted(eventInfo.flowIdUnwrapped(), <FlowCursorDeletedV1>Typed.value(eventInfo.event));
            break;
          case LogAdded.className:
            eventInfo = ProcessFlowEventInfoFactory.copy(rawEventInfo);
            this.onLogAdded(eventInfo.flowIdUnwrapped(), <LogAdded>Typed.value(eventInfo.event));
            break;
          case ActionButtonExecutedV1.className:
            eventInfo = ProcessFlowEventInfoFactory.copy(rawEventInfo);
            this.onActionButtonExecuted(eventInfo.flowIdUnwrapped(), <ActionButtonExecutedV1>Typed.value(eventInfo.event));
            break;
          case ImportanceUpdated.className:
            eventInfo = ProcessFlowEventInfoFactory.copy(rawEventInfo);
            this.onImportanceUpdated(eventInfo.flowIdUnwrapped(), <ImportanceUpdated>Typed.value(eventInfo.event));
            break;
          case ImportanceOverrode.className:
            eventInfo = ProcessFlowEventInfoFactory.copy(rawEventInfo);
            break;
          case ImportanceOverrideCleared.className:
            eventInfo = ProcessFlowEventInfoFactory.copy(rawEventInfo);
            break;
          case ColorUpdated.className:
            eventInfo = ProcessFlowEventInfoFactory.copy(rawEventInfo);
            this.onColorUpdated(eventInfo.flowIdUnwrapped(), <ColorUpdated>Typed.value(eventInfo.event));
            break;
          case TaskDeadlineChanged.className:
            eventInfo = ProcessFlowEventInfoFactory.copy(rawEventInfo);
            this.onDeadlineChanged(eventInfo.flowIdUnwrapped(), (<TaskDeadlineChanged>Typed.value(eventInfo.event)).nodeId, Some((<TaskDeadlineChanged>Typed.value(eventInfo.event)).deadline));
            break;
          case TaskDeadlineChangedV1.className:
            eventInfo = ProcessFlowEventInfoFactory.copy(rawEventInfo);
            this.onDeadlineChanged(eventInfo.flowIdUnwrapped(), (<TaskDeadlineChangedV1>Typed.value(eventInfo.event)).nodeId, Some((<TaskDeadlineChangedV1>Typed.value(eventInfo.event)).deadlineTime()));
            break;
          case TaskDeadlineCleared.className:
            eventInfo = ProcessFlowEventInfoFactory.copy(rawEventInfo);
            this.onDeadlineChanged(eventInfo.flowIdUnwrapped(), (<TaskDeadlineCleared>Typed.value(eventInfo.event)).nodeId, None());
            break;
          case ProcessMaterialized.className:
            eventInfo = ProcessFlowEventInfoFactory.copy(rawEventInfo);
            this.onProcessMaterialized(eventInfo.flowIdUnwrapped(), <ProcessMaterialized>Typed.value(eventInfo.event));
            break;
          default: {
          } // ignore other events
        }
      }));
  }


  onAutoVariablesFilled(flowId: AnyFlowId, event: AutoVariablesFilledV4) {
    this.taskEventBus.taskAutoUpdated(flowId, event.variablesData, event.clearedVariables, event.variablesEvaluated);
  }

  onDescriptionUpdated(flowId: AnyFlowId, description: string) {
  }

  onSectionVisibilityChanged(flowId: AnyFlowId, event: SectionVisibilityChanged) {
    this.taskEventBus.sectionVisibilityChanged(flowId, event.shownSections, event.hiddenSections);
  }

  onFormFieldPropertyChanged(flowId: AnyFlowId, event: FormFieldPropertyChangedV1) {
    this.taskEventBus.fieldStateUpdated(flowId, event.elementId, event.contextObjectId, event.propertyName, Some(Typed.value(event.value)));
  }

  onFormFieldPropertyCleared(flowId: AnyFlowId, event: FormFieldPropertyClearedV1) {
    this.taskEventBus.fieldStateUpdated(flowId, event.elementId, event.contextObjectId, event.propertyName, None());
  }

  onVariablesFilled(flowId: AnyFlowId, event: VariablesFilledV2) {
    this.taskEventBus.taskAutoUpdated(flowId, event.variablesData, event.clearedVariables, []);
  }

  onSectionLengthUpdated(flowId: AnyFlowId, event: SectionLengthUpdatedV3) {
    this.taskEventBus.sectionLengthAutoUpdated(flowId, event.sectionVariableName, event.removedRows, event.addedRows);
  }

  onActivityChecked(flowId: AnyFlowId, event: ActivityCheckedV1) {
    this.taskEventBus.activityStateChanged(flowId, event.nodeId, event.activityId, true);
  }

  onActivityUnchecked(flowId: AnyFlowId, event: ActivityUncheckedV1) {
    this.taskEventBus.activityStateChanged(flowId, event.nodeId, event.activityId, false);
  }

  onCommentAddedV3(flowId: AnyFlowId, event: CommentAddedV3) {
    this.taskEventBus.commentAdded(flowId, Typed.value(event.person), event.commentId, event.extraRecipients.map(p => Typed.value(p)), event.attachments, event.commentText);
  }


  private onImportanceUpdated(flowId: AnyFlowId, event: ImportanceUpdated) {
    this.taskEventBus.importanceChanged(flowId, event.importance);
  }

  private onColorUpdated(flowId: AnyFlowId, event: ColorUpdated) {
    this.taskEventBus.colorChanged(flowId, event.color);
  }

  private onDeadlineChanged(flowId: AnyFlowId, nodeId: number, deadline: Option<LocalDateTime>) {
    // ok
  }

  onCommentChanged(flowId: AnyFlowId, event: CommentChanged) {
    // ok
  }

  onCommentDeleted(flowId: AnyFlowId, event: CommentDeleted) {
    // ok
  }

  onFlowCursorDeleted(flowId: AnyFlowId, event: FlowCursorDeletedV1) {
    // ok
  }

  onLogAdded(flowId: AnyFlowId, event: LogAdded) {
  }

  onActionButtonExecuted(flowId: AnyFlowId, event: ActionButtonExecutedV1) {
    // ok
  }

  private onProcessMaterialized(anyFlowId: AnyFlowId, value: ProcessMaterialized) {
    toastr.info("Process materialized");
  }
}



class PendingFormSubmit {
  constructor(readonly timestamp: number,
              readonly taskId: TaskIdentifier,
              readonly edgeId: ProcessEdgeId,
              readonly onCreateSuccess: (id: AnyFlowId, version: AggregateVersion) => void,
              readonly onUpdateSuccess: (id: AnyFlowId, version: AggregateVersion) => void) {
  }
}

export class TaskServerModel {

  private automatic: boolean = false;

  private fillFormPending: boolean = false;
  private fillFormQueue: Array<(() => void)> = [];


  private submitFormPending: Option<PendingFormSubmit> = None();
  private submitFormPendingTimeout: number = 0;


  private task: Option<TaskModel> = None();

  private taskRequested: Option<TaskIdentifier> = None();
  private openAfterLoad = false;

  private updatedFlowIds: Array<[AnyFlowId, AnyFlowId]> = [];

  constructor(readonly eventBus: TaskEventBus,
              readonly tasksEventBus: TasksEventBus,
              readonly flowService: FlowService,
              readonly flowsSharedService: FlowsSharedService,
              readonly personId: PersonId,
              readonly userFirstName: string,
              readonly userLastName: string,
              readonly userEmail: string,
              readonly personsSharedService: PersonsSharedService,
              readonly taskEventListener: TaskEventListener,
              readonly i18nService: I18nService,
              readonly applicationsSharedService: ApplicationsSharedService) {
    this.tasksEventBus.on(this.tasksEventBus.flowIdChanged, (oldId: AnyFlowId, newId: AnyFlowId) => this.onFlowIdChanged(oldId, newId));
    this.eventBus.on(this.eventBus.taskAutoUpdated, (flowId: AnyFlowId,
                                                     modifiedVariables: Array<ContextVariable<BusinessVariable>>,
                                                     variablesCleared: Array<ContextPath>,
                                                     variablesEvaluated: Array<ContextPath>) => {
        if (this.task.exists(t => AnyFlowIdHelper.equals(t.flowIdUnwrapped(), flowId))) {
          this.onTaskAutoUpdated(modifiedVariables, variablesCleared, variablesEvaluated)
        }
      }
    );
    this.eventBus.on(this.eventBus.sectionVisibilityChanged, (flowId: AnyFlowId,
                                                              shownSections: FormSectionId[], hiddenSections: FormSectionId[]) => {
        if (this.task.exists(t => AnyFlowIdHelper.equals(t.flowIdUnwrapped(), flowId))) {
          this.onSectionsVisibilityChanged(shownSections, hiddenSections)
        }
      }
    );
    this.eventBus.on(this.eventBus.fieldStateUpdated, (flowId: AnyFlowId,
                                                       elementId: FormElementId,
                                                       contextObjectId: Option<ObjectId>,
                                                       propertyName: string,
                                                       value: Option<BusinessVariable>) => {
        if (this.task.exists(t => AnyFlowIdHelper.equals(t.flowIdUnwrapped(), flowId))) {
          this.onFieldStateUpdated(elementId, contextObjectId, propertyName, value)
        }
      }
    );
  }

  unsubscribeFromEventListening() {
    this.taskEventListener.unsubscribeFromEventListening();
  }

  onFieldStateUpdated(elementId: FormElementId,
                      contextObjectId: Option<ObjectId>,
                      propertyName: string,
                      value: Option<BusinessVariable>) {

    const task = this.task.get();
    task.updateFieldState(elementId, contextObjectId, propertyName, value);
  }

  onTaskAutoUpdated(modifiedVariables: ContextVariable<BusinessVariable>[],
                    variablesCleared: Array<ContextPath>,
                    variablesEvaluated: Array<ContextPath>) {

    const task = this.task.get();

    task.pendingEvaluated = task.pendingEvaluated.concat(__(variablesEvaluated).filterNot(e => __(task.variablesToEvaluate).exists(v => e.isEqual(v))));
    task.variablesToEvaluate = __(task.variablesToEvaluate).filterNot(v => __(variablesEvaluated).exists(e => e.isEqual(v)));

    if(task.variablesToEvaluate.length === 0 && this.submitFormPending.isDefined()) {
      const pending = this.submitFormPending.get();
      this.submitFormPending = None();
      clearTimeout(this.submitFormPendingTimeout);
      this.tryToSubmitForm(pending.timestamp, pending.taskId, pending.edgeId, pending.onCreateSuccess, pending.onUpdateSuccess);
    }

    task.updateVariables(variablesCleared, modifiedVariables);
  }

  onSectionsVisibilityChanged(shownSections: FormSectionId[], hiddenSections: FormSectionId[]) {

    const task = this.task.get();
    task.updateSections(shownSections, hiddenSections);
  }

  loadTaskDetails(taskId: TaskIdentifier, openAfterLoad: boolean) {

    const updatedTaskId = this.getUpdatedTaskId(taskId);

    this.tasksEventBus.taskLoadStarted(openAfterLoad);

    if(!this.taskRequested.exists(t => t.equals(updatedTaskId))) {
      this.openAfterLoad = openAfterLoad;
      this.taskRequested = Some(updatedTaskId);
      this.flowService.getTaskWithPersonsOrWait(updatedTaskId.flowIdUnwrapped(), updatedTaskId.nodeId, (task: TaskModel, personsFromTask: Array<BasicPersonInfo>) => {
        this.personsSharedService.addToCache(personsFromTask);
        this.taskRequested.forEach(requested => {
            if (AnyFlowIdHelper.equals(requested.flowIdUnwrapped(), task.flowIdUnwrapped()) && requested.nodeId === task.nodeId) {

              this.applicationsSharedService.loadApplicationOptionName(task.applicationId, (applicationName: ApplicationName) => {
                this.task = Some(task);
                this.submitFormPending = None();
                clearTimeout(this.submitFormPendingTimeout);
                this.fillFormQueue = [];
                this.taskEventListener.subscribeForFlowEvents(task.flowIdUnwrapped(), task.flowVersion);
                this.eventBus.taskLoaded(task, applicationName.name, applicationName.identifier, ApplicationIcons.getApplicationOptionIcon(task.applicationId, applicationName.iconCode, applicationName.colorId), this.openAfterLoad);
                if (!task.seen) {
                  this.tasksEventBus.taskMarkedSeen(updatedTaskId, true);
                }
                this.taskRequested = None();
              });
            }// otherwise we don't want this task anymore
          }
        );
      }, () => {
        this.taskRequested = None();
        this.tasksEventBus.taskNotFound(updatedTaskId);
      }, (error) => {
        toastr.error(error);
        this.taskRequested = None();
        this.tasksEventBus.taskNotFound(updatedTaskId);
      });

    } else {
      this.openAfterLoad = this.openAfterLoad || openAfterLoad; // if second request for same happened but with different open command
    }
  }


  public openNewTaskExternal(applicationIdentifier: string,
                             processInstanceIdentifier: string,
                             startNodeIdentifier: string,
                             initVariables: Array<RootVariable<BusinessVariable>>) {

    this.flowService.createProcessFlowExternal(applicationIdentifier, processInstanceIdentifier, startNodeIdentifier, initVariables, (id, version, nodeId) => {

      this.flowService.getTaskWithPersonsOrWait(new FlowId(id.id), nodeId, (task: TaskModel, personsFromTask: Array<BasicPersonInfo>) => {

        const applicationName = ApplicationName.global;

        this.personsSharedService.addToCache(personsFromTask);
        this.task = Some(task);
        this.submitFormPending = None();
        clearTimeout(this.submitFormPendingTimeout);
        this.fillFormQueue = [];
        this.taskEventListener.subscribeForFlowEvents(task.flowIdUnwrapped(), task.flowVersion);
        this.eventBus.taskLoaded(task, applicationName.name, applicationName.identifier, ApplicationIcons.getApplicationOptionIcon(task.applicationId, applicationName.iconCode, applicationName.colorId), true);
      }, () => {
        this.tasksEventBus.taskNotFound(TaskIdentifier.of(new FlowId(id.id), nodeId));
        toastr.info("Not found");
      }, (error) => {
        this.tasksEventBus.taskNotFound(TaskIdentifier.of(new FlowId(id.id), nodeId));
        toastr.error(htmlEscape(error));
      })
    }, () => {
      // this.eventBus.taskNotFound(None(), startNodeId);
    });

  }

  onInternallyIdChanged(oldId: AnyFlowId, newId: AnyFlowId) {
    if(!AnyFlowIdHelper.equals(oldId, newId)) {
      if (this.task.exists(t => AnyFlowIdHelper.equals(t.flowIdUnwrapped(), oldId))) {
        this.task.get().flowId = Typed.of(newId);
        this.tasksEventBus.flowIdChanged(oldId, newId);
        this.taskEventListener.subscribeForFlowEvents(newId, this.task.get().flowVersion);
        this.eventBus.newFlowMaterialized(this.task.get().flowIdUnwrapped(), this.task.get().nodeId)
      }
    }
  }

  private onFlowIdChanged(oldId: AnyFlowId, newId: AnyFlowId) {
    if(!AnyFlowIdHelper.equals(oldId, newId)) {
      this.updatedFlowIds = this.updatedFlowIds.filter(u => !AnyFlowIdHelper.equals(u[0], oldId));
      this.updatedFlowIds.push([oldId, newId]);
      if (this.task.exists(t => AnyFlowIdHelper.equals(t.flowIdUnwrapped(), oldId))) {
        this.task.get().flowId = Typed.of(newId);
        this.taskEventListener.subscribeForFlowEvents(newId, this.task.get().flowVersion);
      }
    }
  }


  private getTask(taskId: TaskIdentifier, onSuccess: (task: TaskModel) => void) {
    const updatedTaskId = this.getUpdatedTaskId(taskId);

    if(this.task.exists(t => t.toTaskIdentifier().equals(updatedTaskId))) {
      onSuccess(this.task.get());
    } else {
      this.flowService.getTaskWithPersonsOrWait(updatedTaskId.flowIdUnwrapped(), updatedTaskId.nodeId, (task: TaskModel, personsFromTask: Array<BasicPersonInfo>) => {
        this.task = Some(task);
        onSuccess(task);
      }, () => {
        toastr.error("Task not found");
      }, (error) => {
        toastr.error("Task loading error: "+error);
      });
      }
  }

  private getUpdatedTaskId(taskId: TaskIdentifier): TaskIdentifier {
    const flowId = taskId.flowIdUnwrapped();
    const newFlowId = __(this.updatedFlowIds).find(idPair => AnyFlowIdHelper.equals(idPair[0], flowId));
    if(newFlowId.isDefined()) {
      return new TaskIdentifier(Typed.of(newFlowId.get()[1]), taskId.nodeId);
    } else {
      return taskId;
    }
  }

  private fillFormField(taskId: TaskIdentifier, refId: FormElementRefId, formSectionId: FormSectionId, contextObjectId: Option<ObjectId>, value: Option<BusinessVariable>,
                        onUpdateSuccess: (id: AnyFlowId, version: AggregateVersion) => void) {

    if (this.fillFormPending) {
      this.fillFormQueue.push(() => this.fillFormField(taskId, refId, formSectionId, contextObjectId, value, onUpdateSuccess));
    } else {
      this.fillFormPending = true;

      this.getTask(taskId, task => {

        const variablesToSet: FieldWithData[] = value.isDefined()
          ? this.findVariablesToSet(task, refId, formSectionId, value.get(), contextObjectId)
          : [];

        const variablesToClear: FieldId[] = value.isDefined()
          ? []
          : this.findVariablesToClear(task, refId, formSectionId, contextObjectId);

        this.changeTaskVariables(task, refId, value, contextObjectId);

        this.flowService.fillFormFields(
          task.flowIdUnwrapped(),
          task.cursorInfo.get().cursorId,
          task.cursorInfo.get().cursorVersion,
          variablesToSet,
          variablesToClear,
          (newId) => this.onInternallyIdChanged(task.flowIdUnwrapped(), newId),
          (id: AnyFlowId, version: AggregateVersion, variablesToEvaluate: Array<ContextPath>) => {
            onUpdateSuccess(id, version);
            if (variablesToEvaluate.length > 0) {
              this.eventBus.variablesWillBeEvaluated(id, variablesToEvaluate);
              const newPending = __(task.pendingEvaluated).filterNot(v => __(variablesToEvaluate).exists(e => e.isEqual(v)));
              const newVariablesToEvaluate = __(variablesToEvaluate).filterNot(v => __(task.pendingEvaluated).exists(e => e.isEqual(v)));
              task.pendingEvaluated = newPending;
              task.variablesToEvaluate = task.variablesToEvaluate.concat(newVariablesToEvaluate);
            }
          }, () => {
            this.fillFormPending = false;
            this.triggerTaskFromQueue();
          });
      });
    }
  }

  private findVariablesToClear(task: TaskModel, elementRefId: FormElementRefId, formSectionId: FormSectionId, contextObjectId: Option<ObjectId>) {
    return ___(task.visibleSectionsOnly()).filter(s => s.section.id.id === formSectionId.id).flatMap((formSectionInfo: FormSectionInfo) => {
      return ___(formSectionInfo.section.inputElementsRefs)
        .map(ref => Typed.value(ref))
        .filter(ref => elementRefId.id === ref.id.id)
        .filter(ref => {
          const state = task.fieldState(ref.elementId, contextObjectId);
          const readOnly = ref.readOnly.booleanOrElse(() => state.readOnly);
          const hidden = ref.hidden.booleanOrElse(() => state.hidden);
          return !readOnly && !hidden;
        }).map(inputElement => {
          return new FieldId(formSectionInfo.section.id, inputElement.id, contextObjectId);
        }).value();
    }).value();
  }

  private findVariablesToSet(task: TaskModel, elementRefId: FormElementRefId, formSectionId: FormSectionId, value: BusinessVariable, contextObjectId: Option<ObjectId>) {
    return ___(task.visibleSectionsOnly()).filter(s => s.section.id.id === formSectionId.id).flatMap((formSectionInfo: FormSectionInfo) => {
      return ___(formSectionInfo.section.inputElementsRefs)
        .map(ref => Typed.value(ref))
        .filter(ref => elementRefId.id === ref.id.id)
        .filter((ref: InputElementRef) => {
          const state = task.fieldState(ref.elementId, contextObjectId);
          const readOnly = ref.readOnly.booleanOrElse(() => state.readOnly);
          const hidden = ref.hidden.booleanOrElse(() => state.hidden);
          return !readOnly && !hidden;
        }).map(inputElement => {
          return new FieldWithData(formSectionInfo.section.id, inputElement.id, contextObjectId, Typed.of(value));
        }).value()
    }).value();
  }

  private changeTaskVariables(task: TaskModel, elementRefId: FormElementRefId, value: Option<BusinessVariable>, contextObjectId: Option<ObjectId>) {
    task.visibleSectionsOnly().forEach((formSectionInfo: FormSectionInfo) => {
      formSectionInfo.section.inputElementsRefs.map(ref => Typed.value(ref))
        .filter(ref => elementRefId.id === ref.id.id)
        .filter((ref: InputElementRef) => {
          const state = task.fieldState(ref.elementId, contextObjectId);
          const readOnly = ref.readOnly.booleanOrElse(() => state.readOnly);
          const hidden = ref.hidden.booleanOrElse(() => state.hidden);
          return !readOnly && !hidden;
        }).forEach(inputElement => {
        const element = this.task.get().inputElementForRef(inputElement);
        __(this.task.get().formVariables).find(fV => fV.name === element.variableTypePath.head()).forEach(v => {

          if(element.variableTypePath.isRoot()) {
            v.variable = value.map(vv => BusinessVariableFactory.copyTyped(Typed.of(vv)));
          } else {
            const array = <ArrayVariable<ObjectVariable>>v.unwrappedVariableOption().get();
            const index = __(array.unwrappedValue()).findIndexOf(o => (<ObjectVariable>o).id.id === contextObjectId.get().id).get();
            array.setValueByPath(index, VariablePath.root(element.variableTypePath.tail().head()), value.map(BusinessVariableFactory.copy));
          }

        })
      });
    });
  }


  updateField(taskId: TaskIdentifier, elementRefId: FormElementRefId, formSectionId: FormSectionId, contextObjectId: Option<ObjectId>, value: Option<BusinessVariable>) {
    if (!this.automatic) {
      this.fillFormField(taskId, elementRefId, formSectionId, contextObjectId, value,
        (id, version) => {
          // do nothing
        });
    }

  }


  executeActionButton(taskId: TaskIdentifier, sectionId: FormSectionId, contextObjectId: Option<ObjectId>, elementId: FormElementId) {
    if (this.fillFormPending) {
      this.fillFormQueue.push(() => this.executeActionButton(taskId, sectionId, contextObjectId, elementId));
    } else {
      this.fillFormPending = true;
      this.getTask(taskId, task => {
        this.flowService.executeActionButton(task.flowIdUnwrapped(), task.cursorInfo.get().cursorId, task.cursorInfo.get().cursorVersion,
          sectionId, contextObjectId, elementId,
          (newId) => this.onInternallyIdChanged(task.flowIdUnwrapped(), newId),
          () => {
          }, () => {
            this.fillFormPending = false;
            this.triggerTaskFromQueue();
          });
      })
    }

  }

  addFiles(taskId: TaskIdentifier, elementRefId: FormElementRefId, formSectionRefId: FormSectionRefId, contextObjectId: Option<ObjectId>, filesUris: Array<FileUri>): void {
    this.getTask(taskId, task => {
      this.flowService.addAttachments(
        task.flowIdUnwrapped(), task.cursorInfo.get().cursorId, task.cursorInfo.get().cursorVersion, formSectionRefId, contextObjectId, elementRefId, filesUris,
        (newId) => this.onInternallyIdChanged(task.flowIdUnwrapped(), newId),
        () => {
        }, displayExceptions);
    });
  };

  deleteFile(taskId: TaskIdentifier, elementRefId: FormElementRefId, formSectionRefId: FormSectionRefId, contextObjectId: Option<ObjectId>, fileUri: FileUri): void {
    if (this.fillFormPending) {
      this.fillFormQueue.push(() => this.deleteFile(taskId, elementRefId, formSectionRefId, contextObjectId, fileUri));
    } else {
      this.fillFormPending = true;
      this.getTask(taskId, task => {

        // TODO delete attachement to support file uploads
        this.flowService.deleteAttachment(
          task.flowIdUnwrapped(), task.cursorInfo.get().cursorId, task.cursorInfo.get().cursorVersion, formSectionRefId, contextObjectId, elementRefId, fileUri,
          (newId) => this.onInternallyIdChanged(task.flowIdUnwrapped(), newId),
          () => {},
          displayExceptions, () => {
            this.fillFormPending = false;
            this.triggerTaskFromQueue();
          }
        );
      });
    }
  }


  taskInfo() {
    const task = this.task.get();
    return new TaskInfo(task.flowIdUnwrapped(), task.nodeId, task.cursorInfo, false);
  }

  addSectionRow(taskId: TaskIdentifier, sectionId: FormSectionId, contextObjectId: ObjectId) {

    this.getTask(taskId, task => {
      const sectionInfo = task.visibleSectionsOnly().filter(s => s.section.id.id === sectionId.id)[0];

      if (sectionInfo.section.forEachVariableName.isDefined()) {
        const contextWithType = task.formVariables.filter(v => v.name === sectionInfo.section.forEachVariableName.get())[0];

        const rowData = new ObjectVariable({}, contextObjectId);
        if (contextWithType.variable.isDefined()) {
          const array = <ArrayVariable<BusinessVariable>>contextWithType.unwrappedVariableOption().get();
          array.addRow(rowData);
        } else {
          contextWithType.variable = Some(Typed.of(new ArrayVariable([Typed.of(rowData)])));
        }

        this.addSectionRowCommand(taskId, sectionId, contextObjectId);

      } else {
        throw new Error("Can only add row to sections with variable name defined");
      }
    });
  }

  addSectionRowCommand(taskId: TaskIdentifier, sectionId: FormSectionId, contextObjectId: ObjectId) {
    if (this.fillFormPending) {
      this.fillFormQueue.push(() => this.addSectionRowCommand(taskId, sectionId, contextObjectId));
    } else {
      this.fillFormPending = true;

      this.updateFormSectionArrayLength(taskId, sectionId, [], [contextObjectId], (id: AggregateId, version: AggregateVersion) => {
      }, () => {
        this.fillFormPending = false;
        this.triggerTaskFromQueue();
      });

    }
  }

  deleteSectionRow(taskId: TaskIdentifier, sectionId: FormSectionId, contextObjectId: ObjectId) {

    this.getTask(taskId, task => {
      const sectionInfo = task.visibleSectionsOnly().filter(s => s.section.id.id === sectionId.id)[0];
      if (sectionInfo.section.forEachVariableName.isDefined()) {
        const context = <ArrayVariable<BusinessVariable>>task.formVariables.filter(v => v.name === sectionInfo.section.forEachVariableName.get())[0].unwrappedVariableOption().get();
        const indexToRemove = __(<Array<BusinessVariable>>context.unwrappedValue()).findIndexOf(o => (<ObjectVariable>o).id.id === contextObjectId.id);
        context.deleteRow(indexToRemove.get());
        this.deleteSectionRowCommand(taskId, sectionId, contextObjectId);
      } else {
        this.fillFormPending = false;
        this.triggerTaskFromQueue();
        throw new Error("Can only add row to sections with variable name defined");
      }
    });
  }


  deleteSectionRowCommand(taskId: TaskIdentifier, sectionId: FormSectionId, contextObjectId: ObjectId) {
    if (this.fillFormPending) {
      this.fillFormQueue.push(() => this.deleteSectionRowCommand(taskId, sectionId, contextObjectId));
    } else {
      this.fillFormPending = true;
      this.updateFormSectionArrayLength(taskId, sectionId, [contextObjectId], [], (id: AggregateId, version: AggregateVersion) => {
      }, () => {
        this.fillFormPending = false;
        this.triggerTaskFromQueue();
      });
    }
  }

  private updateFormSectionArrayLength(taskId: TaskIdentifier, sectionId: FormSectionId, rowsToRemove: Array<ObjectId>, rowsToAdd: Array<ObjectId>,
                                       onUpdateSuccess: (id: AggregateId, version: AggregateVersion) => void, onCallEnd: () => void) {
    if (!this.automatic) {
      const task = this.task.get();

      this.flowService.changeSectionLength(
        task.flowIdUnwrapped(),
        task.cursorInfo.get().cursorId,
        task.cursorInfo.get().cursorVersion,
        sectionId,
        rowsToRemove,
        rowsToAdd,
        (newId) => this.onInternallyIdChanged(task.flowIdUnwrapped(), newId),
        (id, version) => {
          // do nothing
        }, () => {
          onCallEnd();
        });
    }
  }

  addLabel(taskId: TaskIdentifier, label: string) {

    this.getTask(taskId, task => {
      const currentLabels = task.labels;
      this.flowService.addFlowLabel(task.flowIdUnwrapped(), task.cursorInfo.getOrError("No cursor info").cursorId, task.cursorInfo.getOrError("No cursor info").cursorVersion, label, () => {
      }, () => {
        this.eventBus.taskUpdateFailed(Some(currentLabels), None(), None());
      });
      task.labels.push(label);
      __(task.labels).sortByAlphanumericInPlace(v => v);
      let taskSummary = task.toSummary();
      this.withPersons([taskSummary], persons => {
        this.tasksEventBus.tasksUpdated([], [taskSummary], [], persons);
      });
    });
  }


  removeLabel(taskId: TaskIdentifier, label: string) {
    this.getTask(taskId, task => {
      const currentLabels = task.labels;
      this.flowService.removeFlowLabel(task.flowIdUnwrapped(), task.cursorInfo.getOrError("No cursor info").cursorId, task.cursorInfo.getOrError("No cursor info").cursorVersion, label, () => {
      }, () => {
        this.eventBus.taskUpdateFailed(Some(currentLabels), None(), None());
      });

      const index = task.labels.indexOf(label);
      if(index >= 0) {
        task.labels.splice(index, 1);
      }
      let taskSummary = task.toSummary();
      this.withPersons([taskSummary], persons => {
        this.tasksEventBus.tasksUpdated([], [taskSummary], [], persons);
      });
    });
  }


  private withPersons(tasks: Array<TaskModelSummary>, callback: (persons: Array<PersonSummary>) => void) {
    this.personsSharedService.findPersonBasicInfo(___(tasks).filter(t => t.personsAssignedUnwrapped().length > 0).flatMap(t => t.personsAssignedUnwrapped()).value(),
      (data: { [personId: string]: BasicPersonInfo }) => {
      const persons = values(data).map((p: BasicPersonInfo) => p.toPersonSummary());
      callback(persons);
    });
  }

  submitForm(taskId: TaskIdentifier, edgeId: ProcessEdgeId) {

    this.getTask(taskId, task => {

      const tryToSubmitForm = () => this.tryToSubmitForm(new Date().getTime(), taskId, edgeId,
        (id: AnyFlowId, version: AggregateVersion) => {
          this.tasksEventBus.taskSubmitted(id, task.nodeId);
        },
        (id: AnyFlowId, version: AggregateVersion) => {
          this.tasksEventBus.taskSubmitted(id, task.nodeId);
        }
      );


      if (!task.completed.isDefined()) {
        if (task.started.isEmpty() && task.personsAssigned.length === 0) {
          this.flowService.assignSelfAndStartTask(task.flowIdUnwrapped(), task.roleId, task.cursorInfo.get().cursorId, task.cursorInfo.get().cursorVersion, () => {
            tryToSubmitForm();
          }, () => {
            this.eventBus.taskUpdateFailed(None(), None(), None());
          });
        } else if (task.started.isEmpty()) {
          this.flowService.startTask(task.flowIdUnwrapped(), task.cursorInfo.get().cursorId, task.cursorInfo.get().cursorVersion, () => {
            tryToSubmitForm();
          }, () => {
            this.eventBus.taskUpdateFailed(None(), None(), None());
          });
        } else {
          tryToSubmitForm();
        }
      }
    });

    // }
  }


  tryToSubmitForm(timestamp: number, taskId: TaskIdentifier, edgeId: ProcessEdgeId, onCreateSuccess: (id: AnyFlowId, version: AggregateVersion) => void,
                  onUpdateSuccess: (id: AnyFlowId, version: AggregateVersion) => void) {
    if (this.fillFormPending) {
      this.fillFormQueue.push(() => this.tryToSubmitForm(timestamp, taskId, edgeId, onCreateSuccess, onUpdateSuccess));
    } else {

      this.fillFormPending = true;
      this.getTask(taskId, task => {
        toastr.clearMessagesWithTag(Typed.value(task.flowId).id);

        if (task.variablesToEvaluate.length > 0) {
          this.submitFormPending = Some(new PendingFormSubmit(timestamp, taskId, edgeId, onCreateSuccess, onUpdateSuccess));
          this.submitFormPendingTimeout = mySetTimeout(() => {
            if (this.submitFormPending.exists(s => new Date().getTime() - s.timestamp > 9000)) {
              toastr.warning("Cannot submit form while waiting for form fields to update!");
            }
          }, 10000);
          this.fillFormPending = false;
          this.triggerTaskFromQueue();
        } else {

          this.flowService.submitForm(
            edgeId,
            task.flowIdUnwrapped(),
            task.cursorInfo.get().cursorId,
            task.cursorInfo.get().cursorVersion,
            (newId) => this.onInternallyIdChanged(task.flowIdUnwrapped(), newId),
            (id: AnyFlowId, version: AggregateVersion, response: SubmitFormSuccessResponse) => {
              toastr.clearMessagesWithTag(ToastrCategory.Task);
              onUpdateSuccess(id, version);
            }, (id: AnyFlowId, version: AggregateVersion, response: SubmitFormFailureResponse) => {
              this.eventBus.taskUpdateFailed(None(), None(), None());
              this.eventBus.submitFormValidationFailed();
              toastr.clearMessagesWithTag(ToastrCategory.Task);
              SubmitFormFailedResponseHandler.handle(this.eventBus, task.flowIdUnwrapped(), task.nodeId, response, this.i18nService);
            }, () => {
              toastr.clearMessagesWithTag(ToastrCategory.Task);
              this.eventBus.taskUpdateFailed(None(), None(), None());
            }, () => {
              this.fillFormPending = false;
              this.triggerTaskFromQueue();
            });
        }
      });
    }
  }




  private checkFormRequired(taskId: TaskIdentifier, sectionsInfo: FormSectionInfo[], task: TaskModel,
                            visibleFormSections: FormSectionId[]): Array<string> {

    let missing: Array<string> = [];

    sectionsInfo.filter(sectionInfo => sectionInfo.section.visibilityExpression.isEmpty() || visibleFormSections.filter(vs => vs.id === sectionInfo.section.id.id).length > 0)
      .forEach(sectionInfo => {
        if (sectionInfo.section.forEachVariableName.isDefined()) {

          const contextVariableName = sectionInfo.section.forEachVariableName.get();
          const arrayAny: any = Option.of(task.formVariables.filter(v => v.name === contextVariableName)[0]).flatMap(v => v.unwrappedVariableOption());
          const array = <Option<ArrayVariable<ObjectVariable>>>arrayAny;

          if (array.isDefined()) {
            for (let i = 0; i < array.get().unwrappedValue().length; i++) {
              const obj: ObjectVariable = array.get().unwrappedValue()[i];

              sectionInfo.section.inputElementsRefsUnwrapped().forEach(elementRef => {
                const element = task.inputElementForRef(elementRef);
                if (this.needToBeFilled(elementRef, task, Some(obj.id)) && obj.valueFor(element.variableTypePath.last()).isEmpty()) {
                  missing.push(contextVariableName + "." + element.variableTypePath.last());
                }
              });
            }
          } else {
            if (sectionInfo.section.minimumLength.getOrElse(0) > 0) {
              missing.push(contextVariableName);
            }
          }

        } else {
          sectionInfo.section.inputElementsRefsUnwrapped().forEach(elementRef => {
            const element = task.inputElementForRef(elementRef);
            if (this.needToBeFilled(elementRef, task, None()) && task.formVariables.filter(v => v.name === element.variableTypePath.last() && v.unwrappedVariableOption().exists(v => !v.isEmpty())).length === 0) {
              missing.push(element.variableTypePath.last());
            }
          })
        }
      });

    return missing;
  }
  //TODO expression check
  private needToBeFilled(elementRef: InputElementRef, task: TaskModel, contextObjectId: Option<ObjectId>): boolean {
    const state = task.fieldState(elementRef.elementId, contextObjectId);

    const readOnly = elementRef.readOnly.booleanOrElse(() => state.readOnly);
    const hidden = elementRef.hidden.booleanOrElse(() => state.hidden);
    const required = elementRef.required.booleanOrElse(() => state.required);

    return required && !hidden && !readOnly;
  }

  private checkRequiredActivities(task: TaskModel): Array<string> {
    return task.activities.filter(a => a.required && !__(task.completedActivities).contains(a.id)).map(a => a.name);
  }

  toggleActivity(taskId: TaskIdentifier, activityId: number, checked: boolean) {

    if (this.fillFormPending) {
      this.fillFormQueue.push(() => this.toggleActivity(taskId, activityId, checked));
    } else {
      this.fillFormPending = true;
      this.getTask(taskId, task => {

        this.flowService.toggleActivity(task.flowIdUnwrapped(),
          task.cursorInfo.get().cursorId,
          task.cursorInfo.get().cursorVersion,
          activityId, checked,
          (newId) => this.onInternallyIdChanged(task.flowIdUnwrapped(), newId),
          () => {
            // do nothing
            // this.taskEventBus.activityToggleConfirmed(task, activityId); // TODO do we want to inform others?
          }, () => {
            this.fillFormPending = false;
            this.triggerTaskFromQueue();
          });
      });
    }

  }

  private triggerTaskFromQueue() {
    Option.of(this.fillFormQueue.shift())
      .forEach(task => task());
  }

  changeDeadline(taskId: TaskIdentifier, deadline: LocalDateTime | null) {
    this.getTask(taskId, task => {
      this.flowService.changeTaskDeadline(task.flowIdUnwrapped(), task.nodeId, Option.of(deadline),
        () => this.onInternallyIdChanged(task.flowIdUnwrapped(), task.flowIdUnwrapped()));
    });
  }

  assignCurrentPersonToTask(taskId: TaskIdentifier, roleId: number) {

    this.getTask(taskId, task => {
      const currentPersons = task.personsAssignedUnwrapped();
      this.flowService.assignSelfToRole(taskId.flowIdUnwrapped(), roleId, () => {
        this.task.getOrError("No task").personsAssigned = [Typed.of(this.personId)];
        this.tasksEventBus.tasksMoveConfirmed([taskId]);
      }, () => {
        this.task.forEach(t => this.eventBus.taskUpdateFailed(None(), Some(currentPersons), None()));
      });
      this.tasksEventBus.taskAssigned(taskId, this.personId);

      // this.assignPersonToTask(PersonId.of(this.personId), flowId, roleId, nodeId);
    });
  }

  startTask(taskId: TaskIdentifier) {
    this.getTask(taskId, task => {
      const cursor = this.taskInfo().cursorInfo.get();
      const currentStarted = task.started;
      task.started = Some(LocalDateTime.now());
      this.flowService.startTask(taskId.flowIdUnwrapped(), cursor.cursorId, this.taskInfo().cursorInfo.get().cursorVersion, () => {
        this.tasksEventBus.tasksMoveConfirmed([taskId]);
      }, () => {
        this.task.forEach( t => {
          t.started = None();
          this.eventBus.taskUpdateFailed(None(), None(), Some(currentStarted));
        });
      });
      this.eventBus.taskStarted(taskId);
    });

  }

  stopTask(taskId: TaskIdentifier) {
    this.getTask(taskId, task => {
      const cursor = this.taskInfo().cursorInfo.get();
      const currentStarted = task.started;
      task.started = None();
      this.flowService.stopTask(taskId.flowIdUnwrapped(), cursor.cursorId, this.taskInfo().cursorInfo.get().cursorVersion, () => {
        this.tasksEventBus.tasksMoveConfirmed([taskId]);
      }, () => {
        this.task.forEach( t => {
          t.started = None();
          this.eventBus.taskUpdateFailed(None(), None(), Some(currentStarted));
        });
      });
      this.eventBus.taskStopped(taskId);
    });

  }

  cancelFlow(taskId: TaskIdentifier) {
    const updatedTaskId = this.getUpdatedTaskId(taskId);
    const cursor = this.taskInfo().cursorInfo.get();
    this.flowsSharedService.cancelFlow(updatedTaskId.flowIdUnwrapped(), cursor.cursorId, cursor.cursorVersion, () => {
      this.tasksEventBus.tasksMoveConfirmed([updatedTaskId]);
    }, () => {
      this.task.forEach( t => this.eventBus.taskUpdateFailed(None(), None(), None()));
    });
  }


  addPersonToTask(personId: AnyPersonId, taskId: TaskIdentifier, roleId: number) {
    this.getTask(taskId, task => {
      const currentPersons = task.personsAssignedUnwrapped();
      const newPersons = currentPersons.slice();
      newPersons.push(personId);
      this.flowService.assignPersonToRole(taskId.flowIdUnwrapped(), roleId, personId, () => {
        task.personsAssigned = newPersons.map(p => Typed.of(p));
        this.tasksEventBus.tasksMoveConfirmed([taskId]);
      }, () => {
        task.personsAssigned = currentPersons.map(p => Typed.of(p));
        this.task.forEach( t => this.eventBus.taskUpdateFailed(None(), Some(currentPersons), None()));
      });

      this.tasksEventBus.taskAssigned(taskId, personId);
    });
  }


  unassignPersonFromTask(taskId: TaskIdentifier, roleId: number, personId: AnyPersonId) {
    this.getTask(taskId, task => {
      const currentPersons = task.personsAssignedUnwrapped();
      const newPersons = currentPersons.filter(p => !AnyPersonIdHelper.equals(p, personId));
      this.flowService.unassignPersonsFromRole(taskId.flowIdUnwrapped(), roleId, [personId], () => {
        task.personsAssigned = newPersons.map(p => Typed.of(p));
        this.tasksEventBus.tasksMoveConfirmed([taskId]);
      }, () => {
        task.personsAssigned = currentPersons.map(p => Typed.of(p));
        this.task.forEach( t => this.eventBus.taskUpdateFailed(None(), Some(currentPersons), None()));
      });

      this.tasksEventBus.taskUnassigned(taskId, personId);
    })
  }

  assignPersonToTask(personId: AnyPersonId, taskId: TaskIdentifier, roleId: number) {
    this.getTask(taskId, task => {
      const currentPersons = task.personsAssignedUnwrapped();
      this.flowService.assignPersonToRole(taskId.flowIdUnwrapped(), roleId, personId, () => {
        this.tasksEventBus.tasksMoveConfirmed([taskId]);
      }, () => {
        this.task.forEach( t => this.eventBus.taskUpdateFailed(None(), Some(currentPersons), None()));
      });


      this.tasksEventBus.taskAssigned(taskId, personId);
    });
  }

  redirectTask(taskId: TaskIdentifier, edgeId: ProcessEdgeId, commentOption: Option<CommentViewModel>) {
    this.getTask(taskId, task => {

      const processFlowCommentOption = commentOption.map(newComment => {
        const currentPersonId = PersonId.of(this.personId);
        const nodeIdOption = Some(task.nodeId);
        const commentAuthor = new CommentPersonInfo(currentPersonId, this.userFirstName,
          this.userLastName, Some(this.userEmail));

        return new ProcessFlowComment(newComment.commentId, Option.of(newComment.authorNode).filter(p => p.isPerson()).map(p => Typed.of(p.asPerson())),
          Some(commentAuthor), nodeIdOption, newComment.commentText,
          newComment.attachments, newComment.extraRecipients.map(p => Typed.of(p)), [], false, LocalDateTime.now());
      });
      this.flowService.redirectAction(task.flowIdUnwrapped(), task.cursorInfo.get().cursorId, task.cursorInfo.get().cursorVersion, edgeId,
        processFlowCommentOption, () => {
          this.tasksEventBus.taskRedirected(task.flowIdUnwrapped(), task.nodeId);
        }, () => {
          this.eventBus.taskUpdateFailed(None(), None(), None());
        });

    });

  }


  pullTask(taskId: TaskIdentifier, edgeId: ProcessEdgeId, cursorId: FlowCursorId, commentOption: Option<CommentViewModel>) {
    this.getTask(taskId, task => {

      const processFlowCommentOption = commentOption.map(newComment => {
        const currentPersonId = PersonId.of(this.personId);
        const nodeIdOption = Some(task.nodeId);
        const commentAuthor = new CommentPersonInfo(currentPersonId, this.userFirstName,
          this.userLastName, Some(this.userEmail));

        return new ProcessFlowComment(newComment.commentId, Option.of(newComment.authorNode).filter(p => p.isPerson()).map(p => Typed.of(p.asPerson())),
          Some(commentAuthor), nodeIdOption, newComment.commentText,
          newComment.attachments, newComment.extraRecipients.map(p => Typed.of(p)), [], false, LocalDateTime.now());
      });
      this.flowService.pullAction(task.flowIdUnwrapped(), cursorId, edgeId,
        processFlowCommentOption, () => {
          this.tasksEventBus.taskRedirected(task.flowIdUnwrapped(), task.nodeId);
        }, () => {
          this.eventBus.taskUpdateFailed(None(), None(), None());
        });

    });

  }

  saveAsWorkingCopy(taskId: TaskIdentifier, onSuccess: () => void) {
    this.getTask(taskId, task => {
      if(task.flowCode.length > 0) {
        throw new Error("Cannot save as draft for a task of started flow");
      } else {
        this.flowService.markFlowAsWorking(taskId.flowIdUnwrapped(), () => {
          onSuccess();
        });
      }
    });
  }

}
