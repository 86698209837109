import {
  ___,
  AnyFlowId,
  FormElementRefId,
  global,
  i18n,
  LocalDate,
  LocalDateTime,
  LocalTime,
  ObjectId,
  Option,
  toastr,
  ToastrCategory,
  Typed
} from "@utils";
import {ProcessNodeId, StringVariableValidationType, VariableTypePath} from "@shared-model";
import {FormSectionRefId, I18nService} from "@shared";
import {TaskEventBus} from "../TaskEventBus";

export interface SubmitFormResponse {
    className(): string;
    isSuccess(): boolean;
    translate(): Array<string>;
  }

  export class SubmitFormSuccessResponse implements SubmitFormResponse {

    constructor(readonly newFlowCode: Option<string>) {}

    static copy(other: SubmitFormSuccessResponse) {
      return new SubmitFormSuccessResponse(Option.copy(other.newFlowCode));
    }

    static className: string = "SubmitFormSuccessResponse";

    className(): string {
      return SubmitFormSuccessResponse.className;
    }

    isSuccess(): boolean {
      return true
    };

    translate(): Array<string> { return [] }
  }

  export class SubmitFormFailureResponse implements SubmitFormResponse {

    constructor(readonly errors: Array<Typed<FormValidationError>>) {}

    static copy(other: SubmitFormFailureResponse) {
      return new SubmitFormFailureResponse(other.errors.map(FormValidationErrorFactory.copyTyped));
    }

    static className: string = "SubmitFormFailureResponse";

    className(): string {
      return SubmitFormFailureResponse.className;
    }

    errorsUnwrapped(): Array<FormValidationError> {
      return this.errors.map(e => Typed.value(e));
    }

    isSuccess(): boolean {
      return false
    };

    translate(): Array<string> { return this.errors.map(e => (Typed.value(e)).translate()) }
  }

  export interface FormValidationError {
    className(): string;
    translate(): string;
  }

  export class FormSubmitExpressionValidationError implements FormValidationError {
    static className: string = "FormSubmitExpressionValidationError";

    className() {
      return FormSubmitExpressionValidationError.className;
    }

    constructor(readonly error: string) {}

    translate(): string {
      return i18n(FormSubmitExpressionValidationError.label) + ": " + this.error;
    }

    static label: string = "formValidationError_Submit_ExpressionValidation"
  }

  export class FormSubmitValidationError implements FormValidationError {
    static className: string = "FormSubmitValidationError";

    className() {
      return FormSubmitValidationError.className;
    }

    constructor(readonly error: string) {}

    translate(): string {
      return this.error;
    }
  }

  export class FormSubmitMessageExpressionValidationError implements FormValidationError {
    static className: string = "FormSubmitMessageExpressionValidationError";

    className() {
      return FormSubmitMessageExpressionValidationError.className;
    }

    constructor(readonly error: string) {}

    translate(): string {
      return i18n(FormSubmitMessageExpressionValidationError.label)+": "+this.error;
    }

    static label: string = "formValidationError_Submit_MessageExpressionValidation"
  }

  export class FormSubmitMessageTypeValidationError implements FormValidationError {
    static className: string = "FormSubmitMessageTypeValidationError";

    className() {
      return FormSubmitMessageTypeValidationError.className;
    }

    constructor() {}

    translate(): string {
      return i18n(FormSubmitMessageTypeValidationError.label);
    }

    static label: string = "formValidationError_Submit_MessageTypeValidation"
  }

  export class FormSubmitUnsupportedMessageTypeValidationError implements FormValidationError {
    static className: string = "FormSubmitUnsupportedMessageTypeValidationError";

    className() {
      return FormSubmitUnsupportedMessageTypeValidationError.className;
    }

    constructor() {}

    translate(): string {
      return i18n(FormSubmitUnsupportedMessageTypeValidationError.label);
    }

    static label: string = "formValidationError_Submit_UnsupportedMessageType"
  }

  export class FormSubmitUnsupportedValidationTypeValidationError implements FormValidationError {
    static className: string = "FormSubmitUnsupportedValidationTypeValidationError";

    className() {
      return FormSubmitUnsupportedValidationTypeValidationError.className;
    }

    constructor() {}

    translate(): string {
      return i18n(FormSubmitUnsupportedValidationTypeValidationError.label);
    }

    static label: string = "formValidationError_Submit_UnsupportedValidationType"
  }

  export class FormRequiredActionsValidationError implements FormValidationError {
    static className: string = "FormRequiredActionsValidationError";

    className() {
      return FormRequiredActionsValidationError.className;
    }

    constructor() {}

    translate(): string {
      return i18n(FormRequiredActionsValidationError.label);
    }

    static label: string = "formValidationError_Action_RequiredActions"
  }

  export class VariableInvalidTypeValidationError implements VariableValidationError {
    static className: string = "VariableInvalidTypeValidationError";

    className() {
      return VariableInvalidTypeValidationError.className;
    }

    constructor(readonly variableName: string,
                readonly invalidType: string,
                readonly requiredType: string) {}

    translate(): string {
      return i18n(VariableInvalidTypeValidationError.label,{"invalidType": this.invalidType, "requiredType": this.requiredType, "variableName": this.variableName});
    }

    translateWithLabel(label: string): string {
      return label + ": " + this.translate();
    }

    static label: string = "formValidationError_Element_InvalidType"
  }

  export class VariableInvalidValueValidationError implements VariableValidationError {
    static className: string = "VariableInvalidValueValidationError";

    className() {
      return VariableInvalidValueValidationError.className;
    }

    constructor(readonly value: string,
                readonly variableTypePath: VariableTypePath) {}

    translate(): string {
      return i18n(VariableInvalidValueValidationError.label,
        {"value": this.value, "variableTypePath": this.variableTypePath});
    }

    translateWithLabel(label: string): string {
      return label + ": " + this.translate();
    }

    static label: string = "formValidationError_Element_InvalidType"
  }

  export class VariableNoStateInitializedValidationError implements VariableValidationError {
    static className: string = "VariableNoStateInitializedValidationError";

    className() {
      return VariableNoStateInitializedValidationError.className;
    }

    constructor() {}

    translate(): string {
      return i18n(VariableNoStateInitializedValidationError.label);
    }

    translateWithLabel(label: string): string {
      return label + ": " + this.translate();
    }

    static label: string = "formValidationError_Element_NoStateInitialized"
  }

  export class FormVariableNotAvailableValidationError implements FormValidationError {
    static className: string = "FormVariableNotAvailableValidationError";

    className() {
      return FormVariableNotAvailableValidationError.className;
    }

    constructor(readonly variableName: string) {}

    translate(): string {
      return i18n(FormVariableNotAvailableValidationError.label, {"variableName": this.variableName});
    }

    static label: string = "formValidationError_Variable_NotAvailable"
  }

  export class FormSectionValidationError implements FormValidationError {
    static className: string = "FormSectionValidationError";

    className() {
      return FormSectionValidationError.className;
    }

    constructor(readonly sectionRefId: FormSectionRefId,
                readonly error: Typed<SectionValidationError>,
                readonly label: string) {}

    translate(): string {
      return Typed.value(this.error).translateWithLabel(this.label);
    }

  }

  export interface SectionValidationError {
    className(): string;
    translate(): string;
    translateWithLabel(label: string): string;
  }

  export class FormSectionNotEnoughEntriesValidationError implements SectionValidationError {
    static className: string = "FormSectionNotEnoughEntriesValidationError";

    className() {
      return FormSectionNotEnoughEntriesValidationError.className;
    }

    constructor(readonly minimumEntries: number) {}

    translate(): string {
      return i18n(FormSectionNotEnoughEntriesValidationError.label, {"min": this.minimumEntries});
    }

    translateWithLabel(label: string): string {
      return label + ": " + this.translate();
    }

    static label: string = "formValidationError_Section_NotEnoughEntries"
  }

  export class FormSectionEntriesLimitExceededValidationError implements SectionValidationError {
    static className: string = "FormSectionEntriesLimitExceededValidationError";

    className() {
      return FormSectionEntriesLimitExceededValidationError.className;
    }

    constructor(readonly maximumEntries: number) {}

    translate(): string {
      return i18n(FormSectionEntriesLimitExceededValidationError.label, {"max": this.maximumEntries});
    }

    translateWithLabel(label: string): string {
      return label + ": " + this.translate();
    }

    static label: string = "formValidationError_Section_EntriesLimitExceeded"
  }

  export class FormElementValidationError implements FormValidationError {
    static className: string = "FormElementValidationError";

    className() {
      return FormElementValidationError.className;
    }

    constructor(readonly elementRefId: FormElementRefId,
                readonly error: Typed<VariableValidationError>,
                readonly contextObjectId: Option<ObjectId>,
                readonly label: string) {}

    translate(): string {
      return Typed.value(this.error).translateWithLabel(this.label);
    }
  }

  export interface VariableValidationError {
    className(): string;
    translate(): string;
    translateWithLabel(variableLabel: string): string;
  }

  export class VariableNotMatchTypeValidationError implements VariableValidationError {
    static className: string = "VariableNotMatchTypeValidationError";

    className(): string { return StringVariablePatternValidationError.className }

    static label: string = "formValidationError_Variable_NotMatchTypeValidationError";

    translate(): string {
      return i18n("formValidationError_Variable_NotMatchTypeValidationError_Details", {"expectedTypeName": this.expectedTypeName, "variableTypeName": this.variableTypeName})
    }

    translateWithLabel(label: string): string {
      return label + ": " + this.translate();
    }

    constructor(readonly expectedTypeName: string, readonly variableTypeName: string) {}
  }

  export class StringVariablePatternValidationError implements VariableValidationError {
    static className: string = "StringVariablePatternValidationError";

    className(): string { return StringVariablePatternValidationError.className }

    static label: string = "formValidationError_Variable_String_Pattern";

    translate(): string {
      switch(this.validationType.name) {
        case StringVariableValidationType.PeselType.name: return i18n("formValidationError_Variable_String_Pattern_PeselType");
        case StringVariableValidationType.PostalCodeType.name: return i18n("formValidationError_Variable_String_Pattern_PostalCodeType");
        case StringVariableValidationType.EmailType.name: return i18n("formValidationError_Variable_String_Pattern_EmailType");
        case StringVariableValidationType.NIPCodeType.name: return i18n("formValidationError_Variable_String_Pattern_NIPCodeType");
        case StringVariableValidationType.BankAccountNumberType.name: return i18n("formValidationError_Variable_String_Pattern_BankAccountNumberType");
        case StringVariableValidationType.AnyValidationType.name: return i18n(StringVariablePatternValidationError.label, {"patternType": this.validationType.name});
        case StringVariableValidationType.CustomType.name: return i18n(StringVariablePatternValidationError.label, {"patternType": this.validationType.name});
        default : throw new Error("Incorrect validationType")
      }
    }

    translateWithLabel(label: string): string {
      return label + ": " + this.translate();
    }

    constructor(readonly validationType: StringVariableValidationType) {}
  }

  export class NumberVariableMinValueValidationError implements VariableValidationError {
    static className: string = "NumberVariableMinValueValidationError";

    className(): string { return NumberVariableMinValueValidationError.className }

    static label: string = "formValidationError_Variable_Number_Min";

    translate(): string {return i18n(NumberVariableMinValueValidationError.label, {"min": this.min})}

    translateWithLabel(label: string): string {
      return label + ": " + this.translate();
    }

    constructor(readonly min: number) {}
  }

  export class NumberVariableMaxValueValidationError implements VariableValidationError {
    static className: string = "NumberVariableMaxValueValidationError";

    className(): string { return NumberVariableMaxValueValidationError.className }

    static label: string = "formValidationError_Variable_Number_Max";

    translate(): string {return i18n(NumberVariableMaxValueValidationError.label, {"max": this.max})}


    translateWithLabel(label: string): string {
      return label + ": " + this.translate();
    }

    constructor(readonly max: number) {}
  }

  export class DateVariableMinValueValidationError implements VariableValidationError {
    static className: string = "DateVariableMinValueValidationError";

    className(): string { return DateVariableMinValueValidationError.className }

    static label: string = "formValidationError_Variable_Date_Min";

    translate(): string { return i18n(DateVariableMinValueValidationError.label, {"min": this.min.formatted()})}

    translateWithLabel(label: string): string {
      return label + ": " + this.translate();
    }

    constructor(readonly min: LocalDate) {}
  }

  export class DateVariableMinEvaluationValidationError implements VariableValidationError {
    static className: string = "DateVariableMinEvaluationValidationError";

    className(): string { return DateVariableMinEvaluationValidationError.className }

    static label: string = "formValidationError_Variable_Date_MinEvaluation";

    translate(): string {return i18n(DateVariableMinEvaluationValidationError.label, {"message": this.message})}

    translateWithLabel(label: string): string {
      return label + ": " + this.translate();
    }

    constructor(readonly message: string) {}
  }

  export class DateVariableMaxValueValidationError implements VariableValidationError {
    static className: string = "DateVariableMaxValueValidationError";

    className(): string { return DateVariableMaxValueValidationError.className }

    static label: string = "formValidationError_Variable_Date_Max";

    translate(): string { return i18n(DateVariableMaxValueValidationError.label, {"max": this.max.formatted()})}

    translateWithLabel(label: string): string {
      return label + ": " + this.translate();
    }

    constructor(readonly max: LocalDate) {}
  }

  export class DateVariableMaxEvaluationValidationError implements VariableValidationError {
    static className: string = "DateVariableMaxEvaluationValidationError";

    className(): string { return DateVariableMaxEvaluationValidationError.className }

    static label: string = "formValidationError_Variable_Date_MaxEvaluation";

    translate(): string {return i18n(DateVariableMaxEvaluationValidationError.label, {"message": this.message})}

    translateWithLabel(label: string): string {
      return label + ": " + this.translate();
    }

    constructor(readonly message: string) {}
  }

  export class DateTimeVariableMinValueValidationError implements VariableValidationError {
    static className: string = "DateTimeVariableMinValueValidationError";

    className(): string { return DateTimeVariableMinValueValidationError.className }

    static label: string = "formValidationError_Variable_DateTime_Min";

    translate(): string { return i18n(DateTimeVariableMinValueValidationError.label, {"min": global.i18nService.formatMediumDateTime(this.min)})}

    translateWithLabel(label: string): string {
      return label + ": " + this.translate();
    }

    constructor(readonly min: LocalDateTime) {}
  }

  export class DateTimeVariableMinEvaluationValidationError implements VariableValidationError {
    static className: string = "DateTimeVariableMinEvaluationValidationError";

    className(): string { return DateTimeVariableMinEvaluationValidationError.className }

    static label: string = "formValidationError_Variable_DateTime_MinEvaluation";

    translate(): string {return i18n(DateTimeVariableMinEvaluationValidationError.label, {"message": this.message})}

    translateWithLabel(label: string): string {
      return label + ": " + this.translate();
    }

    constructor(readonly message: string) {}
  }

  export class DateTimeVariableMaxValueValidationError implements VariableValidationError {
    static className: string = "DateTimeVariableMaxValueValidationError";

    className(): string { return DateTimeVariableMaxValueValidationError.className }

    static label: string = "formValidationError_Variable_DateTime_Max";

    translate(): string {return i18n(DateTimeVariableMaxValueValidationError.label, {"max": global.i18nService.formatMediumDateTime(this.max)})}

    translateWithLabel(label: string): string {
      return label + ": " + this.translate();
    }

    constructor(readonly max: LocalDateTime) {}
  }

  export class DateTimeVariableMaxEvaluationValidationError implements VariableValidationError {
    static className: string = "DateTimeVariableMaxEvaluationValidationError";

    className(): string { return DateTimeVariableMaxEvaluationValidationError.className }

    static label: string = "formValidationError_Variable_DateTime_MaxEvaluation";

    translate(): string {return i18n(DateTimeVariableMaxEvaluationValidationError.label, {"message": this.message})}

    translateWithLabel(label: string): string {
      return label + ": " + this.translate();
    }

    constructor(readonly message: string) {}
  }

  export class TimeVariableMinValueValidationError implements VariableValidationError {
    static className: string = "TimeVariableMinValueValidationError";

    className(): string { return TimeVariableMinValueValidationError.className }

    static label: string = "formValidationError_Variable_Time_Min";

    translate(): string {return i18n(TimeVariableMinValueValidationError.label, {"min": this.min.formattedToMinutes()})}

    translateWithLabel(label: string): string {
      return label + ": " + this.translate();
    }

    constructor(readonly min: LocalTime) {}
  }

  export class TimeVariableMinEvaluationValidationError implements VariableValidationError {
    static className: string = "TimeVariableMinEvaluationValidationError";

    className(): string { return TimeVariableMinEvaluationValidationError.className }

    static label: string = "formValidationError_Variable_Time_MinEvaluation";

    translate(): string {return i18n(TimeVariableMinEvaluationValidationError.label, {"message": this.message})}

    translateWithLabel(label: string): string {
      return label + ": " + this.translate();
    }

    constructor(readonly message: string) {}
  }

  export class TimeVariableMaxValueValidationError implements VariableValidationError {
    static className: string = "TimeVariableMaxValueValidationError";

    className(): string { return TimeVariableMaxValueValidationError.className }

    static label: string = "formValidationError_Variable_Time_Max";

    translate(): string {return i18n(TimeVariableMaxValueValidationError.label, {"max": this.max.formattedToMinutes()})}

    translateWithLabel(label: string): string {
      return label + ": " + this.translate();
    }

    constructor(readonly max: LocalTime) {}
  }

  export class TimeVariableMaxEvaluationValidationError implements VariableValidationError {
    static className: string = "TimeVariableMaxEvaluationValidationError";

    className(): string { return TimeVariableMaxEvaluationValidationError.className }

    static label: string = "formValidationError_Variable_Time_MaxEvaluation";

    translate(): string {return i18n(TimeVariableMaxEvaluationValidationError.label, {"message": this.message})}

    translateWithLabel(label: string): string {
      return label + ": " + this.translate();
    }

    constructor(readonly message: string) {}
  }

  export class ArrayVariableNotEnoughElementsValidationError implements VariableValidationError {
    static className: string = "ArrayVariableNotEnoughElementsValidationError";

    className(): string { return ArrayVariableNotEnoughElementsValidationError.className }

    static label: string = "formValidationError_Variable_Array_NotEnoughElements";

    translate(): string {return i18n(ArrayVariableNotEnoughElementsValidationError.label, {"min": this.min})}

    translateWithLabel(label: string): string {
      return label + ": " + this.translate();
    }

    constructor(readonly min: number) {}
  }

  export class ArrayVariableElementsLimitExceededValidationError implements VariableValidationError {
    static className: string = "ArrayVariableElementsLimitExceededValidationError";

    className(): string { return ArrayVariableElementsLimitExceededValidationError.className }

    static label: string = "formValidationError_Variable_Array_ElementsLimitExceeded";

    translate(): string {return i18n(ArrayVariableElementsLimitExceededValidationError.label, {"max": this.max})}

    translateWithLabel(label: string): string {
      return label + ": " + this.translate();
    }

    constructor(readonly max: number) {}
  }

  export class ObjectVariableUnknownFieldValidationError implements VariableValidationError {
    static className: string = "ObjectVariableUnknownFieldValidationError";

    className(): string { return ObjectVariableUnknownFieldValidationError.className }

    static label: string = "formValidationError_Variable_Object_UnknownField";

    translate(): string {return i18n(ObjectVariableUnknownFieldValidationError.label, {"fieldName": this.fieldName, "fieldType": this.fieldType})}

    translateWithLabel(label: string): string {
      return label + ": " + this.translate();
    }

    constructor(readonly fieldName: string, readonly fieldType: string) {}
  }

  export class VariableRequiredMissingValueValidationError implements VariableValidationError {
    static className: string = "VariableRequiredMissingValueValidationError";

    className() {
      return VariableRequiredMissingValueValidationError.className;
    }

    constructor(readonly sectionRefId: FormSectionRefId) {}

    translate(): string {
      return i18n(VariableRequiredMissingValueValidationError.label);
    }

    translateWithLabel(label: string): string {
      return label + ": " + this.translate();
    }

    static label: string = "formValidationError_Variable_MissingRequiredValueForVariable"
  }

  // Factories

  export class SubmitFormResponseFactory {

    static copy(response: SubmitFormResponse): SubmitFormResponse {
      return SubmitFormResponseFactory.copyByType(response, response.className());
    }

    static copyTyped(response: Typed<SubmitFormResponse>): Typed<SubmitFormResponse> {
      return Typed.of(SubmitFormResponseFactory.copyByType(Typed.value(response), Typed.className(response)));
    }

    static copyByType(response: SubmitFormResponse, className: string): SubmitFormResponse {
      switch (className) {
        case SubmitFormSuccessResponse.className:
          return new SubmitFormSuccessResponse((<SubmitFormSuccessResponse>response).newFlowCode);
        case SubmitFormFailureResponse.className:
          return new SubmitFormFailureResponse((<SubmitFormFailureResponse>response).errors.map(FormValidationErrorFactory.copyTyped));
        default:
          throw new Error("Unsupported response class: " + className + ", response: " + JSON.stringify(response));
      }
    }
  }

  export class FormValidationErrorFactory {

    static copy(response: FormValidationError): FormValidationError {
      return FormValidationErrorFactory.copyByType(response, response.className());
    }

    static copyTyped(response: Typed<FormValidationError>): Typed<FormValidationError> {
      return Typed.of(FormValidationErrorFactory.copyByType(Typed.value(response), Typed.className(response)));
    }

    static copyByType(response: FormValidationError, className: string): FormValidationError {
      switch (className) {
        case FormSectionValidationError.className :
          return new FormSectionValidationError(
            (<FormSectionValidationError>response).sectionRefId,
            SectionValidationErrorFactory.copyTyped((<FormElementValidationError>response).error),
            (<FormSectionValidationError>response).label
          );
        case FormElementValidationError.className :
          return new FormElementValidationError(
            (<FormElementValidationError>response).elementRefId,
            VariableValidationErrorFactory.copyTyped((<FormElementValidationError>response).error),
            Option.copy((<FormElementValidationError>response).contextObjectId, ObjectId.copy),
            (<FormSectionValidationError>response).label
          );
        case FormSubmitExpressionValidationError.className :
          return new FormSubmitExpressionValidationError(
            (<FormSubmitExpressionValidationError>response).error
          );
        case FormSubmitValidationError.className :
          return new FormSubmitValidationError(
            (<FormSubmitValidationError>response).error
          );
        case FormSubmitMessageExpressionValidationError.className :
          return new FormSubmitMessageExpressionValidationError((<FormSubmitMessageExpressionValidationError>response).error);
        case FormSubmitMessageTypeValidationError.className :
          return new FormSubmitMessageTypeValidationError();
        case FormSubmitUnsupportedMessageTypeValidationError.className :
          return new FormSubmitUnsupportedMessageTypeValidationError();
        case FormSubmitUnsupportedValidationTypeValidationError.className :
          return new FormSubmitUnsupportedValidationTypeValidationError();
        case FormRequiredActionsValidationError.className :
          return new FormRequiredActionsValidationError();
        case VariableInvalidTypeValidationError.className :
          return new VariableInvalidTypeValidationError(
            (<VariableInvalidTypeValidationError>response).variableName,
            (<VariableInvalidTypeValidationError>response).invalidType,
            (<VariableInvalidTypeValidationError>response).requiredType
          );
        case VariableInvalidValueValidationError.className :
          return new VariableInvalidValueValidationError(
            (<VariableInvalidValueValidationError>response).value,
            VariableTypePath.copy((<VariableInvalidValueValidationError>response).variableTypePath)
          );
        case VariableNoStateInitializedValidationError.className :
          return new VariableNoStateInitializedValidationError();

        case FormVariableNotAvailableValidationError.className :
          return new FormVariableNotAvailableValidationError(
            (<FormVariableNotAvailableValidationError>response).variableName
          );

        default:
          throw new Error("Unsupported form error class: " + className + ", response: " + JSON.stringify(response));
      }
    }
  }

  export class SectionValidationErrorFactory {

    static copy(sectionValidationError: VariableValidationError): SectionValidationError {
      return SectionValidationErrorFactory.copyByType(sectionValidationError, sectionValidationError.className());
    }

    static copyTyped(sectionValidationError: Typed<VariableValidationError>): Typed<SectionValidationError> {
      return Typed.of(SectionValidationErrorFactory.copyByType(Typed.value(sectionValidationError), Typed.className(sectionValidationError)));
    }

    static copyByType(sectionValidationError: SectionValidationError, className: string): SectionValidationError {
      switch (className) {
        case FormSectionNotEnoughEntriesValidationError.className :
          return new FormSectionNotEnoughEntriesValidationError(
            (<FormSectionNotEnoughEntriesValidationError>sectionValidationError).minimumEntries
          );

        case FormSectionEntriesLimitExceededValidationError.className :
          return new FormSectionEntriesLimitExceededValidationError(
            (<FormSectionEntriesLimitExceededValidationError>sectionValidationError).maximumEntries
          );

        default:
          throw new Error("Unsupported section validation class: " + className + ", response: " + JSON.stringify(sectionValidationError));
      }
    }
  }

  export class VariableValidationErrorFactory {

    static copy(variableValidationError: VariableValidationError): VariableValidationError {
      return VariableValidationErrorFactory.copyByType(variableValidationError, variableValidationError.className());
    }

    static copyTyped(variableValidationError: Typed<VariableValidationError>): Typed<VariableValidationError> {
      return Typed.of(VariableValidationErrorFactory.copyByType(Typed.value(variableValidationError), Typed.className(variableValidationError)));
    }

    static copyByType(variableValidationError: VariableValidationError, className: string): VariableValidationError {
      switch (className) {

        case VariableNotMatchTypeValidationError.className : return new VariableNotMatchTypeValidationError(
          (<VariableNotMatchTypeValidationError>variableValidationError).expectedTypeName,
          (<VariableNotMatchTypeValidationError>variableValidationError).variableTypeName
        );

        case StringVariablePatternValidationError.className : return new StringVariablePatternValidationError(
          StringVariableValidationType.copy(<StringVariableValidationType>(<StringVariablePatternValidationError>variableValidationError).validationType)
        );
        case NumberVariableMinValueValidationError.className : return new NumberVariableMinValueValidationError(
          (<NumberVariableMinValueValidationError>variableValidationError).min
        );
        case NumberVariableMaxValueValidationError.className : return new NumberVariableMaxValueValidationError(
          (<NumberVariableMaxValueValidationError>variableValidationError).max
        );
        case DateVariableMinValueValidationError.className : return new DateVariableMinValueValidationError(
          LocalDate.copy((<DateVariableMinValueValidationError>variableValidationError).min)
        );
        case DateVariableMinEvaluationValidationError.className : return new DateVariableMinEvaluationValidationError(
          (<DateVariableMinEvaluationValidationError>variableValidationError).message
        );
        case DateVariableMaxValueValidationError.className : return new DateVariableMaxValueValidationError(
          LocalDate.copy((<DateVariableMaxValueValidationError>variableValidationError).max)
        );
        case DateVariableMaxEvaluationValidationError.className : return new DateVariableMaxEvaluationValidationError(
          (<DateVariableMaxEvaluationValidationError>variableValidationError).message
        );
        case DateTimeVariableMinValueValidationError.className : return new DateTimeVariableMinValueValidationError(
          LocalDateTime.copy((<DateTimeVariableMinValueValidationError>variableValidationError).min)
        );
        case DateTimeVariableMinEvaluationValidationError.className : return new DateTimeVariableMinEvaluationValidationError(
          (<DateTimeVariableMinEvaluationValidationError>variableValidationError).message
        );
        case DateTimeVariableMaxValueValidationError.className : return new DateTimeVariableMaxValueValidationError(
          LocalDateTime.copy((<DateTimeVariableMaxValueValidationError>variableValidationError).max)
        );
        case DateTimeVariableMaxEvaluationValidationError.className : return new DateTimeVariableMaxEvaluationValidationError(
          (<DateTimeVariableMaxEvaluationValidationError>variableValidationError).message
        );
        case TimeVariableMinValueValidationError.className : return new TimeVariableMinValueValidationError(
          LocalTime.copy((<TimeVariableMinValueValidationError>variableValidationError).min)
        );
        case TimeVariableMinEvaluationValidationError.className : return new TimeVariableMinEvaluationValidationError(
          (<TimeVariableMinEvaluationValidationError>variableValidationError).message
        );
        case TimeVariableMaxValueValidationError.className : return new TimeVariableMaxValueValidationError(
          LocalTime.copy((<TimeVariableMaxValueValidationError>variableValidationError).max)
        );
        case TimeVariableMaxEvaluationValidationError.className : return new TimeVariableMaxEvaluationValidationError(
          (<TimeVariableMaxEvaluationValidationError>variableValidationError).message
        );
        case ArrayVariableNotEnoughElementsValidationError.className : return new ArrayVariableNotEnoughElementsValidationError(
          (<ArrayVariableNotEnoughElementsValidationError>variableValidationError).min
        );
        case ArrayVariableElementsLimitExceededValidationError.className : return new ArrayVariableElementsLimitExceededValidationError(
          (<ArrayVariableElementsLimitExceededValidationError>variableValidationError).max
        );

        case ObjectVariableUnknownFieldValidationError.className : return new ObjectVariableUnknownFieldValidationError(
          (<ObjectVariableUnknownFieldValidationError>variableValidationError).fieldName,
          (<ObjectVariableUnknownFieldValidationError>variableValidationError).fieldType
        );

        case VariableRequiredMissingValueValidationError.className : return new VariableRequiredMissingValueValidationError(
          (<VariableRequiredMissingValueValidationError>variableValidationError).sectionRefId
        );

        default:
          throw new Error("Unsupported variable validation class: " + className + ", response: " + JSON.stringify(variableValidationError));
      }
    }
  }

  export class SubmitFormFailedResponseHandler {
    static handle(eventBus: TaskEventBus, flowId: AnyFlowId, nodeId: ProcessNodeId, response: SubmitFormFailureResponse,
                  i18nService: I18nService) {

      const allErrors = response.errorsUnwrapped();

      allErrors.forEach(e => {
        switch (e.className()) {
          case FormElementValidationError.className :
            eventBus.inputFieldValidationUpdated(flowId, nodeId, (<FormElementValidationError>e).elementRefId, (<FormElementValidationError>e).contextObjectId,
              Typed.value((<FormElementValidationError>e).error));
            break;
          case FormSectionValidationError.className:
            eventBus.formRepeatableSectionValidationUpdated(
              flowId, nodeId,
              (<FormSectionValidationError>e).sectionRefId,
              Typed.value((<FormSectionValidationError>e).error)
            );
            break;
          case FormRequiredActionsValidationError.className :
            eventBus.activityValidationFailed();
            break;
          default : break;
        }
      });

      const errors = ___(allErrors).map(e => e.translate()).unique().value().join("\n\n");

      if(errors.length > 0) {
        toastr.error(errors, ToastrCategory.Task);
      }
    }
  }
