import {Component, Input, OnInit} from "@angular/core";
import {AlternativeEdgeViewModel, TaskViewModel} from "../../TaskViewModel";

@Component({
  selector: "my-alternative-actions",
  templateUrl: "./alternative-actions.component.html",
})
export class AlternativeActionsComponent implements OnInit {
  get viewModel(): TaskViewModel {
    return this._viewModel;
  }

  @Input() set minimal(minimal: boolean) {
    this.alternativeActionsToShow = minimal ? 0 : 1;
    if (this.initialized) {
      this.updateView();
    }
  };

  @Input({required: true})
  set viewModel(value: TaskViewModel) {
    this._viewModel = value;
    if (this.initialized) {
      this.updateView();
    }
  }

  visibleActions: AlternativeEdgeViewModel[] = [];
  actionsInsideDropdown: AlternativeEdgeViewModel[] = [];

  moreActionsMenuVisible = false;

  private _viewModel!: TaskViewModel;

  alternativeActionsToShow = 1;

  initialized = false;

  ngOnInit() {
    this.updateView();
    this.initialized = true;
  }


  private updateView(): void {
    this.visibleActions = this.viewModel.alternativeActions.slice(0, this.alternativeActionsToShow);
    this.actionsInsideDropdown = this.viewModel.alternativeActions.slice(this.alternativeActionsToShow);
  }
}
