import {
  __,
  AggregateId,
  AggregateVersion,
  AnyFlowId,
  AnyFlowIdHelper,
  AnyInstanceId,
  AnyPersonId, ApplicationId,
  Either,
  FileUri,
  FlowId,
  FormElementId,
  FormElementRefId,
  LocalDateTime,
  ObjectId,
  Option,
  OrganizationNodeId,
  PersonId,
  Typed
} from "@utils";
import {
  FlowAuthorization, FlowPreviewInfo,
  MyCommentServerModel,
  ProcessFlowDetails,
  ProcessFlowDetailsWithPersons,
  TaskModel,
  TaskModelWithPersons
} from "../model/ProcessFlow";
import {
  AnyFlowCommandResponse,
  anyFlowCommandResponseHandler,
  AnyFlowCustomSuccessResponse,
  AssignableTask,
  AuthenticatedHttp,
  BasicPersonInfo,
  BusinessVariable,
  CommandResponse,
  commandResponseHandler,
  CommentViewModel,
  ContextPath,
  ContextVariable, CreateProcessFlowByIdentifiersRouting,
  CreateProcessFlowRouting,
  customAnyFlowCommandResponseHandler,
  customCommandResponseHandler,
  DateTimeVariable,
  DateVariable,
  DepartmentVariable,
  FillAndSubmitTaskFlow,
  FlowAndTasksInfoForUser,
  FlowCursorId,
  FlowCursorVersion,
  FlowImportance,
  FlowUrgency,
  FlowWithCursor,
  GroupVariable,
  PersonVariable,
  PhaseWithStep,
  ProcessEdgeId,
  ProcessFlowComment,
  ProcessNodeId,
  RootVariable,
  StringVariable,
  TaskIdentifier,
  TaskModelSummary
} from "@shared-model";
import {
  AddAttachments,
  AddComment,
  AddCommentRouting,
  AddFlowAuthorization,
  AddFlowLabel,
  AdHocTaskData,
  AnonymizeFlowData,
  ArchivedTasksSearchResult,
  AssignPersonToRoleBySupervisor,
  AssignPersonToRoleRouting,
  AssignSelfAndStartTaskRouting,
  AssignSelfToRoleRouting,
  ChangeComment,
  ChangeFlowRelease,
  ChangeTaskDeadlineRouting,
  CreateFlowCursor,
  DeleteAttachmentRouting,
  DeleteComment,
  DeleteFlowCursor,
  DeletePermanentlyProcessFlow,
  ExecuteActionButtonRouting,
  ExecuteFlowFormulaByManager,
  FieldId,
  FieldWithData,
  FillFlowVariables,
  FillFormFieldsResponse,
  FillFormFieldsRouting,
  FindArchivedTasksForPerson,
  FindAssignableTasks,
  FlowFormulaExecutionFailureResponse,
  FlowFormulaExecutionResponse,
  FlowFormulaExecutionResponseFactory,
  FlowFormulaExecutionSuccessResponse,
  GetAdHocFlowData,
  GetFlowsInfoForUser,
  GetTask,
  MarkFlowAsWorkingRouting,
  MarkTaskAsSeenRouting,
  MarkTaskWaiting,
  MaterializeProcessFlow,
  MoveFlowCursor,
  OverrideColorRouting,
  OverrideImportanceRouting,
  OverrideTaskDeadlineRouting,
  OverrideUrgencyRouting,
  PullActionRouting,
  RedirectActionFromFlow,
  RedirectActionRouting,
  RemoveFlowAuthorization,
  RemoveFlowLabel,
  StartTaskRouting,
  StopTask,
  StopTaskAndUnAssignPersons,
  SubmitFormRouting,
  TerminateProcessFlow,
  ToggleActivity,
  UnAssignPersonsFromRoleRouting,
  UnmarkTaskAsSeenRouting,
  UpdateRepeatSectionLengthRouting
} from "./FlowMessages";
import {FormSectionId, FormSectionRefId, SessionServiceProvider} from "@shared";
import {
  SubmitFormFailureResponse,
  SubmitFormResponse,
  SubmitFormResponseFactory,
  SubmitFormSuccessResponse
} from "../model/ProcessFlowValidationErrors";
import {Injectable} from "@angular/core";


interface AdHocTaskInfo {
  description: string;
  importance: FlowImportance;
  assignee: OrganizationNodeId;
  deadline: LocalDateTime|null;
  taskId: TaskIdentifier;
  flowWithCursor: FlowWithCursor;
}


@Injectable({
  providedIn: 'root',
})
export class FlowService {

  private archivedTaskCache?: ArchivedTasksSearchResult;
  private archivedTaskCacheTimestamp = 0;


  constructor(readonly authenticatedHttp: AuthenticatedHttp,
              readonly sessionServiceProvider: SessionServiceProvider) {}


  createProcessFlowAndGet(processInstanceId: AnyInstanceId, startNodeId: ProcessNodeId, variablesData: Array<RootVariable<BusinessVariable>>,
                          onSuccess: (task: TaskModel, personsFromTask: Array<BasicPersonInfo>) => void,
                          onNotFound: () => void,
                          onError: (error: string) => void) {
    this.authenticatedHttp.post(
      "process-flow/new-and-get-task",
      new CreateProcessFlowRouting(Typed.of(processInstanceId), startNodeId, variablesData),
      (data: Either<string, Option<TaskModelWithPersons>>) => {
        const either = Either.copy(data, l => l, r => Option.copy(r).map(TaskModelWithPersons.copy));
        if(either.isRight())  {
          if(either.getRight().isDefined()) {
            onSuccess(either.getRight().get().task, either.getRight().get().persons);
          } else {
            onNotFound();
          }
        } else {
          onError(either.getLeft());
        }
      });
  }



  createProcessFlowExternal(applicationIdentifier: string, processInstanceIdentifier: string, startNodeIdentifier: string, variablesData: Array<RootVariable<BusinessVariable>>,
                            onSuccess: (id: AnyFlowId, version: AggregateVersion, nodeId: number) => void,
                            onCallEnd: () => void) {
    this.authenticatedHttp.post(
      "process-flow/new-external-anonymously",
      new CreateProcessFlowByIdentifiersRouting(applicationIdentifier, processInstanceIdentifier, startNodeIdentifier, variablesData),
      (data: Typed<AnyFlowCustomSuccessResponse<number>>) => {
        onCallEnd();
        customAnyFlowCommandResponseHandler<ProcessNodeId>(data).onSuccess((flowId: AnyFlowId, aggregateVersion: AggregateVersion, info: ProcessNodeId) => {
          onSuccess(flowId, aggregateVersion, info);
        }).handle();
      }, () => {
        onCallEnd();
      });
  }

  materializeProcessFlow(volatileFlowId: AnyFlowId, onIdChange: (newId: AnyFlowId) => void, onComplete: (flowId: AnyFlowId) => void) {
    if(!AnyFlowIdHelper.isMaterialized(volatileFlowId)) { // aggregate id starts with underscore for temporary, negative ids
      this.authenticatedHttp.post("process-flow/materialize", new MaterializeProcessFlow(Typed.of(volatileFlowId)),
        (data: Typed<AnyFlowCommandResponse>) => {
          anyFlowCommandResponseHandler(data).onSuccess((flowId: AnyFlowId, aggregateVersion: AggregateVersion) => {
            onIdChange(flowId);
            onComplete(flowId);
          }).handle();
        });
    } else {
      onComplete(volatileFlowId);
    }
  }


  toggleActivity(flowId: AnyFlowId, flowCursor: FlowCursorId, flowCursorVersion: FlowCursorVersion, activityId: number, checked: boolean,
                 onIdChange: (newId: AnyFlowId) => void, onSuccess: () => void, onCallEnd: () => void) {
    this.materializeProcessFlow(flowId, onIdChange,(materializedFlowId) => {
      this.authenticatedHttp.post(
        "process-flow/toggle-activity",
        new ToggleActivity(materializedFlowId.toFlowId().toAggregateId(), flowCursor, flowCursorVersion, activityId, checked),
        (data: Typed<CommandResponse>) => {
          onCallEnd();
          commandResponseHandler(data).onSuccess((aggregateId: AggregateId, aggregateVersion: AggregateVersion) => {
            onSuccess();
          }).handle();
        }, () => {
          onCallEnd();
        }
      );
    });
  }


  changeSectionLength(flowId: AnyFlowId, flowCursor: FlowCursorId, flowCursorVersion: FlowCursorVersion, sectionId: FormSectionId, rowsToRemove: Array<ObjectId>, rowsToAdd: Array<ObjectId>,
                      onIdChange: (newId: AnyFlowId) => void,
                      onSuccess: (id: AnyFlowId, version: AggregateVersion) => void, onCallEnd: () => void) {
    this.materializeProcessFlow(flowId, onIdChange, (materializedFlowId) => {
      this.authenticatedHttp.post(
        "process-flow/change-section-length",
        new UpdateRepeatSectionLengthRouting(Typed.of(materializedFlowId), flowCursor, flowCursorVersion, sectionId, rowsToRemove, rowsToAdd),
        (data: Typed<AnyFlowCommandResponse>) => {
          onCallEnd();
          anyFlowCommandResponseHandler(data).onSuccess((flowId: AnyFlowId, aggregateVersion: AggregateVersion) => {
            onSuccess(flowId, aggregateVersion);
          }).handle();
        }, () => {
          onCallEnd();
        });
    });
  }

  fillFormFields(flowId: AnyFlowId, flowCursor: FlowCursorId, flowCursorVersion: FlowCursorVersion,
                 formData: Array<FieldWithData>, fieldsToClear: Array<FieldId>,
                 onIdChange: (newId: AnyFlowId) => void,
                 onSuccess: (id: AnyFlowId, version: AggregateVersion, variablesToEvaluate: Array<ContextPath>) => void,
                 onCallEnd: () => void) {
    this.materializeProcessFlow(flowId, onIdChange,(materializedFlowId) => {
      this.authenticatedHttp.post(
        "process-flow/fill-form-fields",
        new FillFormFieldsRouting(Typed.of(materializedFlowId), flowCursor, flowCursorVersion, formData, fieldsToClear),
        (data: Typed<AnyFlowCustomSuccessResponse<FillFormFieldsResponse>>) => {
          onCallEnd();
          customAnyFlowCommandResponseHandler<FillFormFieldsResponse>(data).onSuccess((flowId: AnyFlowId, aggregateVersion: AggregateVersion, info: FillFormFieldsResponse) => {
            onSuccess(flowId, aggregateVersion, info.variablesToEvaluate.map(ContextPath.copy));
          }).handle();

        }, () => {
          onCallEnd();
        });
    });
  }

  fillFlowVariables(flowId: FlowId, variablesWithData: Array<ContextVariable<BusinessVariable>>,
                    onSuccess: (id: AnyFlowId, version: AggregateVersion) => void) {
    this.authenticatedHttp.post(
      "process-flow/fill-flow-variables",
      new FillFlowVariables(flowId.toAggregateId(), variablesWithData, []),
      (data: Typed<CommandResponse>) => {
        commandResponseHandler(data).onSuccess((aggregateId: AggregateId, aggregateVersion: AggregateVersion) => {
          onSuccess(FlowId.of(aggregateId), aggregateVersion);
        }).handle();
      })
  }

  executeFlowFormulaByManager(flowId: FlowId, expression: string, contextPath: string,
                              onSuccess: (result: Typed<BusinessVariable>, durationMillis: number) => void,
                              onFailure: (result: string, durationMillis: number) => void) {
    this.authenticatedHttp.post(
      "process-flow/execute-flow-formula-by-manager",
      new ExecuteFlowFormulaByManager(flowId.toAggregateId(), expression, contextPath), (response: Typed<FlowFormulaExecutionResponse>) => {
        const responseUnwrapped = Typed.value(FlowFormulaExecutionResponseFactory.copyTyped(response));
        if(responseUnwrapped.isSuccess()) {
          onSuccess((<FlowFormulaExecutionSuccessResponse>responseUnwrapped).variable, (<FlowFormulaExecutionSuccessResponse>responseUnwrapped).durationMillis);
        } else {
          onFailure((<FlowFormulaExecutionFailureResponse>responseUnwrapped).error, (<FlowFormulaExecutionFailureResponse>responseUnwrapped).durationMillis);
        }
      }, (error, data) => {
        onFailure("Error "+error+": " + data, 0);
      })
  }

  fillAndSubmitTaskFlow(flowId: AnyFlowId, flowCursor: FlowCursorId, flowCursorVersion: FlowCursorVersion,
                        description: string, plan: "today" | "tomorrow" | "remaining",
                        assignee: Option<OrganizationNodeId>, deadline: Option<LocalDateTime>, importance: FlowImportance,
                        onSuccess: (id: AnyFlowId, version: AggregateVersion, response: SubmitFormSuccessResponse) => void,
                        onFailure: (id: AnyFlowId, version: AggregateVersion, response: SubmitFormFailureResponse) => void,
                        onError: (message: string) => void) {
    this.authenticatedHttp.post("process-flow/fill-and-submit-task-flow",
      new FillAndSubmitTaskFlow(Typed.of(flowId), flowCursor, flowCursorVersion, description, assignee, deadline, importance), (data: Typed<AnyFlowCustomSuccessResponse<SubmitFormResponse>>) => {
        customCommandResponseHandler<Typed<SubmitFormResponse>>(data).onSuccess((aggregateId: AggregateId, aggregateVersion: AggregateVersion, submitFormResponse: Typed<SubmitFormResponse>) => {
          const response = Typed.value(SubmitFormResponseFactory.copyTyped(submitFormResponse));
          if(response.isSuccess()) {
            onSuccess(flowId, aggregateVersion, <SubmitFormSuccessResponse>response);
          } else {
            onFailure(flowId, aggregateVersion, <SubmitFormFailureResponse>response);
          }
        }).handle();
      }, (status: number, data: any) => {
        onError(data+"");
      });
  }

  submitForm(edgeId: ProcessEdgeId, flowId: AnyFlowId, flowCursor: FlowCursorId, flowCursorVersion: FlowCursorVersion, onIdChange: (newId: AnyFlowId) => void,
             onSuccess: (id: AnyFlowId, version: AggregateVersion, response: SubmitFormSuccessResponse) => void,
             onFailure: (id: AnyFlowId, version: AggregateVersion, response: SubmitFormFailureResponse) => void,
             onError: () => void,
             onCallEnd: () => void) {
    this.materializeProcessFlow(flowId, onIdChange,(materializedFlowId) => {
      this.authenticatedHttp.post(
        "process-flow/submit-form",
        new SubmitFormRouting(Typed.of(materializedFlowId), edgeId, flowCursor, flowCursorVersion),
        (data: Typed<AnyFlowCustomSuccessResponse<SubmitFormResponse>>) => {
          onCallEnd();
          customAnyFlowCommandResponseHandler<Typed<SubmitFormResponse>>(data).onSuccess((flowId: AnyFlowId, aggregateVersion: AggregateVersion, submitFormResponse: Typed<SubmitFormResponse>) => {
            const response = Typed.value(SubmitFormResponseFactory.copyTyped(submitFormResponse));
            if(response.isSuccess()) {
              onSuccess(flowId, aggregateVersion, <SubmitFormSuccessResponse>response);
            } else {
              onFailure(flowId, aggregateVersion, <SubmitFormFailureResponse>response);
            }
          }).onAnyError(onError).handle();
        }, () => {
          onCallEnd();
          onError();
        });
    });
  }

  addAttachments(flowId: AnyFlowId, flowCursor: FlowCursorId, flowCursorVersion: FlowCursorVersion, formSectionRefId: FormSectionRefId,
                 contextObjectId: Option<ObjectId>, fieldRefId: FormElementRefId, filesUris: Array<FileUri>,
                 onIdChange: (newId: AnyFlowId) => void,
                 onSuccess: () => void, onFailure: (exceptions: Array<string>) => void) {
    this.materializeProcessFlow(flowId, onIdChange,(materializedFlowId) => {
      this.authenticatedHttp.post(
        "process-flow/add-attachment",
        new AddAttachments(materializedFlowId.toFlowId().toAggregateId(), flowCursor, flowCursorVersion, formSectionRefId, contextObjectId, fieldRefId, filesUris),
        (data: Typed<CommandResponse>) => {
          commandResponseHandler(data).onSuccess((aggregateId: AggregateId, aggregateVersion: AggregateVersion) => {
            onSuccess();
          }).handle();
        }
      );
    });
  }

  deleteAttachment(flowId: AnyFlowId, flowCursor: FlowCursorId, flowCursorVersion: FlowCursorVersion, formSectionRefId: FormSectionRefId,
                   contextObjectId: Option<ObjectId>, fieldRefId: FormElementRefId, fileUri: FileUri,
                   onIdChange: (newId: AnyFlowId) => void,
                   onSuccess: () => void, onFailure: (exceptions: Array<string>) => void, onCallEnd: () => void) {
    this.materializeProcessFlow(flowId, onIdChange, (materializedFlowId) => {
      this.authenticatedHttp.post(
        "process-flow/delete-attachment",
        new DeleteAttachmentRouting(Typed.of(materializedFlowId), flowCursor, flowCursorVersion, formSectionRefId, contextObjectId, fieldRefId, fileUri),
        (data: Typed<AnyFlowCommandResponse>) => {
          onCallEnd();
          anyFlowCommandResponseHandler(data).onSuccess((flowId: AnyFlowId, aggregateVersion: AggregateVersion) => {
            onSuccess();
          }).handle();
        }, () => {
          onCallEnd();
        }
      );
    });
  }


  addComment(flowId: AnyFlowId, nodeId: Option<number>, commentId: number, commentText: string, attachments: Array<FileUri>, extraRecipients: Array<PersonId>,
             onIdChange: (newId: AnyFlowId) => void, onSuccess: () => void, onError: () => void) {

    this.materializeProcessFlow(flowId, onIdChange,(materializedFlowId) => {
      this.authenticatedHttp.post(
        `process-flow/add-comment`, new AddCommentRouting(Typed.of(materializedFlowId), nodeId, commentId, commentText, attachments, extraRecipients),
        (data: Typed<AnyFlowCommandResponse>) => {
          anyFlowCommandResponseHandler(data).onSuccess((flowId: AnyFlowId, aggregateVersion: AggregateVersion) => {
            onSuccess();
          }).onAnyError(onError).handle();
        }, () => onError()
      );
    });
  }

  changeComment(flowId: AnyFlowId, commentId: number, commentText: string, onSuccess: () => void) {
    this.authenticatedHttp.post(
      `process-flow/change-comment`,
      new ChangeComment(flowId.toFlowId().toAggregateId(), commentId, commentText),
      (data: Typed<CommandResponse>) => {
        commandResponseHandler(data).onSuccess((aggregateId: AggregateId, aggregateVersion: AggregateVersion) => {
          onSuccess();
        }).handle();
      }
    );
  }

  deleteComment(flowId: AnyFlowId, commentId: number, onSuccess: () => void) {
    this.authenticatedHttp.post(
      `process-flow/delete-comment`,
      new DeleteComment(flowId.toFlowId().toAggregateId(), commentId),
      (data: Typed<CommandResponse>) => {
        commandResponseHandler(data).onSuccess((aggregateId: AggregateId, aggregateVersion: AggregateVersion) => {
          onSuccess();
        }).handle();
      }
    );
  }

  moveCursor(flowId: FlowId, cursorId: FlowCursorId, cursorVersion: FlowCursorVersion, toNodeId: ProcessNodeId, phaseWithStep: Option<PhaseWithStep>, onSuccess: ((aggregateId: AggregateId, aggregateVersion: AggregateVersion) => void)) {
    this.authenticatedHttp.post(
      `process-flow/move-cursor`,
      new MoveFlowCursor(flowId.toAggregateId(), cursorId, cursorVersion, toNodeId, phaseWithStep),
      (data: Typed<CommandResponse>) => {
        commandResponseHandler(data).onSuccess((aggregateId: AggregateId, aggregateVersion: AggregateVersion) => {
          onSuccess(aggregateId, aggregateVersion);
        }).handle();
      }
    );
  }

  deleteCursor(flowId: FlowId, cursorId: FlowCursorId, cursorVersion: FlowCursorVersion, onSuccess: ((aggregateId: AggregateId, aggregateVersion: AggregateVersion) => void)) {
    this.authenticatedHttp.post(
      `process-flow/delete-cursor`,
      new DeleteFlowCursor(flowId.toAggregateId(), cursorId, cursorVersion),
      (data: Typed<CommandResponse>) => {
        commandResponseHandler(data).onSuccess((aggregateId: AggregateId, aggregateVersion: AggregateVersion) => {
          onSuccess(aggregateId, aggregateVersion);
        }).handle();
      }
    );
  }


  createCursor(flowId: FlowId, nodeId: ProcessNodeId, phaseWithStep: Option<PhaseWithStep>, onSuccess: ((aggregateId: AggregateId, aggregateVersion: AggregateVersion) => void)) {
    this.authenticatedHttp.post(
      `process-flow/create-cursor`,
      new CreateFlowCursor(flowId.toAggregateId(), nodeId, phaseWithStep),
      (data: Typed<CommandResponse>) => {
        commandResponseHandler(data).onSuccess((aggregateId: AggregateId, aggregateVersion: AggregateVersion) => {
          onSuccess(aggregateId, aggregateVersion);
        }).handle();
      }
    );
  }

  terminateFlow(flowId: FlowId, comment: string, onSuccess: () => void, onFailure: (exceptions: Array<string>) => void) {
    this.authenticatedHttp.post(
      "process-flow/terminate-flow",
      new TerminateProcessFlow(flowId.toAggregateId(), comment),
      (data: Typed<CommandResponse>) => {
        commandResponseHandler(data).onSuccess((aggregateId: AggregateId, aggregateVersion: AggregateVersion) => {
          onSuccess();
        }).handle();
      }
    )
  }


  assignSelfToRole(flowId: AnyFlowId, roleId: number, onSuccess: () => void, onError: () => void) {
    this.authenticatedHttp.post(
      "process-flow/assign-self",
      new AssignSelfToRoleRouting(Typed.of(flowId), roleId),
      (data: Typed<AnyFlowCommandResponse>) => {
        anyFlowCommandResponseHandler(data).onSuccess((flowId: AnyFlowId, aggregateVersion: AggregateVersion) => {
          onSuccess();
        }).onAnyError(onError).handle();
      }, () => onError()
    );
  }


  assignPersonToRole(flowId: AnyFlowId, roleId: number, personId: AnyPersonId, onSuccess: () => void, onError: () => void) {
    this.authenticatedHttp.post(
      "process-flow/assign-person",
      new AssignPersonToRoleRouting(Typed.of(flowId), Typed.of(personId), roleId),
      (data: Typed<AnyFlowCommandResponse>) => {
        anyFlowCommandResponseHandler(data).onSuccess((flowId: AnyFlowId, aggregateVersion: AggregateVersion) => {
          onSuccess();
        }).onAnyError(onError).handle();
      }, () => onError());
  }

  assignPersonToRoleBySupervisor(flowId: FlowId, roleId: number, personId: PersonId, onSuccess: () => void) {
    this.authenticatedHttp.post(
      "process-flow/assign-person-by-supervisor",
      new AssignPersonToRoleBySupervisor(flowId.toAggregateId(), personId, roleId),
      (data: Typed<CommandResponse>) => {
        commandResponseHandler(data).onSuccess((aggregateId: AggregateId, aggregateVersion: AggregateVersion) => {
          onSuccess();
        }).handle();
      });
  }

  unassignPersonsFromRole(flowId: AnyFlowId, roleId: number, persons: Array<AnyPersonId>, onSuccess: () => void, onError: () => void) {
    this.authenticatedHttp.post(
      "process-flow/unassign-persons",
      new UnAssignPersonsFromRoleRouting(Typed.of(flowId), roleId, persons.map(p => Typed.of(p))),
      (data: Typed<AnyFlowCommandResponse>) => {
        anyFlowCommandResponseHandler(data).onSuccess((flowId: AnyFlowId, aggregateVersion: AggregateVersion) => {
          onSuccess();
        }).onAnyError(onError).handle();
      }, () => onError());
  }

  addFlowLabel(flowId: AnyFlowId, flowCursor: FlowCursorId, flowCursorVersion: FlowCursorVersion, label: string, onSuccess: () => void, onError: () => void) {
    this.authenticatedHttp.post(
      "process-flow/add-label", new AddFlowLabel(flowId.toFlowId().toAggregateId(), flowCursor, flowCursorVersion, label),
      (data: Typed<CommandResponse>) => {
        commandResponseHandler(data).onSuccess((aggregateId: AggregateId, aggregateVersion: AggregateVersion) => {
          onSuccess();
        }).onAnyError(onError).handle();
      }, () => onError());
  }

  removeFlowLabel(flowId: AnyFlowId, flowCursor: FlowCursorId, flowCursorVersion: FlowCursorVersion, label: string, onSuccess: () => void, onError: () => void) {
    this.authenticatedHttp.post(
      "process-flow/remove-label", new RemoveFlowLabel(flowId.toFlowId().toAggregateId(), flowCursor, flowCursorVersion, label),
      (data: Typed<CommandResponse>) => {
        commandResponseHandler(data).onSuccess((aggregateId: AggregateId, aggregateVersion: AggregateVersion) => {
          onSuccess();
        }).onAnyError(onError).handle();
      }, () => onError());
  }

  markTaskAsSeen(flowId: AnyFlowId, nodeId: ProcessNodeId, onSuccess: () => void) {
    this.authenticatedHttp.post(
      "process-flow/mark-task-as-seen", new MarkTaskAsSeenRouting(Typed.of(flowId), nodeId),
      (data: Typed<CommandResponse>) => {
        anyFlowCommandResponseHandler(data).onSuccess((flowId: AnyFlowId, aggregateVersion: AggregateVersion) => {
          onSuccess();
        }).handle();
      });
  }

  unmarkTaskAsSeen(flowId: AnyFlowId, nodeId: ProcessNodeId, onSuccess: () => void) {
    this.authenticatedHttp.post(
      "process-flow/unmark-task-as-seen", new UnmarkTaskAsSeenRouting(Typed.of(flowId), nodeId),
      (data: Typed<CommandResponse>) => {
        anyFlowCommandResponseHandler(data).onSuccess((flowId: AnyFlowId, aggregateVersion: AggregateVersion) => {
          onSuccess();
        }).handle();
      });
  }

  markFlowAsWorking(flowId: AnyFlowId, onSuccess: () => void) {
    this.authenticatedHttp.post(
      "process-flow/mark-flow-as-working", new MarkFlowAsWorkingRouting(Typed.of(flowId)),
      (data: Typed<CommandResponse>) => {
        anyFlowCommandResponseHandler(data).onSuccess((flowId: AnyFlowId, aggregateVersion: AggregateVersion) => {
          onSuccess();
        }).handle();
      });
  }

  assignSelfAndStartTask(flowId: AnyFlowId, roleId: number, flowCursor: FlowCursorId, flowCursorVersion: FlowCursorVersion, onSuccess: () => void, onError: () => void) {
    this.authenticatedHttp.post(
      "process-flow/assign-self-and-start-task",
      new AssignSelfAndStartTaskRouting(Typed.of(flowId), roleId, flowCursor, flowCursorVersion),
      (data: Typed<AnyFlowCommandResponse>) => {
        anyFlowCommandResponseHandler(data).onSuccess((flowId: AnyFlowId, aggregateVersion: AggregateVersion) => {
          onSuccess();
        }).onAnyError(onError).handle();
      }, () => onError());
  }

  startTask(flowId: AnyFlowId, flowCursor: FlowCursorId, flowCursorVersion: FlowCursorVersion,
            onSuccess: () => void, onError: () => void) {
    this.authenticatedHttp.post(
      "process-flow/start-task",
      new StartTaskRouting(Typed.of(flowId), flowCursor, flowCursorVersion),
      (data: Typed<AnyFlowCommandResponse>) => {
        anyFlowCommandResponseHandler(data).onSuccess((flowId: AnyFlowId, aggregateVersion: AggregateVersion) => {
          onSuccess();
        }).onAnyError(onError).handle();
      }, () => onError());
  }

  stopTask(flowId: AnyFlowId, flowCursor: FlowCursorId, flowCursorVersion: FlowCursorVersion,
           onSuccess: (aggregateId: AggregateId, aggregateVersion: AggregateVersion) => void,
           onError: () => void) {
    this.authenticatedHttp.post(
      "process-flow/stop-task",
      new StopTask(flowId.toFlowId().toAggregateId(), flowCursor, flowCursorVersion),
      (data: Typed<CommandResponse>) => {
        commandResponseHandler(data).
        onSuccess((aggregateId: AggregateId, aggregateVersion: AggregateVersion) => {
          onSuccess(aggregateId, aggregateVersion);
        }).
        onAnyError(onError).handle();
      },
      onError
    );
  }

  stopTaskAndUnAssignPersons(flowId: AnyFlowId, flowCursor: FlowCursorId, flowCursorVersion: FlowCursorVersion,
                             onSuccess: (aggregateId: AggregateId, aggregateVersion: AggregateVersion) => void,
                             onError: () => void) {
    this.authenticatedHttp.post(
      "process-flow/stop-task-and-un-assign-persons",
      new StopTaskAndUnAssignPersons(flowId.toFlowId().toAggregateId(), flowCursor, flowCursorVersion),
      (data: Typed<CommandResponse>) => {
        commandResponseHandler(data).
        onSuccess((aggregateId: AggregateId, aggregateVersion: AggregateVersion) => {
          onSuccess(aggregateId, aggregateVersion);
        }).
        onAnyError(onError).handle();
      },
      onError
    );
  }

  markTaskWaiting(flowId: AnyFlowId, flowCursor: FlowCursorId, flowCursorVersion: FlowCursorVersion,
                  onSuccess: () => void,
                  onError: () => void) {
    this.authenticatedHttp.post(
      "process-flow/mark-task-waiting",
      new MarkTaskWaiting(Typed.of(flowId), flowCursor, flowCursorVersion),
      (data: Typed<CommandResponse>) => {
        anyFlowCommandResponseHandler(data).
        onSuccess((flowId: AnyFlowId, version: AggregateVersion) => {
          onSuccess();
        }).
        onAnyError(onError).handle();
      },
      onError
    );
  }



  anonymizeData(flowId: FlowId, paths: Array<ContextPath>, onSuccess: () => void, onError: () => void) {
    if(paths.length == 0) {
      onSuccess();
    } else {
      this.authenticatedHttp.post(
        "process-flow/anonymize-data", new AnonymizeFlowData(flowId.toAggregateId(), paths),
        (data: Typed<CommandResponse>) => {
          commandResponseHandler(data).onSuccess((aggregateId: AggregateId, version: AggregateVersion) => {
            onSuccess();
          }).onAnyError(onError).handle();
        })
    }
  }

  permanentlyDeleteFlow(flowId: FlowId, onSuccess: () => void, onError: () => void) {
    this.authenticatedHttp.post(
      "process-flow/permanently-delete-flow",
      new DeletePermanentlyProcessFlow(flowId.toAggregateId()),
      (data: Typed<CommandResponse>) => {
        commandResponseHandler(data).onSuccess((aggregateId: AggregateId, version: AggregateVersion) => {
          onSuccess();
        }).onAnyError(onError).handle();
      }, () => onError()
    );
  }

  addAuthorizationToFlow(flowId: FlowId, authorization: FlowAuthorization, nodeId: OrganizationNodeId,
                         onSuccess: () => void) {
    this.authenticatedHttp.post(
      "process-flow/add-authorization",
      new AddFlowAuthorization(flowId.toAggregateId(), authorization, nodeId),
      (data: Typed<CommandResponse>) => {
        commandResponseHandler(data).onSuccess((aggregateId: AggregateId, aggregateVersion: AggregateVersion) => {
          onSuccess();
        }).handle();
      }
    )
  }

  removeAuthorizationFromFlow(flowId: FlowId, authorization: FlowAuthorization, nodeId: OrganizationNodeId,
                              onSuccess: () => void) {
    this.authenticatedHttp.post(
      "process-flow/remove-authorization",
      new RemoveFlowAuthorization(flowId.toAggregateId(), authorization, nodeId),
      (data: Typed<CommandResponse>) => {
        commandResponseHandler(data).onSuccess((aggregateId: AggregateId, aggregateVersion: AggregateVersion) => {
          onSuccess();
        }).handle();
      }
    )
  }

  changeFlowRelease(flowId: FlowId, toReleaseId: AggregateId, onSuccess: () => void) {
    this.authenticatedHttp.post(
      "process-flow/change-release-version",
      new ChangeFlowRelease(flowId.toAggregateId(), toReleaseId),
      (data: Typed<CommandResponse>) => {
        commandResponseHandler(data).onSuccess((aggregateId: AggregateId, aggregateVersion: AggregateVersion) => {
          onSuccess();
        }).handle();
      }
    )
  }

  redirectAction(flowId: AnyFlowId, flowCursor: FlowCursorId, flowCursorVersion: FlowCursorVersion, edgeId: ProcessEdgeId, flowCommentOption: Option<ProcessFlowComment>,
                 onSuccess: () => void, onError: () => void) {
    const addCommentOption = flowCommentOption.map(comment => {
      return new AddCommentRouting(Typed.of(flowId), comment.nodeId, comment.commentId, comment.commentText, comment.attachments, comment.extraRecipients.map(p => Typed.value(p).asPersonId()));
    });
    this.authenticatedHttp.post(
      "process-flow/redirect-action",
      new RedirectActionRouting(Typed.of(flowId), flowCursor, flowCursorVersion, edgeId, addCommentOption),
      (data: Typed<AnyFlowCommandResponse>) => {
        anyFlowCommandResponseHandler(data).onSuccess((flowId: AnyFlowId, aggregateVersion: AggregateVersion) => {
          onSuccess();
        }).onAnyError(onError).handle();
      },
      onError
    )
  }


  redirectActionFromFlow(flowId: FlowId, flowCursor: FlowCursorId, flowCursorVersion: FlowCursorVersion, edgeId: ProcessEdgeId, flowCommentOption: Option<ProcessFlowComment>,
                 onSuccess: () => void, onError: () => void) {
    const addCommentOption = flowCommentOption.map(comment => {
      return new AddComment(flowId.toAggregateId(), comment.nodeId, comment.commentId, comment.commentText, comment.attachments, comment.extraRecipients.map(p => Typed.value(p).asPersonId()));
    });
    this.authenticatedHttp.post(
      "process-flow/redirect-action-from-flow",
      new RedirectActionFromFlow(flowId.toAggregateId(), flowCursor, flowCursorVersion, edgeId, addCommentOption),
      (data: Typed<CommandResponse>) => {
        commandResponseHandler(data).onSuccess((aggregateId: AggregateId, aggregateVersion: AggregateVersion) => {
          onSuccess();
        }).onAnyError(() => onError()).handle();
      },
      onError
    )
  }

  pullAction(flowId: AnyFlowId, flowCursor: FlowCursorId, edgeId: ProcessEdgeId, flowCommentOption: Option<ProcessFlowComment>,
                 onSuccess: () => void, onError: () => void) {
    const addCommentOption = flowCommentOption.map(comment => {
      return new AddCommentRouting(Typed.of(flowId), comment.nodeId, comment.commentId, comment.commentText, comment.attachments, comment.extraRecipients.map(p => Typed.value(p).asPersonId()));
    });
    this.authenticatedHttp.post(
      "process-flow/pull-action",
      new PullActionRouting(Typed.of(flowId), flowCursor, edgeId, addCommentOption),
      (data: Typed<AnyFlowCommandResponse>) => {
        anyFlowCommandResponseHandler(data).onSuccess((flowId: AnyFlowId, aggregateVersion: AggregateVersion) => {
          onSuccess();
        }).onAnyError(onError).handle();
      },
      onError
    )
  }

  executeActionButton(flowId: AnyFlowId, flowCursor: FlowCursorId, flowCursorVersion: FlowCursorVersion,
                      sectionId: FormSectionId, contextObjectId: Option<ObjectId>, elementId: FormElementId,
                      onIdChange: (newId: AnyFlowId) => void,
                      onSuccess: () => void, onCallEnd: () => void) {
    this.materializeProcessFlow(flowId, onIdChange,(materializedFlowId) => {
      this.authenticatedHttp.post(
        "process-flow/execute-action-button",
        new ExecuteActionButtonRouting(Typed.of(materializedFlowId), flowCursor, flowCursorVersion, sectionId.id, elementId, contextObjectId),
        (data: Typed<AnyFlowCommandResponse>) => {
          onCallEnd();
          anyFlowCommandResponseHandler(data).onSuccess((flowId: AnyFlowId, aggregateVersion: AggregateVersion) => {
            onSuccess();
          }).onFailure(exceptions => console.log("Error during button execution: " +exceptions.join(", "))).handle();
        }, () => {
          onCallEnd();
        }
      )
    });
  }

  loadProcessFlowPreviewDetails(flowCodeOrId: string): Promise<Option<FlowPreviewInfo>> {
    return this.authenticatedHttp.getPromise<Option<FlowPreviewInfo>>("process-flow/get-flow-preview-details/" + flowCodeOrId).then(data => {
      return Option.copy(data, FlowPreviewInfo.copy);
    });
  }


  loadProcessFlowDetails(flowId: AnyFlowId, onSuccess: (processFlowDetails: ProcessFlowDetails, persons: Array<BasicPersonInfo>) => void, onFailure: () => void) {
    this.authenticatedHttp.get("process-flow/get-flow-details/" + flowId.urlSerialized(),
      (data:Option<ProcessFlowDetailsWithPersons>) => {
        const processFlowDetailsAndPersons = Option.copy(data, ProcessFlowDetailsWithPersons.copy);
        if(processFlowDetailsAndPersons.isEmpty()) {
          onFailure();
        } else {
          onSuccess(ProcessFlowDetails.copy(processFlowDetailsAndPersons.get().flowDetails), processFlowDetailsAndPersons.get().persons.map(BasicPersonInfo.copy));
        }
      })
  }


  loadProcessFlowDetailsByFlowCode(flowCode: string): Promise<{flowDetails: ProcessFlowDetails, persons: Array<BasicPersonInfo>}> {
    return this.authenticatedHttp.getPromise<Option<ProcessFlowDetailsWithPersons>>("process-flow/get-flow-details-by-code/" + flowCode).then(data => {
      const modelInfoAndPersons = Option.copy(data, ProcessFlowDetailsWithPersons.copy);
      if(modelInfoAndPersons.isEmpty()) {
        throw new Error("Flow not found");
      } else {
        return {
          flowDetails: ProcessFlowDetails.copy(modelInfoAndPersons.get().flowDetails),
          persons: modelInfoAndPersons.get().persons.map(BasicPersonInfo.copy)
        };
      }
    });
  }

  loadProcessFlowInfoWithTasksForUserByFlowId(flowsIds: Array<Typed<AnyFlowId>>, onSuccess: (flowsInfo: Array<Option<FlowAndTasksInfoForUser>>) => void) {
    this.authenticatedHttp.post("process-flow/get-flows-with-tasks-info-for-user", new GetFlowsInfoForUser(flowsIds),
      (data:Array<Option<FlowAndTasksInfoForUser>>) => {
        onSuccess(data.map(f => Option.copy(f, FlowAndTasksInfoForUser.copy)));
      });
  }

  loadProcessFlowInfoForUserByFlowCode(flowCode: string, onSuccess: (modelInfo: Option<FlowAndTasksInfoForUser>) => void) {
    this.authenticatedHttp.get("process-flow/get-flow-info-for-user-by-code/" + flowCode,
      (data:Option<FlowAndTasksInfoForUser>) => {
        onSuccess(Option.copy(data, FlowAndTasksInfoForUser.copy));
      });
  }

  loadProcessFlowDetailsComments(flowId: AnyFlowId, onSuccess: (comments: Array<CommentViewModel>) => void) {
    this.authenticatedHttp.get("process-flow/get-flow-comments/" + flowId.urlSerialized(),
      (data:Array<MyCommentServerModel>) => {
        onSuccess(data.map(MyCommentServerModel.copy).map(comment => {

          return new CommentViewModel(comment.commentId, comment.personId.map(Typed.value).filter(s => s.isPersonId()).map(p => OrganizationNodeId.fromPersonId(p.asPersonId())).getOrUndefined(), comment.commentText, comment.attachments, comment.extraRecipients.map(Typed.value),  comment.created, comment.modified.getOrNull(), comment.deleted)}));
      })
  }

  getTask(flowId: AnyFlowId, nodeId: ProcessNodeId,
          onSuccess: (task: TaskModel, personsFromTask: Array<BasicPersonInfo>) => void,
          onNotFound: () => void,
          onError: (error: string) => void) {
    this.authenticatedHttp.post(
      "process-flow/task", new GetTask(Typed.of(flowId), nodeId),
      (data: Either<string, Option<TaskModelWithPersons>>) => {
        const either = Either.copy(data, l => l, r => Option.copy(r).map(TaskModelWithPersons.copy));
        if(either.isRight())  {
          if(either.getRight().isDefined()) {
            onSuccess(either.getRight().get().task, either.getRight().get().persons);
          } else {
            onNotFound();
          }
        } else {
          onError(either.getLeft());
        }
      })
  }

  getTaskWithPersonsOrWait(flowId: AnyFlowId, nodeId: ProcessNodeId,
          onSuccess: (task: TaskModel, personsFromTask: Array<BasicPersonInfo>) => void,
          onNotFound: () => void,
          onError: (error: string) => void) {
    this.authenticatedHttp.post(
      "process-flow/task-with-persons-or-wait", new GetTask(Typed.of(flowId), nodeId),
      (data: Either<string, Option<TaskModelWithPersons>>) => {
        const either = Either.copy(data, l => l, r => Option.copy(r).map(TaskModelWithPersons.copy));
        if(either.isRight())  {
          if(either.getRight().isDefined()) {
            onSuccess(either.getRight().get().task, either.getRight().get().persons);
          } else {
            onNotFound();
          }
        } else {
          onError(either.getLeft());
        }
      })
  }

  getTaskOrWait(flowId: AnyFlowId, nodeId: ProcessNodeId, onSuccess: (task: TaskModel) => void, onFailure: () => void) {
    this.authenticatedHttp.get(
      "process-flow/task-or-wait/"+flowId.urlSerialized()+"/"+nodeId,
      (data: Option<TaskModel>) => {
        const d = Option.copy(data);
        if(d.isDefined())  {
          onSuccess(TaskModel.copy(d.get()));
        } else {
          onFailure();
        }

      })
  }

  readonly ARCHIVE_TASKS_TO_LOAD = 200;
  preloadArchivedTasksForMe(applicationId: Array<ApplicationId>,
                            initiator: Array<AnyPersonId>,
                            assignee: Array<AnyPersonId>,
                            labels: Array<string>,
                            systemLabels: Array<BusinessVariable>,
                            importance: Array<FlowImportance>,
                            createdFrom: Option<LocalDateTime>,
                            createdTo: Option<LocalDateTime>,
                            completedFrom: Option<LocalDateTime>,
                            completedTo: Option<LocalDateTime>) : Promise<void> {
    return new Promise<void>((resolve, reject) => {

      if(this.archivedTaskCache && new Date().getTime() - this.archivedTaskCacheTimestamp < 30000) {
        resolve();
      } else {

        this.authenticatedHttp.post(
          "process-flow/find-archived-tasks-for-me", new FindArchivedTasksForPerson(
            applicationId, initiator.map(Typed.of), assignee.map(Typed.of), labels, systemLabels.map(Typed.of), importance,
            createdFrom, createdTo, completedFrom, completedTo,
            this.ARCHIVE_TASKS_TO_LOAD, 0),
          (data: ArchivedTasksSearchResult) => {
            const copied = ArchivedTasksSearchResult.copy(data);
            this.archivedTaskCache = copied;
            this.archivedTaskCacheTimestamp = new Date().getTime();
            resolve();
          }
        );
      }
    });

  }

  findArchivedTasksForMe(
    applicationId: Array<ApplicationId>,
    initiator: Array<AnyPersonId>,
    assignee: Array<AnyPersonId>,
    labels: Array<string>,
    systemLabels: Array<BusinessVariable>,
    importance: Array<FlowImportance>,
    createdFrom: Option<LocalDateTime>,
    createdTo: Option<LocalDateTime>,
    completedFrom: Option<LocalDateTime>,
    completedTo: Option<LocalDateTime>,
    offset: number, onSuccess: (tasks: Array<TaskModelSummary>, moreAvailable: boolean, nextOffset: number, requestedCount: number) => void) {
    if(offset == 0 && this.archivedTaskCache && new Date().getTime() - this.archivedTaskCacheTimestamp < 10000) {
      const copied = this.archivedTaskCache;
      this.archivedTaskCacheTimestamp = 0;
      this.archivedTaskCache = undefined;
      onSuccess(copied.tasks, copied.moreResultsAvailable, copied.nextOffset, this.ARCHIVE_TASKS_TO_LOAD);
    } else {

      if(offset == 0) {
        this.archivedTaskCache = undefined;
      }
      this.authenticatedHttp.post(
        "process-flow/find-archived-tasks-for-me", new FindArchivedTasksForPerson(
          applicationId, initiator.map(Typed.of), assignee.map(Typed.of), labels, systemLabels.map(Typed.of), importance,
          createdFrom, createdTo, completedFrom, completedTo,
          this.ARCHIVE_TASKS_TO_LOAD, offset),
        (data: ArchivedTasksSearchResult) => {
          const copied = ArchivedTasksSearchResult.copy(data);
          onSuccess(copied.tasks, copied.moreResultsAvailable, copied.nextOffset, this.ARCHIVE_TASKS_TO_LOAD);
        }
      );
    }
  }

  getAssignableTasks(personsIds: Array<AggregateId>, onSuccess: (tasks: Array<AssignableTask>) => void) {
    this.authenticatedHttp.post(
      "process-flow/assignable-tasks", new FindAssignableTasks(personsIds),
      (data: Array<AssignableTask>) => {
        onSuccess(data.map(d => AssignableTask.copy(d)));
      })
  }



  getFlowCode(flowId: AnyFlowId, onSuccess: (code: Option<Option<string>>) => void) {
    this.authenticatedHttp.get(
      "process-flow/get-flow-code/"+flowId.urlSerialized(),
      (code: Option<Option<string>>) => {
        onSuccess(Option.copy(code, (a: Option<string>) => Option.copy(a)));
      })
  }





  overrideImportance(flowId: AnyFlowId, flowCursor: FlowCursorId, flowCursorVersion: FlowCursorVersion, importance: Option<FlowImportance>, onSuccess: () => void) {
    this.authenticatedHttp.post(
      "process-flow/override-importance",
      new OverrideImportanceRouting(Typed.of(flowId), flowCursor, flowCursorVersion, importance),
      (data: Typed<AnyFlowCommandResponse>) => {
        anyFlowCommandResponseHandler(data).onSuccess((flowId: AnyFlowId, version: AggregateVersion) => {
          onSuccess();
        }).handle();
      }
    );
  }

  overrideUrgency(flowId: AnyFlowId, flowCursor: FlowCursorId, flowCursorVersion: FlowCursorVersion, urgency: Option<FlowUrgency>, onSuccess: () => void) {
    this.authenticatedHttp.post(
      "process-flow/override-urgency",
      new OverrideUrgencyRouting(Typed.of(flowId), flowCursor, flowCursorVersion, urgency),
      (data: Typed<AnyFlowCommandResponse>) => {
        anyFlowCommandResponseHandler(data).onSuccess((flowId: AnyFlowId, version: AggregateVersion) => {
          onSuccess();
        }).handle();
      }
    );
  }

  overrideColor(flowId: AnyFlowId, color: Option<Option<string>>, onSuccess: () => void) {
    this.authenticatedHttp.post(
      "process-flow/override-color",
      new OverrideColorRouting(Typed.of(flowId), color),
      (data: Typed<AnyFlowCommandResponse>) => {
        anyFlowCommandResponseHandler(data).onSuccess((flowId: AnyFlowId, version: AggregateVersion) => {
          onSuccess();
        }).handle();
      }
    );
  }

  overrideTaskDeadline(flowId: AnyFlowId, nodeId: ProcessNodeId, deadline: Option<Option<LocalDateTime>>, onSuccess: () => void) {
    this.authenticatedHttp.post(
      "process-flow/override-task-deadline",
      new OverrideTaskDeadlineRouting(Typed.of(flowId), nodeId, deadline),
      (data: Typed<AnyFlowCommandResponse>) => {
        anyFlowCommandResponseHandler(data).onSuccess((flowId: AnyFlowId, version: AggregateVersion) => {
          onSuccess();
        }).handle();
      }
    );
  }

  changeTaskDeadline(flowId: AnyFlowId, nodeId: ProcessNodeId, deadline: Option<LocalDateTime>, onSuccess: () => void) {
    this.authenticatedHttp.post(
      "process-flow/change-task-deadline",
      new ChangeTaskDeadlineRouting(Typed.of(flowId), nodeId, deadline),
      (data: Typed<AnyFlowCommandResponse>) => {
        anyFlowCommandResponseHandler(data).onSuccess((flowId: AnyFlowId, version: AggregateVersion) => {
          onSuccess();
        }).handle();
      }
    );
  }

  getTaskFlowData(flowId: FlowId, onSuccess: (task: AdHocTaskData) => void, onNotFound: () => void) {

    this.authenticatedHttp.post(
      "process-flow/ad-hoc-task-data", new GetAdHocFlowData(flowId),
      (data: Option<AdHocTaskData>) => {
        const copied = Option.copy(data, AdHocTaskData.copy);
        if(copied.isEmpty()) {
          onNotFound();
        } else {
          onSuccess(copied.get());
        }
      });
  }

  private getTaskTaskData(task: TaskModel): Promise<AdHocTaskInfo>{
    return new Promise<AdHocTaskInfo>((resolve, reject) => {
      this.sessionServiceProvider.getOrganizationSessionInfo((sessionInfo) => {

        let description = "";
        let importance = task.importance;
        let assignee = OrganizationNodeId.fromPersonId(sessionInfo.personId);
        let deadline: LocalDateTime|null = null;

        const variables = __(task.formVariables);

        variables.find(v => v.name === "description")
          .forEach(v => v.unwrappedVariableOption().map(v => {
            switch (v.className()) {
              case StringVariable.className:
                description = (<StringVariable>v).value;
                break;
            }
          }));

        variables.find(v => v.name === "assignee")
          .forEach(v => v.unwrappedVariableOption().map(v => {
            switch (v.className()) {
              case DepartmentVariable.className:
                assignee = OrganizationNodeId.fromDepartmentId((<DepartmentVariable>v).value);
                break;
              case GroupVariable.className:
                assignee = OrganizationNodeId.fromGroupId((<GroupVariable>v).value);
                break;
              case PersonVariable.className:
                assignee = OrganizationNodeId.fromPersonId((<PersonVariable>v).value);
                break;
            }
          }));

        variables
          .find(v => v.name === "due_datetime")
          .forEach(v => v.unwrappedVariableOption().map(v => {
            switch (v.className()) {
              case DateVariable.className: // deprecated, this is handled for backward compatibility
                deadline = LocalDateTime.fromLocalDateEnd((<DateVariable>v).value);
                break;
              case DateTimeVariable.className:
                deadline = (<DateTimeVariable>v).value;
                break;
            }
          }));


        resolve({
          importance: task.importance,
          description: description,
          assignee: assignee,
          deadline: deadline,
          taskId: task.toTaskIdentifier(),
          flowWithCursor: task.toFlowWithCursor(),
        });
      });
    });
  }

}
