import {FormModelValidation, LabelPosition} from "../../../process-common.module";
import {FormSectionId, FormSectionRefId} from "@shared";
import {FormElementId, FormElementRefId, GridSize, GridXY, None, Option, Some, Trilean, Typed} from "@utils";
import {
  ArrayVariable,
  BusinessVariable, BusinessVariablesValidation,
  BusinessVariableType,
  ExpressionWithAst,
  VariableTypePath
} from "@shared-model";

export interface ModelEvent {
  eventName(): string;
  className(): string;
}

export class FormSectionCreated implements ModelEvent {
  eventName = () => "FormSectionCreated";
  className = this.eventName;

  constructor(readonly sectionId: FormSectionId,
              readonly sectionName: string) {}
}

export class FormRepeatableSectionCreated implements ModelEvent {
  eventName = () => "FormRepeatableSectionCreated";
  className = this.eventName;

  constructor(readonly sectionId: FormSectionId,
              readonly sectionName: string,
              readonly variableName: string) {}
}


export class FormSectionsRefsOrderChangedV1 implements ModelEvent {
  eventName = () => "FormSectionsRefsOrderChangedV1";
  className = this.eventName;

  constructor(readonly nodeId: number,
              readonly sectionsRefs: Array<FormSectionRefId>) {}

}

export class FormSectionReferred implements ModelEvent {
  eventName = () => "FormSectionReferred";
  className = this.eventName;

  constructor(readonly nodeId: number,
              readonly sectionId: FormSectionId,
              readonly sectionRefId: FormSectionRefId,
              readonly sectionRefIndex: number,
              readonly readOnly: boolean) {}

}

export class FormSectionRefRemoved implements ModelEvent {
  eventName = () => "FormSectionRefRemoved";
  className = this.eventName;

  constructor(readonly nodeId: number,
              readonly sectionRefId: FormSectionRefId) {}

}

export class FormSectionRemoved implements ModelEvent {
  eventName = () => "FormSectionRemoved";
  className = this.eventName;

  constructor(readonly sectionId: FormSectionId) {}

}

export class FormSectionHeightChanged implements ModelEvent {
  eventName = () => "FormSectionHeightChanged";
  className = this.eventName;

  sectionId:FormSectionId;
  newGridHeight:number;

  constructor(sectionId:FormSectionId, newGridHeight:number) {
    this.sectionId = sectionId;
    this.newGridHeight = newGridHeight;
  }

}


export interface FormInputElementChangedEvent extends ModelEvent {}
export interface FormStaticElementChangedEvent extends ModelEvent {}

export interface FormInputElementRefChangedEvent extends ModelEvent {}
export interface FormStaticElementRefChangedEvent extends ModelEvent {}

export class FormLabelChanged implements FormStaticElementChangedEvent {
  eventName = () => "FormLabelChanged";
  className = this.eventName;

  constructor(
    readonly id: FormElementId,
    readonly text: string,
    readonly bold: boolean,
    readonly hiddenExpression: Option<ExpressionWithAst>) {}

}

export interface ProcessAutomaticActionType {
  className(): string;}

export class AdvancedActionType implements ProcessAutomaticActionType {
  static className = "AdvancedActionType";
  className(): string {
    return AdvancedActionType.className;
  }

  constructor(public expressionWithAst: ExpressionWithAst,
              public resultVariableName: Option<string>) {}

  static of(expression: string, result: string) {
    return new AdvancedActionType(ExpressionWithAst.of(expression), result.trim().length > 0 ? Some(result.trim()) : None());
  }

}

export class ProcessAutomaticAction {
  constructor(public id: number,
              public name: Option<string>,
              public automaticActionType: Typed<ProcessAutomaticActionType>,
              public expression: ExpressionWithAst,
              public description: Option<string>,
              public resultVariableName: Option<string>) {
  }
}

export class FormActionButtonChanged implements FormStaticElementChangedEvent {
  eventName = () => "FormActionButtonChanged";
  className = this.eventName;

  constructor(
    readonly id: FormElementId,
    readonly label: string,
    readonly actions: Array<ProcessAutomaticAction>,
    readonly hiddenExpression: Option<ExpressionWithAst>,
    readonly readOnlyExpression: Option<ExpressionWithAst>) {}

}


export class FormTextAreaChanged implements FormInputElementChangedEvent {
  eventName = () => "FormTextAreaChanged";
  className = this.eventName;

  constructor(
    readonly id: FormElementId,
    readonly variableTypePath: VariableTypePath,
    readonly placeholder: string,
    readonly validation: Option<Typed<BusinessVariablesValidation>>,
    readonly hiddenExpression: Option<ExpressionWithAst>,
    readonly requiredExpression: Option<ExpressionWithAst>,
    readonly readOnlyExpression: Option<ExpressionWithAst>
    ) {}

}

export class FormCheckboxChanged implements FormInputElementChangedEvent {
  eventName = () => "FormCheckboxChanged";
  className = this.eventName;

  constructor(
    readonly id: FormElementId,
    readonly variableTypePath: VariableTypePath,
    readonly validation: Option<Typed<BusinessVariablesValidation>>,
    readonly entries: ArrayVariable<BusinessVariable>,
    readonly defaultValue: ArrayVariable<BusinessVariable>,
    readonly entriesExpression: Option<ExpressionWithAst>,
    readonly hiddenExpression: Option<ExpressionWithAst>,
    readonly requiredExpression: Option<ExpressionWithAst>,
    readonly readOnlyExpression: Option<ExpressionWithAst>) {}

}

export class FormRadioButtonChanged implements FormInputElementChangedEvent {
  eventName = () => "FormRadioButtonChanged";
  className = this.eventName;

  constructor(
    readonly id: FormElementId,
    readonly variableTypePath: VariableTypePath,
    readonly validation: Option<Typed<BusinessVariablesValidation>>,
    readonly entries: ArrayVariable<BusinessVariable>,
    readonly defaultValue: Option<Typed<BusinessVariable>>,
    readonly entriesExpression: Option<ExpressionWithAst>,
    readonly hiddenExpression: Option<ExpressionWithAst>,
    readonly requiredExpression: Option<ExpressionWithAst>,
    readonly readOnlyExpression: Option<ExpressionWithAst>) {}


}

export class FormDropListChanged implements FormInputElementChangedEvent {
  eventName = () => "FormDropListChanged";
  className = this.eventName;

  constructor(
    readonly id: FormElementId,
    readonly variableTypePath: VariableTypePath,
    readonly validation: Option<Typed<BusinessVariablesValidation>>,
    readonly entries: ArrayVariable<BusinessVariable>,
    readonly defaultValue: Option<Typed<BusinessVariable>>,
    readonly entriesExpression: Option<ExpressionWithAst>,
    readonly hiddenExpression: Option<ExpressionWithAst>,
    readonly requiredExpression: Option<ExpressionWithAst>,
    readonly readOnlyExpression: Option<ExpressionWithAst>,
    readonly searchPersonsWithin: boolean,
    readonly searchDepartmentsWithin: boolean) {}

}

export class FormDateFieldChanged implements FormInputElementChangedEvent {
  eventName = () => "FormDateFieldChanged";
  className = this.eventName;

  constructor(
    readonly id: FormElementId,
    readonly variableTypePath: VariableTypePath,
    readonly validation: Option<Typed<BusinessVariablesValidation>>,
    readonly hiddenExpression: Option<ExpressionWithAst>,
    readonly requiredExpression: Option<ExpressionWithAst>,
    readonly readOnlyExpression: Option<ExpressionWithAst>) {}


}

export class FormAttachmentFieldChanged implements FormInputElementChangedEvent {
  eventName = () => "FormAttachmentFieldChanged";
  className = this.eventName;

  constructor(
    readonly id: FormElementId,
    readonly variableTypePath: VariableTypePath,
    readonly validation: Option<Typed<BusinessVariablesValidation>>,
    readonly hiddenExpression: Option<ExpressionWithAst>,
    readonly requiredExpression: Option<ExpressionWithAst>,
    readonly readOnlyExpression: Option<ExpressionWithAst>) {}


}

export class FormMapFieldChanged implements FormInputElementChangedEvent {
  eventName = () => "FormMapFieldChanged";
  className = this.eventName;

  constructor(
    readonly id: FormElementId,
    readonly variableTypePath: VariableTypePath,
    readonly validation: Option<Typed<BusinessVariablesValidation>>,
    readonly hiddenExpression: Option<ExpressionWithAst>,
    readonly requiredExpression: Option<ExpressionWithAst>,
    readonly readOnlyExpression: Option<ExpressionWithAst>) {}

}



export class FormLabelRefChanged implements FormStaticElementRefChangedEvent {
  eventName = () => "FormLabelRefChanged";
  className = this.eventName;

  constructor(
    readonly sectionId: FormSectionId,
    readonly sourceSectionId: Option<FormSectionId>,
    readonly id: FormElementRefId,
    readonly elementId: FormElementId,
    readonly gridXY: GridXY,
    readonly gridSize: GridSize,
    readonly hidden: Trilean
  ) {}


}

export class FormActionButtonRefChanged implements FormStaticElementRefChangedEvent {
  eventName = () => "FormActionButtonRefChanged";
  className = this.eventName;

  constructor(
    readonly sectionId: FormSectionId,
    readonly sourceSectionId: Option<FormSectionId>,
    readonly id: FormElementRefId,
    readonly elementId: FormElementId,
    readonly gridXY: GridXY,
    readonly gridSize: GridSize,
    readonly readOnly: Trilean,
    readonly hidden: Trilean
  ) {}

}
export class FormTextAreaRefChangedV1 implements FormInputElementRefChangedEvent {
  eventName = () => "FormTextAreaRefChangedV1";
  className = this.eventName;

  constructor(
    readonly sectionId: FormSectionId,
    readonly sourceSectionId: Option<FormSectionId>,
    readonly id: FormElementRefId,
    readonly elementId: FormElementId,
    readonly gridXY: GridXY,
    readonly gridSize: GridSize,
    readonly label: string,
    readonly labelPosition: LabelPosition,
    readonly labelGridShift: GridXY,
    readonly required: Trilean,
    readonly readOnly: Trilean,
    readonly hidden: Trilean,
    readonly visibleInSummary: boolean,
    readonly visibleInTaskBox: boolean
  ) {}

}

export class FormCheckboxRefChangedV1 implements FormInputElementRefChangedEvent {
  eventName = () => "FormCheckboxRefChangedV1";
  className = this.eventName;

  constructor(
    readonly sectionId: FormSectionId,
    readonly sourceSectionId: Option<FormSectionId>,
    readonly id: FormElementRefId,
    readonly elementId: FormElementId,
    readonly gridXY: GridXY,
    readonly gridSize: GridSize,
    readonly label: string,
    readonly labelPosition: LabelPosition,
    readonly labelGridShift: GridXY,
    readonly required: Trilean,
    readonly readOnly: Trilean,
    readonly hidden: Trilean,
    readonly visibleInSummary: boolean,
    readonly visibleInTaskBox: boolean
  ) {}

}

export class FormRadioButtonRefChangedV1 implements FormInputElementRefChangedEvent {
  eventName = () => "FormRadioButtonRefChangedV1";
  className = this.eventName;

  constructor(
    readonly sectionId: FormSectionId,
    readonly sourceSectionId: Option<FormSectionId>,
    readonly id: FormElementRefId,
    readonly elementId: FormElementId,
    readonly gridXY: GridXY,
    readonly gridSize: GridSize,
    readonly label: string,
    readonly labelPosition: LabelPosition,
    readonly labelGridShift: GridXY,
    readonly required: Trilean,
    readonly readOnly: Trilean,
    readonly hidden: Trilean,
    readonly visibleInSummary: boolean,
    readonly visibleInTaskBox: boolean
  ) {}

}

export class FormDropListRefChangedV1 implements FormInputElementRefChangedEvent {
  eventName = () => "FormDropListRefChangedV1";
  className = this.eventName;

  constructor(
    readonly sectionId: FormSectionId,
    readonly sourceSectionId: Option<FormSectionId>,
    readonly id: FormElementRefId,
    readonly elementId: FormElementId,
    readonly gridXY: GridXY,
    readonly gridSize: GridSize,
    readonly label: string,
    readonly labelPosition: LabelPosition,
    readonly labelGridShift: GridXY,
    readonly required: Trilean,
    readonly readOnly: Trilean,
    readonly hidden: Trilean,
    readonly visibleInSummary: boolean,
    readonly visibleInTaskBox: boolean
  ) {}

}

export class FormDateFieldRefChangedV1 implements FormInputElementRefChangedEvent {
  eventName = () => "FormDateFieldRefChangedV1";
  className = this.eventName;

  constructor(
    readonly sectionId: FormSectionId,
    readonly sourceSectionId: Option<FormSectionId>,
    readonly id: FormElementRefId,
    readonly elementId: FormElementId,
    readonly gridXY: GridXY,
    readonly gridSize: GridSize,
    readonly label: string,
    readonly labelPosition: LabelPosition,
    readonly labelGridShift: GridXY,
    readonly required: Trilean,
    readonly readOnly: Trilean,
    readonly hidden: Trilean,
    readonly visibleInSummary: boolean,
    readonly visibleInTaskBox: boolean
  ) {}

}

export class FormAttachmentFieldRefChangedV1 implements FormInputElementRefChangedEvent  {
  eventName = () => "FormAttachmentFieldRefChangedV1";
  className = this.eventName;

  constructor(
    readonly sectionId: FormSectionId,
    readonly sourceSectionId: Option<FormSectionId>,
    readonly id: FormElementRefId,
    readonly elementId: FormElementId,
    readonly gridXY: GridXY,
    readonly gridSize: GridSize,
    readonly label: string,
    readonly labelPosition: LabelPosition,
    readonly labelGridShift: GridXY,
    readonly required: Trilean,
    readonly readOnly: Trilean,
    readonly hidden: Trilean,
    readonly filesReadOnly: boolean,
    readonly visibleInSummary: boolean,
    readonly visibleInTaskBox: boolean
  ) {}

}

export class FormMapFieldRefChanged implements FormInputElementRefChangedEvent {
  eventName = () => "FormMapFieldRefChanged";
  className = this.eventName;

  constructor(
    readonly sectionId: FormSectionId,
    readonly sourceSectionId: Option<FormSectionId>,
    readonly id: FormElementRefId,
    readonly elementId: FormElementId,
    readonly gridXY: GridXY,
    readonly gridSize: GridSize,
    readonly label: string,
    readonly labelPosition: LabelPosition,
    readonly labelGridShift: GridXY,
    readonly required: Trilean,
    readonly readOnly: Trilean,
    readonly hidden: Trilean,
    readonly visibleInSummary: boolean,
    readonly visibleInTaskBox: boolean
  ) {}

}


export class FormInputElementVariableNameChangedV1 implements ModelEvent {
  eventName = () => "FormInputElementVariableNameChangedV1";
  className = this.eventName;

  constructor(readonly elementId: FormElementId,
              readonly newVariableTypePath: VariableTypePath) {}

}

export class ExternalMappingVariableNameChanged implements ModelEvent {
  eventName = () => "ExternalMappingVariableNameChanged";
  className = this.eventName;

  constructor(readonly nodeId: number,
              readonly oldName: string,
              readonly newName: string) {}
}


//Reflects server-side
export class VariableTypeUpdatedV1 implements ModelEvent {
  eventName = () => "VariableTypeUpdatedV1";
  className = this.eventName;

  constructor(readonly path: VariableTypePath,
              readonly variableType: Typed<BusinessVariableType>) {}
}


//Reflects server-side
export class FormElementRemoved implements ModelEvent {
  eventName = () => "FormElementRemoved";
  className = this.eventName;

  constructor(readonly elementId: FormElementId) {}
}

export class FormStaticElementRefRemoved implements ModelEvent {
  eventName = () => "FormStaticElementRefRemoved";
  className = this.eventName;

  constructor(readonly sectionId: FormSectionId,
              readonly elementRefId: FormElementRefId) {}
}

export class FormInputElementRefRemoved implements ModelEvent {
  eventName = () => "FormInputElementRefRemoved";
  className = this.eventName;

  constructor(readonly sectionId: FormSectionId,
              readonly elementRefId: FormElementRefId) {}

}

export class VariableAddedV1 implements ModelEvent {
  eventName = () => "VariableAddedV1";
  className = this.eventName;

  constructor(readonly path: VariableTypePath,
              readonly variableType: Typed<BusinessVariableType>) {}

}

export class VariableNameChangedV1 implements ModelEvent {
  eventName = () => "VariableNameChangedV1";
  className = this.eventName;

  constructor(readonly path: VariableTypePath,
              readonly newName: string) {}

}

export class VariableRemovedV1 implements ModelEvent {
  eventName = () => "VariableRemovedV1";
  className = this.eventName;

  constructor(readonly path: VariableTypePath) {}

}

export class VariableExpressionUpdated implements ModelEvent {
  eventName = () => "VariableExpressionUpdated";
  className = this.eventName;

  constructor(readonly path: VariableTypePath,
              readonly expressionWithAst: ExpressionWithAst,
              readonly enabled: boolean) {}

}

export class SectionNameChanged implements ModelEvent {
  eventName = () => "SectionNameChanged";
  className = this.eventName;

  constructor(readonly sectionId: FormSectionId,
              readonly name: string) {}

}


export class SectionForSectionRefCopied implements ModelEvent {
  eventName = () => "SectionForSectionRefCopied";
  className = this.eventName;

  constructor(readonly nodeId: number,
              readonly sectionRefId: FormSectionRefId) {}
}


export class SectionPropertiesChangedV1 implements ModelEvent {
  eventName = () => "SectionPropertiesChangedV1";
  className = this.eventName;

  constructor(readonly sectionId: FormSectionId,
              readonly visibilityExpression: Option<ExpressionWithAst>,
              readonly forEachVariableName: Option<string>,
              readonly hideHeader: boolean,
              readonly lengthEditable: boolean,
              readonly minimumLength: Option<number>,
              readonly maximumLength: Option<number>,
              readonly tableMode: boolean) {}

}

export class SectionRefPropertiesChangedV1 implements ModelEvent {
  eventName = () => "SectionRefPropertiesChangedV1";
  className = this.eventName;

  constructor(readonly nodeId: number,
              readonly sectionRefId: FormSectionRefId,
              readonly readonly: boolean) {}

}

export class TooltipChanged implements ModelEvent {
  eventName = () => "TooltipChanged";
  className = this.eventName;

  constructor(readonly id: FormElementId,
              readonly tooltip: string) {}

}

export class FormValidationChanged implements ModelEvent {
  eventName = () => "FormValidationChanged";
  className = this.eventName;

  constructor(readonly nodeId: number,
              readonly formValidations: Array<FormModelValidation>) {}

}
