import {AggregateVersion, ApplicationId, InstanceId, None, OrganizationId, PersonId, ProcessId, Typed} from "@utils";
import {BasicPersonInfo} from "@shared-model";

export const Constants = {
  taskProcessInstanceId: InstanceId.of("gqv0wtt789sf"),
  taskProcessId: ProcessId.of("7r81zurqox2i"),
  taskProcessStartNodeId: 1,
  incomingEmailPersonId: PersonId.of("1dmd0efq0hfll"),
  globalApplicationId: ApplicationId.of("yq4yqrw4az89"),
  platformPersonId: PersonId.of("1coim7mvjkhem"),
  platformOrganizationId: OrganizationId.of("1ek7el8khedsk"),


  platformPersonInfo: new BasicPersonInfo(Typed.of(PersonId.of("1coim7mvjkhem")),AggregateVersion.ZERO,  OrganizationId.of("1ek7el8khedsk"), None(), "Platform", "", "", "", None(), false),
  noCategory: 1,

  webClientUserId: PersonId.of("znzcxkql7xf8"),

}
