<div class="left" #Left>
  <ng-container *ngIf="!operation.left">
    <i class="mi-stop"></i>
    <input type="text" class="entry" (click)="showValueSelector(Left, 'left')" [(ngModel)]="anyLeftValue"
           (blur)="operationAnyValueBlurred('left')" />
  </ng-container>
  <ng-container *ngIf="operation.left">

    <ng-container [ngSwitch]="operation.left.valueType">
      <ng-container *ngSwitchCase="'variable'">
        <i class="mi-tag"></i>
        <input class="variablePath value" (click)="showValueSelector(Left, 'left')" type="text" [(ngModel)]="operation.left.stringValue" [placeholder]="'designer_multi_type_variable_name' | i18n" [focus-on-show-init-only]="focusOnShow" (change)="variableValueChanged('left', operation.left.stringValue)" (keydown)="hideValueSelector()" />
      </ng-container>
      <ng-container *ngSwitchCase="'string'">
        <i class="mi-text"></i>
        <input class="value" (click)="showValueSelector(Left, 'left')" type="text" *ngIf="operation.left.valueType == 'string'" [(ngModel)]="operation.left.stringValue" [placeholder]="'designer_multi_type_text_value' | i18n" [focus-on-show-init-only]="focusOnShow" (change)="stringValueChanged('left', operation.left.stringValue)" (keydown)="hideValueSelector()"/>
      </ng-container>
      <ng-container *ngSwitchCase="'number'">
        <i class="mi-number-input"></i>
        <input class="value numberInput" (click)="showValueSelector(Left, 'left')" type="number" *ngIf="operation.left.valueType == 'number'" [(ngModel)]="operation.left.numberValue" [placeholder]="'designer_multi_type_number_value' | i18n" [focus-on-show-init-only]="focusOnShow" (change)="numberValueChanged('left', operation.left.numberValue)" (keydown)="hideValueSelector()"/>
      </ng-container>
      <ng-container *ngSwitchCase="'boolean'">
        <i class="mi-check" *ngIf="operation.left.booleanValue"></i>
        <i class="mi-check" *ngIf="!operation.left.booleanValue"></i>
        <button *ngIf="operation.left.valueType == 'boolean'" class="booleanValue value" (click)="showValueSelector(Left, 'left')" [class.yes]="operation.left.booleanValue">
          <ng-container *ngIf="operation.left.booleanValue">{{'common_yes' | i18n}}</ng-container>
          <ng-container *ngIf="!operation.left.booleanValue">{{'common_no' | i18n}}</ng-container>
        </button>
      </ng-container>
      <ng-container *ngSwitchCase="'expression'">
        <i class="mi-calculator"></i>
        <my-expression-editor class="value" *ngIf="operation.left.valueType == 'expression'"
                              [suggestionsProvider]="suggestionsProvider"
                              [(expression)]="operation.left.stringValue" [miniMode]="true" [focusOnShow]="true" (expressionChangedOnBlur)="expressionValueChanged('left', operation.left.stringValue)" (expressionChange)="hideValueSelector()"/>
      </ng-container>
    </ng-container>
  </ng-container>
  <button
    *ngIf="!operation.left || operation.left.isEmpty() || operation.left.valueType == 'expression'"
    class="theme-button-flat-icon-medium dropDown" (click)="showValueSelector(Left, 'left')"><i class="mi-navigate-down"></i></button>
</div>
<button #OPERATOR class="operator" (click)="showOperatorMenu()">
  <div class="operatorIcon" *ngIf="operation.operator === '=='">&equals;</div>
  <div class="operatorIcon" *ngIf="operation.operator === '!='">&ne;</div>
  <div class="operatorIcon" *ngIf="operation.operator === '<'">&lt;</div>
  <div class="operatorIcon" *ngIf="operation.operator === '<='">&le;</div>
  <div class="operatorIcon" *ngIf="operation.operator === '>'">&gt;</div>
  <div class="operatorIcon" *ngIf="operation.operator === '>='">&ge;</div>
  <div class="operatorIcon" *ngIf="operation.operator === 'contains'" [my-tooltip]="{i18n: 'process_logic_operation_contains'}">∋</div>
  <div class="operatorIcon" *ngIf="operation.operator === '!contains'" [my-tooltip]="{i18n: 'process_logic_operation_not_contains'}">∌</div>
</button>
<div class="right" #Right>
  <ng-container *ngIf="!operation.right">
    <i class="mi-stop"></i>
    <input type="text" class="entry" (click)="showValueSelector(Right, 'right')" [(ngModel)]="anyRightValue"
           (blur)="operationAnyValueBlurred('right')" />
  </ng-container>
  <ng-container *ngIf="operation.right">

    <ng-container [ngSwitch]="operation.right.valueType">
      <ng-container *ngSwitchCase="'variable'">
        <i class="mi-tag"></i>
        <input class="variablePath value" (click)="showValueSelector(Right, 'right')" type="text" [(ngModel)]="operation.right.stringValue" [placeholder]="'Variable name' | i18n" [focus-on-show-init-only]="focusOnShow" (change)="variableValueChanged('right', operation.right.stringValue)" (keydown)="hideValueSelector()" />
      </ng-container>
      <ng-container *ngSwitchCase="'string'">
        <i class="mi-text"></i>
        <input class="value" (click)="showValueSelector(Right, 'right')" type="text" *ngIf="operation.right.valueType == 'string'" [(ngModel)]="operation.right.stringValue" [placeholder]="'Text value' | i18n" [focus-on-show-init-only]="focusOnShow" (change)="stringValueChanged('right', operation.right.stringValue)" (keydown)="hideValueSelector()"/>
      </ng-container>
      <ng-container *ngSwitchCase="'number'">
        <i class="mi-number-input"></i>
        <input class="value numberInput" (click)="showValueSelector(Right, 'right')" type="number" *ngIf="operation.right.valueType == 'number'" [(ngModel)]="operation.right.numberValue" [placeholder]="'Number value' | i18n" [focus-on-show-init-only]="focusOnShow" (change)="numberValueChanged('right', operation.right.numberValue)" (keydown)="hideValueSelector()"/>
      </ng-container>
      <ng-container *ngSwitchCase="'boolean'">
        <i class="mi-check" *ngIf="operation.right.booleanValue"></i>
        <i class="mi-check" *ngIf="!operation.right.booleanValue"></i>
        <button *ngIf="operation.right.valueType == 'boolean'" class="booleanValue value" (click)="showValueSelector(Right, 'right')" [class.yes]="operation.right.booleanValue">
          <ng-container *ngIf="operation.right.booleanValue">{{'common_yes' | i18n}}</ng-container>
          <ng-container *ngIf="!operation.right.booleanValue">{{'common_no' | i18n}}</ng-container>
        </button>
      </ng-container>
      <ng-container *ngSwitchCase="'expression'">
        <i class="mi-calculator"></i>
        <my-expression-editor class="value" *ngIf="operation.right.valueType == 'expression'"
                              [suggestionsProvider]="suggestionsProvider"
                              [(expression)]="operation.right.stringValue" [miniMode]="true" [focusOnShow]="true" (expressionChangedOnBlur)="expressionValueChanged('right', operation.right.stringValue)" (expressionChange)="hideValueSelector()"/>
      </ng-container>
    </ng-container>
  </ng-container>
  <button
    *ngIf="!operation.right || operation.right.isEmpty() || operation.right.valueType == 'expression'"
    class="theme-button-flat-icon-medium dropDown" (click)="showValueSelector(Right, 'right')"><i class="mi-navigate-down"></i></button>
</div>

<my-drop-menu cssClass="theme-drop-menu logicOperationOperatorSelector" [anchor]="OPERATOR" [(visible)]="operatorMenuVisible">
  <div class="theme-entries-scroll">
    <button class="theme-drop-menu-entry" (click)="changeOperator('==')">&equals;</button>
    <button class="theme-drop-menu-entry" (click)="changeOperator('!=')">&ne;</button>
    <button class="theme-drop-menu-entry" (click)="changeOperator('<')">&lt;</button>
    <button class="theme-drop-menu-entry" (click)="changeOperator('<=')">&le;</button>
    <button class="theme-drop-menu-entry" (click)="changeOperator('>')">&gt;</button>
    <button class="theme-drop-menu-entry" (click)="changeOperator('>=')">&ge;</button>
    <button class="theme-drop-menu-entry" (click)="changeOperator('contains')">∋<span class="info">{{'process_logic_operation_contains' | i18n}}</span></button>
    <button class="theme-drop-menu-entry" (click)="changeOperator('!contains')">∌<span class="info">{{'process_logic_operation_not_contains' | i18n}}</span></button>
  </div>
</my-drop-menu>

<my-drop-menu cssClass="theme-drop-menu logicOperationValueSelector" [anchor]="valueSelectorAnchor" [(visible)]="valueSelectorVisible">
  <ng-container *ngIf="valueSelectorValuePosition">

    <div class="theme-drop-menu-filter">
      <input type="text" [(ngModel)]="query" (ngModelChange)="queryChanged()" (keydown.enter)="selectSingle()" [placeholder]="'process_logic_operation_search' | i18n" />
      <i class="mi-search"></i>
    </div>

    <div class="theme-entries-scroll">
      <div class="theme-drop-menu-group-title">{{'designer_multi_type_custom_values' | i18n}}</div>
      <button class="theme-drop-menu-entry" (click)="changeValueType(valueSelectorValuePosition, 'string')"><i class="mi-text"></i>{{'designer_multi_type_text_value' | i18n}}</button>
      <button class="theme-drop-menu-entry" (click)="changeValueType(valueSelectorValuePosition, 'number')"><i class="mi-number-input"></i>{{'designer_multi_type_number_value' | i18n}}</button>
      <button class="theme-drop-menu-entry" (click)="changeValueType(valueSelectorValuePosition, 'variable')"><i class="mi-tag"></i>{{'designer_multi_type_variable' | i18n}}</button>
      <button class="theme-drop-menu-entry" (click)="changeValueType(valueSelectorValuePosition, 'expression')"><i class="mi-calculator"></i>{{'designer_multi_type_expression' | i18n}}</button>
      <div class="theme-drop-menu-separator"></div>
      <div class="theme-drop-menu-group-title">{{'designer_multi_type_logic_values' | i18n}}</div>
      <button class="theme-drop-menu-entry theme-ok" (click)="changeValueToBoolean(valueSelectorValuePosition, true)"><i class="mi-check"></i>{{"common_yes" | i18n}}</button>
      <button class="theme-drop-menu-entry theme-danger" (click)="changeValueToBoolean(valueSelectorValuePosition, false)"><i class="mi-delete"></i>{{"common_no" | i18n}}</button>
      <ng-container *ngIf="suggestions.variables.length > 0">
        <div class="theme-drop-menu-separator" ></div>
        <div class="theme-drop-menu-group-title">{{'designer_multi_type_variables' | i18n}}</div>
        <button class="theme-drop-menu-entry variable"
                *ngFor="let variable of suggestions.variables"
                (click)="changeValueToVariable(valueSelectorValuePosition, variable.path, variable.variableType); ">{{variable.path}}<span class="typeName">{{variable.getVariableFriendlyTypeName()}}</span></button>
      </ng-container>
      <ng-container *ngIf="suggestions.texts.length > 0">
        <div class="theme-drop-menu-separator"></div>
        <div class="theme-drop-menu-group-title">{{'designer_multi_type_texts' | i18n}}</div>
        <button class="theme-drop-menu-entry text"
                *ngFor="let text of suggestions.texts"
                (click)="changeValueToText(valueSelectorValuePosition, text)">{{text}}</button>
      </ng-container>
    </div>
  </ng-container>
</my-drop-menu>
