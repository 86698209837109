<span class="alternativeActions"
      *ngIf="!hideAlternativeButtons && (viewModel.alternativeActions.length > 0 || viewModel.cancelButtonVisible || viewModel.completed.isDefined() && viewModel.pullActions.length > 0)">

  <ng-container class="alternativeActions" *ngIf="viewModel.alternativeActions.length > 0 || viewModel.cancelButtonVisible">
    <my-alternative-actions *ngIf="viewModel.completed.isEmpty()"
                            [minimal]="mobile"
                            [viewModel]="viewModel"></my-alternative-actions>

  </ng-container>

  <ng-container class="alternativeActions" *ngIf="viewModel.completed.isDefined() && viewModel.pullActions.length > 0">
    <ng-container *ngIf="viewModel.pullActions.length === 1">
      <button class="pullAction theme-button-link-icon" *ngFor="let action of viewModel.pullActions"
              (click)="viewModel.pullByAction(action, $event)"><i class="mi-redo"></i>
        <ng-container *ngIf="!mobile">{{'tasks_revert_task' | i18n}}</ng-container>
      </button>
    </ng-container>
    <ng-container *ngIf="viewModel.pullActions.length > 1">
      <span>{{'tasks_revert_task' | i18n}}</span>
      <button class="pullAction theme-button-link-icon" *ngFor="let action of viewModel.pullActions"
              (click)="viewModel.pullByAction(action, $event)"><i class="mi-redo"></i>{{action.name}}</button>
    </ng-container>

  </ng-container>
</span>


<div class="submitButtonMargin">
  <button class="theme-button-confirm-with-icon submitButton"
          *ngFor="let submit of viewModel.submitButtons"
          [class.active]="!viewModel.submitTaskButtonDisabled"
          (click)="viewModel.submitForm(submit.edgeId)"
          [disabled]="viewModel.submitTaskButtonDisabled">
    <i class="mi-check"></i>
    {{submit.name}}
  </button>
</div>
