import {Injectable} from "@angular/core";
import {ApplicationId, GroupId, Option} from "@utils";
import {BasicGroupInfo, GroupAuthorizationsInfo, GroupInfo} from "./GroupModel";
import {AuthenticatedHttp} from "../AuthenticatedHttp";
import {BasicOrganizationNodeInfo} from "./OrganizationNodesModel";
import {FindAuthorizationsInfoById, FindOrganizationNodesInGroup} from "./OrganizationMessages";

export class FindInfosByIds {
  constructor(readonly ids: Array<GroupId>) {}
}

export class FindBasicInfosByIds {
  constructor(readonly ids: Array<GroupId>) {}
}


@Injectable({
  providedIn: 'root',
})
export class GroupsSharedService {

  constructor(private authenticatedHttp: AuthenticatedHttp) {}

  findBasicGroupsInfos(groups: Array<GroupId>, onSuccess: (groups: Array<BasicGroupInfo>) => void) {
    this.authenticatedHttp.post("organization-groups/find-basic-infos-by-ids", new FindBasicInfosByIds(groups), (data: BasicGroupInfo[]) => {
      onSuccess(data.map(BasicGroupInfo.copy));
    });
  }

  findGroupsInfos(ids: Array<GroupId>, onSuccess: (groupsInfos: GroupInfo[]) => any) {
    this.authenticatedHttp.post("organization-groups/find-infos-by-ids", new FindInfosByIds(ids), (data: GroupInfo[]) => {
      onSuccess(data.map(GroupInfo.copy));
    });
  }

  findInfosForOrganization(onSuccess: (groups: Array<GroupInfo>) => void) {
    this.authenticatedHttp.get("organization-groups/find-infos-for-organization", (data: Array<GroupInfo>) => {
      onSuccess(data.map(GroupInfo.copy));
    });
  }

  findNodesInGroup(groupId: GroupId,
                   onSuccess: (nodes: Array<BasicOrganizationNodeInfo>) => void) {
    const message = new FindOrganizationNodesInGroup(groupId);
    this.authenticatedHttp.post("organization-structure/find-nodes-in-group", message,
      (data: BasicOrganizationNodeInfo[]) => {
        onSuccess(data.map(BasicOrganizationNodeInfo.copy));
      });
  }

  findGroupAuthorizationsInfo(groupId: GroupId,
                              onSuccess: (data: Option<GroupAuthorizationsInfo>) => void) {
    const message = new FindAuthorizationsInfoById(groupId);
    this.authenticatedHttp.post("organization-groups/find-authorizations-info-by-id", message, (data: Option<GroupAuthorizationsInfo>) => {
      onSuccess(Option.copy(data).map(GroupAuthorizationsInfo.copy));
    });
  }
}
