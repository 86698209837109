import {
  Component,
  createNgModule,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {StyleSet, StylesResolver, Translation, TranslationsResolver} from "@shared";
import {ApplicationId, Option} from "@utils";
import {
  GridProcessModelInterface,
  ProcessFlowDetailsInterface,
  TaskHistoryDetailsInterface,
  TasksEventBus
} from "@shared-model";
import {TaskEventBus} from "../../../modules/task-form.module/TaskEventBus";
import {TaskFormViewModel} from "../../../modules/task-form.module/task-form.view-model";

@Component({
  selector: "my-task-form",
  templateUrl: "./task-form.component.html"
})
export class TaskFormComponent implements OnInit {

  @Input() viewModel?: TaskFormViewModel;
  @Input() task!: TaskHistoryDetailsInterface;
  @Input() processFlowDetails!: ProcessFlowDetailsInterface;
  @Input() processModel!: GridProcessModelInterface;
  @Input() applicationId!: Option<ApplicationId>;
  @Input() collapseUnchangedSections = false;

  @Input() taskEventBus?: TaskEventBus;
  @Input() tasksEventBus?: TasksEventBus;

  @Input() mobile: boolean = false;

  @ViewChild(TemplateRef, { read: ViewContainerRef })
  private templateViewContainerRef: ViewContainerRef|undefined = undefined;

  constructor(readonly route: ActivatedRoute,
              private injector: Injector,
              private stylesResolver: StylesResolver,
              private translationsResolver: TranslationsResolver) {
  }

  ngOnInit(): void {
    this.createComponent();
  }

  private createComponent() {

    const module = import('../../../modules/task-form.module/task-form.module');
    const styles = this.stylesResolver.loadStyles(StyleSet.taskForm);
    const translations = this.translationsResolver.loadTranslations(Translation.tasks);


    Promise.all([module, styles, translations]).then(values => {

      const TaskFormModule = values[0].TaskFormModule;

      if(this.templateViewContainerRef === undefined) {
        throw new Error("templateViewContainerRef is undefined");
      } else {

        const moduleRef = createNgModule(TaskFormModule, this.injector)

        this.templateViewContainerRef.clear();
        const instance = this.templateViewContainerRef.createComponent(moduleRef.instance.getTaskFormImplComponent(), {ngModuleRef: moduleRef});

        if (instance === undefined) {
          throw new Error("Failed to create component");
        } else {
          instance.instance.task = <any>this.task;
          instance.instance.viewModel = this.viewModel;
          instance.instance.processFlowDetails = <any>this.processFlowDetails;
          instance.instance.processModel = <any>this.processModel;
          instance.instance.applicationId = this.applicationId;
          instance.instance.collapseUnchangedSections = this.collapseUnchangedSections;
          instance.instance.mobile = this.mobile;
          instance.instance.taskEventBus = this.taskEventBus;
          instance.instance.tasksEventBus = this.tasksEventBus;
        }
      }
    });

  }
}
